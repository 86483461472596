import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'antd'
import Form from './EmailForm'
import { IProps } from './Interfaces'
import useAlert from 'src/hooks/useAlert'

const EmailModal = (props: IProps) => {
  const { t } = useTranslation()
  const { setAlert } = useAlert()
  return (
    <Modal
      data-id='domiciliation-email-update'
      open={true}
      onCancel={props.onCloseModal}
      footer={null}
      title={t('enterprise:email_address')}
    >
      <Form {...props} setAlert={setAlert} />
    </Modal>
  )
}

export default EmailModal
