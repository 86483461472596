import React, { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Upload, UploadFile } from 'antd'
import type { UploadProps } from 'antd'
import Button from '@/components/UIKit/components/Button/buttonWithBorder'
import plusSign from '@/images/plusSign/plus-circle-blue.svg'
import { PlusIcon } from '../../Kyc/ProcedureDrawer/styles'

interface IProps {
  acceptedTypes?: string
  btnBgColor?: 'white' | 'orange' | 'black' | 'blue' | undefined
  btnIcon?: ReactNode
  btnJustifyContent?: string
  btnText?: ReactNode
  customButton?: ReactNode
  handleChange?: any
  handleBeforeChange?: any
  fileList?: UploadFile[] | undefined
  maxFileCount?: number | undefined
  multiple?: boolean
  setFileList?: any
  showUploads?: boolean

  children?: React.ReactNode
}

const DocumentUploader = (props: IProps) => {
  const { t } = useTranslation('products')
  const [defaultFileList, setDefaultFileList] = useState<UploadFile[]>(
    [] as UploadFile[]
  )
  const {
    acceptedTypes = '.jpg, .jpeg, .png, .pdf',
    btnBgColor = 'white',
    btnIcon = <PlusIcon src={plusSign} alt='icone +' />,
    btnJustifyContent = 'center',
    btnText = t('common:document.add'),
    customButton,
    handleChange,
    handleBeforeChange,
    fileList = defaultFileList,
    maxFileCount = 1,
    multiple = false,
    setFileList = setDefaultFileList,
    showUploads = false,

    children
  } = props

  const handleChangeDefault: UploadProps['onChange'] = (info) => {
    let newFileList = [...info.fileList]

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    newFileList = newFileList.slice(-2)

    // 2. Read from response and show file link
    newFileList = newFileList.map((file) => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url
      }
      return file
    })

    setFileList(newFileList)
  }

  return (
    <>
      <Upload
        accept={acceptedTypes}
        onChange={handleChange || handleChangeDefault}
        beforeUpload={handleBeforeChange}
        showUploadList={showUploads}
        maxCount={maxFileCount}
        multiple={multiple}
        fileList={fileList || defaultFileList}
      >
        {children}
        {fileList.length < maxFileCount &&
          (customButton ? (
            customButton
          ) : (
            <Button bgColor={btnBgColor} justifyContent={btnJustifyContent}>
              {btnIcon}
              {btnText}
            </Button>
          ))}
      </Upload>
    </>
  )
}

export default DocumentUploader
