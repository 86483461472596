import { useTranslation } from 'react-i18next'
import { DashboardSectionTitle } from '../styles'
import greenChair from 'src/images/dashboard/chair-green-bg-white.svg'
import bluePhone from 'src/images/dashboard/phone-blue-bg-white.svg'
import label from 'src/images/label/labels-yellow.svg'
import styled from 'styled-components'
import Service from './service'
import constants from 'src/constants'
import { useNavigate } from 'react-router'

const DashboardServices = () => {
  const { t } = useTranslation('dashboard')

  const navigate = useNavigate()
  const services = [
    {
      id: '1',
      icon: greenChair,
      alt: t('dailyServices.meetingRoom.alt'),
      title: t('dailyServices.meetingRoom.title'),
      description: t('dailyServices.meetingRoom.description'),
      onClick: () => navigate('/services/salles-de-reunion/reservation')
    },
    {
      id: '2',
      icon: bluePhone,
      alt: t('dailyServices.hotline.alt'),
      title: t('dailyServices.hotline.title'),
      description: t('dailyServices.hotline.description'),
      onClick: () => navigate('/services/permanence-telephonique/formulaire')
    },
    {
      id: '3',
      icon: label,
      alt: t('dailyServices.goodDeals.alt'),
      title: t('dailyServices.goodDeals.title'),
      description: t('dailyServices.goodDeals.description'),
      href: 'https://sedomicilier.fr/deals'
    }
  ]
  return (
    <DashboardServicesWrapper>
      <DashboardSectionTitle>{t('dailyServices.title')}</DashboardSectionTitle>
      <Services>
        {services.map((service) => (
          <Service key={service.id} service={service} />
        ))}
      </Services>
    </DashboardServicesWrapper>
  )
}

export const DashboardServicesWrapper = styled.div`
  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
    opacity: 0.5;
  `}
`
export const Services = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    flex-direction: column;
  }
`
export default DashboardServices
