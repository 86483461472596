import * as React from 'react'
import styled from 'styled-components'
import { Select } from 'antd'
import { Countries } from './CountriesList'

interface IProps {
  onChange: (value: any) => void
  defaultValue?: string
  disabled?: boolean
}

const CountriesSelect: React.FC<IProps> = (props) => {
  return (
    <div className='select-country'>
      <SelectStyled
        name='countrySelect'
        defaultValue={props.defaultValue}
        options={Countries}
        onChange={props.onChange}
        disabled={props.disabled}
        placeholder='Pays'
      />
    </div>
  )
}

export const SelectStyled = styled(Select)`
  border-radius: 4px;
  height: 35px;
  min-height: 35px;
  width: 100%;

  .ant-select-selector {
    color: #7b7e80;
    font-size: 16px;
  }
`

export default CountriesSelect
