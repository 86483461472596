import React, { useContext } from 'react'
import { DashboardSectionTitle } from '../styles'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import SubscriptionStep, { SubscriptionStepProps } from './subscriptionStep'
import { useQuery } from '@apollo/client'
import { CommonLoader } from 'src/components/shared/Loader'
import Routing from '@/CustomerArea/Routing'
import { useNavigate } from 'react-router'
import { ContactContext } from '@/contexts/contactContext'
import { DomiciliationContext } from '@/contexts/DomiciliationContext'
import { DOMICILIATION_STEPS_STATUSES } from '@/graphql/queries/domiciliation'

const FinalizeSubscription = () => {
  const { t } = useTranslation()

  const { domiciliation } = useContext(DomiciliationContext)
  const { contact } = useContext(ContactContext)

  const { loading, error, data } = useQuery(DOMICILIATION_STEPS_STATUSES)
  const stepStatusFor = (stepNumber) => {
    const status = data?.domiciliationStepsStatuses.find(
      (item) => item.step === stepNumber
    ).status
    return status
  }

  const navigate = useNavigate()

  const tradStepCertificate = () => {
    if (domiciliation === undefined) {
      return null
    }

    if (contact.society.legalStatus.isMicro) {
      return t('dashboard:finalizeSubscription.validation.description-micro')
    } else if (contact.society.legalStatus.name === 'Association') {
      return t(
        'dashboard:finalizeSubscription.validation.description-association'
      )
    } else {
      return t('dashboard:finalizeSubscription.validation.description')
    }
  }

  const subscriptionSteps = [
    {
      step: 1,
      title: t('dashboard:finalizeSubscription.download.title'),
      description: t('dashboard:finalizeSubscription.download.description'),
      buttonText: t('dashboard:caseComplete'),
      buttonRedirection: () => navigate(Routing.PROFILE_LEGAL_FILES),
      status: stepStatusFor('1')
    },
    {
      step: 2,
      title: t('dashboard:finalizeSubscription.sign.title'),
      description: t('dashboard:finalizeSubscription.sign.description'),
      buttonText: t('dashboard:signMyContract'),
      buttonRedirection: () =>
        domiciliation.affiliated
          ? window.location.replace(domiciliation.contractSignatureUrl)
          : window.open(domiciliation.contractSignatureUrl),
      status: stepStatusFor('2'),
      border: true
    },
    {
      step: 3,
      title: t('dashboard:finalizeSubscription.validation.title'),
      description: tradStepCertificate(),
      buttonText: t('dashboard:caseComplete'),
      buttonRedirection: () => navigate(Routing.PROFILE_LEGAL_FILES),
      status: stepStatusFor('3')
    }
  ]

  return loading && !error ? (
    <CommonLoader />
  ) : (
    <FinalizeSubscriptionWrapper>
      <DashboardSectionTitle>
        {t('dashboard:finalizeSubscription.title')}
      </DashboardSectionTitle>
      <SubscriptionStepsWrapper>
        {subscriptionSteps.map((item: SubscriptionStepProps) => (
          <SubscriptionStep
            key={item.step}
            title={item.title}
            description={item.description}
            buttonText={item.buttonText}
            buttonRedirection={item.buttonRedirection}
            step={item.step}
            border={item.border}
            status={item.status}
          />
        ))}
      </SubscriptionStepsWrapper>
    </FinalizeSubscriptionWrapper>
  )
}

export const FinalizeSubscriptionWrapper = styled.div``
export const SubscriptionStepsWrapper = styled.div`
  padding: 32px;
  border: 1px solid #e3e6e8;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export default FinalizeSubscription
