// @ts-nocheck
import { useState, useContext } from 'react'
import { Formik, Field } from 'formik'
import { useMutation } from '@apollo/client'
import { Modal } from 'antd'
import IGenericError from '@/interfaces/genericError'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import CountriesSelect from '@/components/shared/utilities/CountriesSelect'
import { SubmitLoader } from '@/components/shared/Loader'
import { DOMICILIATION_FORWARDING_ADDRESS_UPDATE_MUTATION } from '@/graphql/mutations/domiciliationForwardingAddressUpdate'

const AddressForm = (props: any) => {
  const { t } = useTranslation()
  const { domiciliation } = useContext(DomiciliationContext)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const [updateForwardingAddress] = useMutation(
    DOMICILIATION_FORWARDING_ADDRESS_UPDATE_MUTATION
  )

  const validation = Yup.object().shape({
    streetAddress: Yup.string().required(
      t('enterprise:validation.street_address')
    ),
    postalCode: Yup.string().required(t('enterprise:validation.postal_code')),
    city: Yup.string().required(t('enterprise:validation.city')),
    country: Yup.string().required(t('enterprise:validation.country'))
  })

  const successModal = () => {
    Modal.success({
      closable: true,
      centered: true,
      title: t('common:success'),
      content: (
        <p className='histo-content margin22'>
          {domiciliation.mailForwardingFrequency &&
          domiciliation.mailForwardingFrequency > 0
            ? t('enterprise:address_updated_with_forwarding_offer')
            : t('enterprise:address_updated_without_forwarding_offer')}
          .
        </p>
      )
    })
  }

  const errorModal = (error: IGenericError) => {
    Modal.error({
      closable: true,
      centered: true,
      title: t('common:error'),
      content: (
        <ul className='histo-content center margin22'>
          <li className='histo-content'>{error.message}</li>
        </ul>
      )
    })
  }

  const onFormSubmit = (values) => {
    setIsSubmitting(true)
    updateForwardingAddress({
      variables: values,
      update: (cache: any, { data }: { data: { error: IGenericError } }) => {
        if (data.error) {
          setIsSubmitting(false)
          errorModal(data.error)
        } else {
          props.onCloseModal()
          successModal()
        }
      }
    }).catch(() => {
      setIsSubmitting(false)
    })
  }

  return (
    <Formik
      onSubmit={onFormSubmit}
      initialValues={{
        mailForwardingTargetName:
          props.initialValues.mailForwardingTargetName || '',
        streetAddress: props.initialValues.forwardingAddress.streetAddress,
        postalCode: props.initialValues.forwardingAddress.postalCode,
        city: props.initialValues.forwardingAddress.city,
        country: props.initialValues.forwardingAddress.country
      }}
      validationSchema={validation}
    >
      {({ errors, touched, submitForm }) => (
        <div className='py-4'>
          <div className='mb-2'>
            <Field
              type='text'
              name='mailForwardingTargetName'
              className={`border-neutral-200 focus:shadow appearance-none border w-full
              py-2 px-3 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline
              ${touched.mailForwardingTargetName && errors.mailForwardingTargetName ? 'border-red-500' : ''}`}
              placeholder='Nom sur la boîte aux lettres'
            />
            {touched.mailForwardingTargetName &&
              errors.mailForwardingTargetName && (
                <div className='error-input'>
                  {errors.mailForwardingTargetName}
                </div>
              )}
          </div>
          <div className='mb-2'>
            <Field
              type='text'
              name='streetAddress'
              className={`border-neutral-200 focus:shadow appearance-none border w-full
              py-2 px-3 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline
              ${touched.streetAddress && errors.streetAddress ? 'border-red-500' : ''}`}
              placeholder='Rue et numéro'
            />
            {touched.streetAddress && errors.streetAddress && (
              <div className='error-input'>{errors.streetAddress}</div>
            )}
          </div>
          <div className='mb-2'>
            <Field
              type='text'
              name='postalCode'
              className={`border-neutral-200 focus:shadow appearance-none border w-full py-2 px-3
              text-neutral-700 leading-tight focus:outline-none focus:shadow-outline
              ${touched.postalCode && errors.postalCode ? 'border-red-500' : ''}`}
              placeholder='Code postal'
            />
            {touched.postalCode && errors.postalCode && (
              <div className='error-input'>{errors.postalCode}</div>
            )}
          </div>
          <div className='mb-2'>
            <Field
              type='text'
              name='city'
              className={`border-neutral-200 focus:shadow appearance-none border w-full py-2 px-3
              text-neutral-700 leading-tight focus:outline-none focus:shadow-outline
              ${touched.city && errors.city ? 'border-red-500' : ''}`}
              placeholder='Ville'
            />
            {touched.city && errors.city && (
              <div className='error-input'>{errors.city}</div>
            )}
          </div>
          <div className='mb-2'>
            <Field name='country'>
              {({ field, form }) => (
                <CountriesSelect
                  defaultValue={field.value}
                  onChange={(value) => form.setFieldValue(field.name, value)}
                  disabled={true}
                />
              )}
            </Field>
          </div>
          <button
            type='submit'
            className='submit section-button px-4'
            disabled={isSubmitting}
            onClick={submitForm}
          >
            <SubmitLoader isSubmitting={isSubmitting} />
            <span>{t('common:validate')}</span>
          </button>
        </div>
      )}
    </Formik>
  )
}

export default AddressForm
