import React, { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'

interface IProps {
  path?: string
  element?: React.ReactNode
  authorized: boolean
  children?: ReactNode
}

const ProtectedRoutes = (props: IProps) => {
  const { authorized } = props

  if (authorized) {
    return <Outlet />
  } else {
    return null
  }
}

export default ProtectedRoutes
