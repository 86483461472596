import { TAG_FRAGMENT, USER_MAIL_FRAGMENT } from '@/graphql/Fragments'
import { gql } from '@apollo/client'

export const USER_MAIL_TAG_CREATE_MUTATION = gql`
  mutation UserMailTagCreate($name: String!) {
    userMailTagCreate(name: $name) {
      tags {
        ...TagFragment
      }
    }
  }
  ${TAG_FRAGMENT}
`

export const USER_MAIL_TAG_ATTACH_MUTATION = gql`
  mutation UserMailTagAttach($userMailId: ID!, $tagId: ID!) {
    userMailTagAttach(userMailId: $userMailId, tagId: $tagId) {
      userMail {
        ...UserMailFragment
      }
      tags {
        ...TagFragment
      }
    }
  }
  ${TAG_FRAGMENT}
  ${USER_MAIL_FRAGMENT}
`

export const USER_MAIL_TAG_DETACH_MUTATION = gql`
  mutation UserMailTagDetach($userMailId: ID!, $name: String) {
    userMailTagDetach(userMailId: $userMailId, name: $name) {
      userMail {
        ...UserMailFragment
      }
      tags {
        ...TagFragment
      }
    }
  }
  ${TAG_FRAGMENT}
  ${USER_MAIL_FRAGMENT}
`

export const USER_MAIL_TAG_UPDATE_MUTATION = gql`
  mutation UserMailTagUpdate($id: ID!, $name: String!) {
    userMailTagUpdate(id: $id, name: $name) {
      tag {
        ...TagFragment
      }
    }
  }
  ${TAG_FRAGMENT}
`
