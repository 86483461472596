import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import NoticeInformation from 'src/components/noticeInformation'
import { useEffect, useState } from 'react'
import { Colors } from 'src/components/UIKit'

const DocumentTypeNotice = ({ selected }) => {
  const { t, i18n } = useTranslation()
  const [list, setList] = useState([''])

  const commonBase = `procedure:noticeInformationDrawer.address_proof.${selected}`

  const billPoints = [t(`${commonBase}.li1`), t(`${commonBase}.li2`)]
  const certificatePoint = [
    t(`${commonBase}.li1`),
    t(`${commonBase}.li2`),
    t(`${commonBase}.li3`)
  ]

  useEffect(() => {
    selected === 'bill' && setList(billPoints)
    selected === 'certificate' && setList(certificatePoint)
  }, [])

  return (
    <DocumentTypeNoticeWrapper>
      <NoticeInformation bgColor='blue'>
        <NoticeTopText>
          📄{' '}
          {t(
            `procedure:noticeInformationDrawer.address_proof.${selected}.title`
          )}
        </NoticeTopText>
        {i18n.exists(
          `procedure:noticeInformationDrawer.address_proof.${selected}.subtext`
        ) && (
          <NoticeText>
            {t(
              `procedure:noticeInformationDrawer.address_proof.${selected}.subtext`
            )}
            <NoticeStrongText>
              {t(
                `procedure:noticeInformationDrawer.address_proof.${selected}.less3months`
              )}
            </NoticeStrongText>
          </NoticeText>
        )}
        <NoticeList>
          {list.map((item, idx) => {
            return <NoticeLi key={idx}>{item}</NoticeLi>
          })}
        </NoticeList>
      </NoticeInformation>
    </DocumentTypeNoticeWrapper>
  )
}

export const DocumentTypeNoticeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const NoticeTopText = styled.div`
  margin-bottom: 8px;
`
export const NoticeText = styled.span``
export const NoticeStrongText = styled.span`
  font-weight: bold;
`
export const NoticeList = styled.ul`
  list-style: disc;
  padding: 0;
  padding-left: 16px;
  margin: 0;
`
export const NoticeLi = styled.li`
  font-size: 14px;
  color: ${Colors.black};
  margin: 0;
`

export default DocumentTypeNotice
