import Routing from '@/CustomerArea/Routing'
import {
  GENERAL_INFORMATIONS,
  INVOICES,
  LEGALS,
  NOTIFICATIONS,
  PAYMENT_INFORMATIONS,
  ROOT,
  SECURITY
} from '@/CustomerArea/settings/SettingsRoutes'

export const titleFromLocation = (desiredUrl, t) => {
  switch (desiredUrl) {
    case Routing.ROOT:
      return t('nav:pagesTitle.dashboard')
    case Routing.USER_MAILS:
    case Routing.USER_MAILS_INDEX:
      return t('nav:pagesTitle.userMails')
    case Routing.PROFILE:
      return t('nav:pagesTitle.domiciliation')
    case Routing.PROFILE_LEGAL_FILES:
      return t('nav:pagesTitle.procedures')
    case Routing.PROFILE_ATTESTATIONS:
      return t('nav:pagesTitle.documents')
    case Routing.SERVICES_HOTLINES_FORM:
      return t('nav:pagesTitle.services.hotlinesTitle')
    case Routing.SERVICES_HOTLINES_INDEX:
    case Routing.SERVICES_ROOM_MEETINGS_INDEX:
    case Routing.SERVICES_ROOM_MEETINGS_BOOK:
    case Routing.SERVICES_INDEX:
      return t('nav:pagesTitle.services.mainTitle')
    case Routing.CART:
      return t('nav:cart')
    case Routing.CART_PAYMENT:
      return t('nav:payment')
    case Routing.SUBSCRIPTION_PACKS:
      return t('nav:subscriptions')
    case Routing.TEAM:
      return t('nav:team')
    case Routing.REFERRER:
      return t('nav:referrer')

    case GENERAL_INFORMATIONS:
    case Routing.SETTINGS:
    case SECURITY:
    case LEGALS:
    case NOTIFICATIONS:
    case PAYMENT_INFORMATIONS:
    case INVOICES:
    case ROOT:
      return t('nav:settings')
    default:
      return ''
  }
}
