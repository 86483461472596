import gql from 'graphql-tag'

export const CONTACT_DETAIL_FRAGMENT = gql`
  fragment ContactDetailFragment on ContactDetail {
    id
    firstname
    lastname
    email
    birthday
    phoneNumber
    position
    nationality
    nationalityName
    sex
  }
`
