import { DOMICILIATION_FRAGMENT } from '@/graphql/Fragments'
import { gql } from '@apollo/client'

export const LEGALFLOW_DONE_MUTATION = gql`
  mutation legalflowDone {
    legalflowDone {
      domiciliation {
        ...DomiciliationFragment
      }
    }
  }
  ${DOMICILIATION_FRAGMENT}
`
