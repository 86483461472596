import { isDemo } from './demo/Utils'
import DemoContinueModal from './demo/DemoContinueModal'
import DomiciliationReloader from '@/components/shared/DomiciliationReloader'
import CommunicationModal from '@/components/shared/CommunicationModal'
import CommunicationNotification from '@/components/shared/CommunicationNotification'
import Layout from '@/CustomerArea/layout'
import Routing from '@/CustomerArea/Routing'
import TagCommanderProvider from 'src/contexts/TagCommanderContext'
import AlertProvider from 'src/contexts/alertContext'
import NotificationProvider from 'src/contexts/notificationContext'
import CgvAcceptanceModal from 'src/app/CustomerArea/modals/Cgvus/CgvAcceptanceModal'
import CurrentCgvAcceptanceContextProvider from '@/contexts/currentCgvAcceptanceContext'

const UserApp = () => {
  return (
    <DomiciliationReloader>
      <CommunicationModal>
        <CommunicationNotification>
          <CurrentCgvAcceptanceContextProvider>
            <TagCommanderProvider>
              <NotificationProvider>
                <AlertProvider>
                  {isDemo() && <DemoContinueModal />}
                  <Layout>
                    <CgvAcceptanceModal />
                    <Routing.RoutingComponent />
                  </Layout>
                </AlertProvider>
              </NotificationProvider>
            </TagCommanderProvider>
          </CurrentCgvAcceptanceContextProvider>
        </CommunicationNotification>
      </CommunicationModal>
    </DomiciliationReloader>
  )
}

export default UserApp
