import { useContext } from 'react'
import { Page, Colors } from 'src/components/UIKit'
import Layout from './Layout'
import LegalFormalities from './LegalFormalities'
import HotlinesIndex from './hotlines/Index'
import RoomMeetingsIndex from './roomMeetings/Index'
import { Routes, Route, Outlet } from 'react-router-dom'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'

const Services = () => {
  const { domiciliation }: any = useContext(DomiciliationContext)

  return (
    <>
      {domiciliation && <Layout />}
      <Page background={Colors.lightBlueBackground}>
        <Outlet />
        <Routes>
          <Route path='/' element={<LegalFormalities />} />

          <Route
            path='/permanence-telephonique'
            element={<HotlinesIndex hotline={domiciliation?.hotline} />}
          />

          <Route
            path='/salles-de-reunion'
            element={
              <RoomMeetingsIndex
                nextAppointments={domiciliation?.nextAppointments}
                pastAppointments={domiciliation?.pastAppointments}
              />
            }
          />
        </Routes>
      </Page>
    </>
  )
}

export default Services
