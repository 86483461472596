import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Row, Col, Checkbox, CheckboxProps } from 'antd'
import { useNavigate } from 'react-router-dom'
import { Constants, Button } from 'src/components/UIKit'
import { ContactContext } from 'src/contexts/contactContext'
import { CurrentCartContext } from 'src/contexts/currentCartContext'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import { CurrentCgvAcceptanceContext } from '@/contexts/currentCgvAcceptanceContext'

interface IProps {
  confirmOnClick?: () => void
  paymentButtonText: string
  disabledButton?: boolean
  displayButtonSpinner?: boolean
  form?: any
}

const Cart: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { confirmOnClick, form } = props

  const [totals, setTotals] = useState({ total: 0, totalVat: 0 })

  const { domiciliation }: any = useContext(DomiciliationContext)
  const {
    contact: { vat }
  }: any = useContext(ContactContext)
  const currentCart = useContext(CurrentCartContext)
  const currentCgv = useContext(CurrentCgvAcceptanceContext)

  const [disabledButton, setDisabledButton] = useState(!!props.disabledButton)
  const [cgvAccepted, setCgvAccepted] = useState(false)

  useEffect(() => {
    const cartAmount = currentCart?.amount || 0
    const cartTotal = cartAmount / 100
    const cartTotalVat = (cartAmount * (1 + vat / 100)) / 100

    setTotals({ total: cartTotal, totalVat: cartTotalVat })
  }, [currentCart])

  useEffect(() => {
    if (currentCgv) {
      setDisabledButton(!cgvAccepted)
    }
  }, [cgvAccepted])

  const triggerCgvAccepted: CheckboxProps['onChange'] = (e) => {
    setCgvAccepted(e.target.checked)
  }

  return (
    <OrderSummary bordered={false} data-test='cart-total-price'>
      <VatFreeRow>
        <Col>{t('services:bookings.price_for.total')}</Col>
        <PriceCol flex='auto'>
          {t('common:currency', { value: totals.total })}
        </PriceCol>
      </VatFreeRow>
      <VatRow>
        <Col>{t('services:bookings.price_for.totalWithTaxes')}</Col>
        <PriceCol flex='auto'>
          {t('common:currency', { value: totals.totalVat })}
        </PriceCol>
      </VatRow>
      <Row>
        {currentCgv?.url && (
          <div style={{ marginTop: '15px' }}>
            <Checkbox checked={cgvAccepted} onChange={triggerCgvAccepted}>
              <span>
                {t('cgvus:checkbox_text')}
                <a href={currentCgv?.url} target={'_blank'} rel='noreferrer'>
                  {t('cgvus:link_label')}
                </a>
              </span>
            </Checkbox>
          </div>
        )}
      </Row>
      <Row>
        <Col style={{ textAlign: 'center', width: '100%', marginTop: '15px' }}>
          <Button
            disabled={disabledButton}
            loading={props.displayButtonSpinner}
            onClick={confirmOnClick ? confirmOnClick : () => form.submit()}
          >
            {props.paymentButtonText}
          </Button>
        </Col>
      </Row>
      {domiciliation && (
        <Row>
          <Col
            style={{ textAlign: 'center', width: '100%', marginTop: '15px' }}
          >
            <Button
              disabled={props.displayButtonSpinner}
              loading={props.displayButtonSpinner}
              onClick={() => navigate('/services')}
              className='inverse-color'
            >
              {t('payments:add_services')}
            </Button>
          </Col>
        </Row>
      )}
    </OrderSummary>
  )
}

// TODO: move into box style
const OrderSummary = styled.div`
  padding: 0 25px;
  width: -webkit-fill-available;
  width: -moz-available;
  min-width: 300px;
  max-width: 360px;

  @media (max-width: ${Constants.breakpoints['mobile-breakpoint-i']}) {
    max-width: none;
  }
`

const VatFreeRow = styled(Row)`
  font-size: 14px;
`

const VatRow = styled(Row)`
  font-size: 18px;
  font-family: OpenSans-semibold;
`

const PriceCol = styled(Col)`
  text-align: right;
`

export default Cart
