import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import RedAlertImage from '@/images/red-alert.svg'
import styled from 'styled-components'
import {
  Centred,
  Title,
  SubText,
  PrettyLink,
  CircleInfoWrapper
} from './styles'

interface IProps {
  redirectRoute?: string
  redirectText?: string
}

const Failure = (props: IProps) => {
  const { redirectRoute, redirectText } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const { code, message } = location.state.data

  const redirectInFiveSeconds = () => {
    if (redirectRoute) {
      setTimeout(() => {
        navigate(redirectRoute)
      }, 5000)
    }
  }

  useEffect(() => {
    redirectInFiveSeconds()
  }, [])

  return (
    <Centred>
      <CircleInfoWrapper>
        <img src={RedAlertImage} />
      </CircleInfoWrapper>
      <Title>{t('payments:failure_title')}</Title>
      <ErrorMessage>
        {t(`payments:stripe_errors.${code}`, message)}
      </ErrorMessage>
      <SubText>{t('payments:failed_payment_message')}</SubText>

      {redirectRoute && (
        <PrettyLink onClick={() => navigate(redirectRoute)}>
          {redirectText}
        </PrettyLink>
      )}
    </Centred>
  )
}

const ErrorMessage = styled.span`
  display: block;
  text-align: center;
  max-width: 550px;
  height: auto;
  margin: 0 auto 10px auto;
  font-size: 12px;
  color: red;
`

export default Failure
