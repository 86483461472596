import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'src/components/spinner'

interface DropDownProps {
  loading: boolean
  children: React.ReactNode
  setManualSearch: Dispatch<SetStateAction<boolean>>
}

const DropDown: React.FC<DropDownProps> = ({
  loading,
  children,
  setManualSearch
}) => {
  const { t } = useTranslation('legalRepresentative')

  return (
    <div>
      <Spinner loading={loading}>{children}</Spinner>
      <div
        className='px-1.5 py-0.5'
        onClick={() => setManualSearch(true)}
        data-testid='society-not-found'
      >
        <p className='text-4 font-semibold text-interation'>
          {t('morale.search.notFound')}
        </p>
        <p className='text-5 text-neutral-700'>{t('morale.search.manual')} </p>
      </div>
    </div>
  )
}

export default DropDown
