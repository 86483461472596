import * as React from 'react'
import IRoom from '@/interfaces/room'
import ImageDoublePerson from '@/images/double-person.png'
import ImageOval32x from '@/images/oval-3@2x.png'
import { useTranslation } from 'react-i18next'
import { faWifi } from '@sedomicilier/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CarouselPhoto from './CarouselPhoto'

interface IProps {
  room: IRoom
  handleSelect?: (room: IRoom) => void
  showPrice?: boolean
}

export const Room = (props: IProps) => {
  const { t } = useTranslation()
  const { room, handleSelect, showPrice } = props

  const items = room.photos.map((photo) => ({
    src: photo,
    altText: room.name
  }))

  const durationM = String(
    '00' +
      Math.round(60 * (room.availableHours - Math.floor(room.availableHours)))
  ).slice(-2)

  const handleClick = () => handleSelect && handleSelect(room)

  return (
    <div
      className='flex flex-wrap mb-4 b-1 border border-neutral-200
    hover:bg-neutral-100 hover:cursor-pointer'
      onClick={handleClick}
    >
      <div className='w-1/4 sm-w-2/3 max-h-20 content-center'>
        {room.photos.length && <CarouselPhoto items={items} />}
      </div>

      <div
        className={`max-h-20 ${
          showPrice
            ? `w-1/2 border-r border-neutral-200
      mobile:border-none mobile:w-2/3`
            : 'w-2/3'
        } p-2`}
      >
        <div className='info-text'>{room.addressDetail.fullAddress}</div>
        <div className='title-cadre'>{room.name}</div>
        <div className='icon-info'>
          <img src={ImageDoublePerson} className='mobile-phone' />
          {room.capacity} {t('services:bookings:persons')}
        </div>
        {/* <div className="spacer" /> */}
        <div className='info-text equipments mt-3'>
          {room.equipments.map((e, i) => (
            <React.Fragment key={e}>
              {e}
              {i < room.equipments.length - 1 && (
                <img src={ImageOval32x} className='oval oval-cadre' />
              )}
            </React.Fragment>
          ))}
          <img src={ImageOval32x} className='oval oval-cadre' />
          <FontAwesomeIcon icon={faWifi} />
        </div>
      </div>

      {showPrice && (
        <div className='w-1/4 p-2 max-h-20 mobile:w-full'>
          <div className='title-cadre'>
            {room.bestPrice} € {t('common:exclTaxes')}
          </div>
          <div className='info-text'>
            {t('services:bookings.duration', {
              durationH: Math.floor(room.availableHours),
              durationM
            })}
          </div>
          <div className='info-text info-text-light'>
            {t('services:bookings.availableSlices')}
            {room.availableSlices}
          </div>
        </div>
      )}
    </div>
  )
}
