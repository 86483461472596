import React, { useState, useContext } from 'react'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { IRetryPaymentProps } from './Interfaces'
import { useTranslation } from 'react-i18next'
import { SubmitLoader } from '@/components/shared/Loader'
import { cardElementProps } from '@/components/shared/Stripe'
import { PAYMENT_SOURCE_CREATE_MUTATION } from '@/graphql/mutations/paymentSource'
import { DOMICILIATION_QUERY } from '@/graphql/queries/domiciliation'
import { UNPAID_INVOICE_PAID_MUTATION } from '@/graphql/mutations/invoices'
import { ContactContext } from 'src/contexts/contactContext'
import { useMutation } from '@apollo/client'

const NewCard = (props: IRetryPaymentProps) => {
  const { t } = useTranslation()
  const { contact }: any = useContext(ContactContext)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [stripeError, setStripeError] = useState()

  const [paymentSourceCreate] = useMutation(PAYMENT_SOURCE_CREATE_MUTATION)
  const [unpaidInvoicePaid] = useMutation(UNPAID_INVOICE_PAID_MUTATION)

  const stripe: any = useStripe()
  const elements: any = useElements()

  const handleRetry = async () => {
    const cardElement = elements.getElement(CardElement)
    setIsSubmitting(true)

    stripe
      .confirmCardPayment(props.unpaidInvoice.paymentIntentSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            email: contact.email
          }
        },
        setup_future_usage: 'off_session'
      })
      .then((result) => {
        if (!result.error) {
          unpaidInvoicePaid({ variables: { id: props.unpaidInvoice.id } })
            .then(() => {
              paymentSourceCreate({
                variables: { token: result.paymentIntent.payment_method },
                refetchQueries: [{ query: DOMICILIATION_QUERY }]
              })
                .then(() => {
                  setIsSubmitting(false)
                  props.onSuccess()
                })
                .catch(() => {
                  setIsSubmitting(false)
                })
            })
            .catch(() => {
              setIsSubmitting(false)
            })
        } else {
          setIsSubmitting(false)
          setStripeError(result.error.message)
          props.alertFailedPayment()
        }
      })
  }

  return (
    <div className='cardSection'>
      <div className='cb-wrapper'>
        <CardElement options={cardElementProps} />
        {stripeError !== undefined && (
          <div className='form-errors'>{stripeError}</div>
        )}
      </div>

      <button
        type='submit'
        className='submit section-button'
        onClick={() => handleRetry()}
        disabled={isSubmitting}
      >
        <SubmitLoader isSubmitting={isSubmitting} />
        <span>{t('invoices:retry.btnAddAndRetry')}</span>
      </button>
    </div>
  )
}

export default NewCard
