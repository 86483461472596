import React, { useMemo } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

interface IProps {
  stripeConnectedAccount: string | undefined
  children: React.ReactNode
}

const StripeWrapper = (props: IProps) => {
  const { stripeConnectedAccount } = props
  const stripePromise = useMemo(
    () =>
      loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`, {
        stripeAccount: stripeConnectedAccount,
        locale: 'fr'
      }),
    [stripeConnectedAccount]
  )

  return <Elements stripe={stripePromise}>{props.children}</Elements>
}

export default StripeWrapper
