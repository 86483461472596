// @ts-nocheck
import React from 'react'
import { withFormik, FormikProps, Form as FormikForm, Field } from 'formik'
import { graphql, MutateProps } from '@apollo/client/react/hoc'
import IGenericError from '@/interfaces/genericError'
import { withTranslation, WithTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { EMAIL_UPDATE_MUTATION } from '@/graphql/mutations/informationChange'
import { IProps, IFormValues } from './Interfaces'
import { SubmitLoader } from '@/components/shared/Loader'

const Content = (props: FormikProps<IFormValues> & WithTranslation) => {
  const { touched, errors, t } = props

  return (
    <FormikForm className='py-4'>
      <div className='mb-2'>
        <Field
          type='text'
          name='email'
          className={`border border-neutral-200 focus:outline-none focus:border-primary
           rounded-md w-full py-2 px-3 text-neutral-700
           ${touched.email && errors.email ? 'border-red-500' : ''}`}
          placeholder='Adresse Email'
        />
        {touched.email && errors.email && (
          <div className='text-error-700'>{errors.email}</div>
        )}
      </div>

      <div className='flex justify-center mt-2'>
        <button
          type='submit'
          className='submit section-button'
          disabled={props.isSubmitting}
        >
          <SubmitLoader isSubmitting={props.isSubmitting} />
          <span>{t('common:modify')}</span>
        </button>
      </div>
    </FormikForm>
  )
}

const Form = withTranslation('enterprise')(
  graphql<IProps & WithTranslation>(EMAIL_UPDATE_MUTATION)(
    withFormik<IProps & WithTranslation & MutateProps, IFormValues>({
      mapPropsToValues: (props) => {
        return {
          email: props.initialValues.email
        }
      },

      validationSchema: (props: any) => {
        return Yup.object().shape({
          email: Yup.string().required(props.t('enterprise:validation_email'))
        })
      },

      handleSubmit: (values, { props, setSubmitting }) => {
        props
          .mutate({
            variables: { ...values, email: values.email.toLowerCase() },
            update: (
              cache: any,
              { data }: { data: { error: IGenericError } }
            ) => {
              if (data.error) {
                setSubmitting(false)
                props.setAlert({
                  type: 'error',
                  text: data.error.message,
                  size: 'large'
                })
              } else {
                props.onCloseModal()
                props.setAlert({
                  type: 'success',
                  text: props.t('enterprise:emailUpdated'),
                  size: 'large'
                })
              }
            }
          })
          .catch(() => {
            setSubmitting(false)
          })
      }
    })(Content)
  )
)

export default Form
