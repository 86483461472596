import React, { createContext } from 'react'
import { useQuery } from '@apollo/client'
import { CommonLoader } from 'src/components/shared/Loader'

import * as Cookies from 'js-cookie'
import IContact, { ContactContextProps } from '@/interfaces/contact'
import { CONTACT_QUERY } from '@/graphql/queries/contact'

interface IProps {
  children: React.ReactNode
}

export const ContactContext = createContext({} as ContactContextProps)

const ContactContextProvider = (props: IProps) => {
  const { data, loading, error, refetch } = useQuery(CONTACT_QUERY)

  if (loading) {
    return <CommonLoader size={48} />
  }

  const contact: IContact = data?.contact
  if (contact) {
    // will be used by Bugsnag reporter
    Cookies.set('contact_id', contact?.id)
  }

  return (
    <ContactContext.Provider value={{ contact, loading, error, refetch }}>
      {props.children}
    </ContactContext.Provider>
  )
}

export default ContactContextProvider
