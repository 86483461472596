import { CONTACT_FRAGMENT } from '@/graphql/Fragments'
import { gql } from '@apollo/client'

export const CONTACT_SELECT_MUTATION = gql`
  mutation ContactSelect($contactId: ID!) {
    contactSelect(contactId: $contactId) {
      token
    }
  }
`

export const CONTACT_UPDATE_ADDRESS_MUTATION = gql`
  mutation contactUpdateAddress(
    $streetAddress: String!
    $postalCode: String
    $city: String!
    $country: String!
  ) {
    contactUpdateAddress(
      streetAddress: $streetAddress
      postalCode: $postalCode
      city: $city
      country: $country
    ) {
      contact {
        ...ContactFragment
      }
    }
  }
  ${CONTACT_FRAGMENT}
`
