import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useContext, useState } from 'react'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import { ContactContext } from 'src/contexts/contactContext'
import IContact from '@/interfaces/contact'
import Button from '@/components/UIKit/components/Button/buttonWithBorder'
import { Colors } from '@/components/UIKit'
import { useNavigate } from 'react-router-dom'
import Routing from '@/CustomerArea/Routing'
import {
  InfosList,
  InfosListElement,
  InfosListElementHeader,
  InfosListElementValue,
  Panel,
  PanelTitle
} from './styles'
import constants from '@/components/UIKit/constants'
import dayjs from 'dayjs'
import MailsOffersDetails from './drawers/mailsOffersDetails'

const Information = () => {
  const { t } = useTranslation('domiciliationPage')
  const navigate = useNavigate()
  const { domiciliation }: any = useContext(DomiciliationContext)
  const { contact }: { contact: IContact } = useContext(ContactContext)
  const country = contact.countryConfig.countryCode.toLowerCase()

  const [openMailsOfferDetailsDrawer, setOpenMailsOfferDetailsDrawer] =
    useState(false)

  return (
    <DomiciliationPageWrapper>
      {domiciliation.addressSubscription && (
        <Panel>
          <PanelTitle>{t('title')}</PanelTitle>
          <InfosList>
            <InfosListElement>
              <InfosListElementHeader>
                {t('domiciliationTitle')}
              </InfosListElementHeader>
              <InfosListElementValue>
                {domiciliation.address.fullAddress}
              </InfosListElementValue>
            </InfosListElement>
            <InfosListElement>
              <InfosListElementHeader>
                {t('domiciliationPriceTitle')}
              </InfosListElementHeader>
              <InfosListElementValue>
                {domiciliation.addressSubscription.amount} € HT&nbsp;
                {t(
                  `priceFrequencyLiteral.${domiciliation.addressSubscription.paymentType}`
                )}
              </InfosListElementValue>
            </InfosListElement>
            <InfosListElement>
              <InfosListElementHeader>
                {t('domiciliationInitialInvoiceTitle')}
              </InfosListElementHeader>
              <InfosListElementValue>
                {dayjs(domiciliation.initialInvoicePaidAt).format('DD/MM/YYYY')}
              </InfosListElementValue>
            </InfosListElement>
            <InfosListElement>
              <InfosListElementHeader>
                {t('domiciliationCommitmentTitle')}
              </InfosListElementHeader>
              <InfosListElementValue>
                {dayjs(domiciliation.startingDateWithReconduction).format(
                  'DD/MM/YYYY'
                )}
                {domiciliation.endOfCommitment &&
                  ' ' +
                    t('domiciliationCommitmentEndText', {
                      date: dayjs(domiciliation.endOfCommitment).format(
                        'DD MMMM YYYY'
                      )
                    })}
              </InfosListElementValue>
            </InfosListElement>
          </InfosList>
        </Panel>
      )}

      {domiciliation.userMailSubscription && (
        <Panel>
          <PanelTitle>{t('userMailTitle')}</PanelTitle>
          <InfosList>
            <InfosListElement>
              <InfosListElementHeader>{t('option')}</InfosListElementHeader>
              <InfosListElementValue>
                {t(`${domiciliation.userMailSubscription.title}`)}
              </InfosListElementValue>
            </InfosListElement>
            <InfosListElement>
              <InfosListElementHeader>
                {t('optionPrice')}
              </InfosListElementHeader>
              <InfosListElementValue>
                {domiciliation.userMailSubscription.amount !== 0 && (
                  <>
                    {domiciliation.userMailSubscription.amount} € HT&nbsp;
                    {t(
                      `priceFrequencyShort.${domiciliation.userMailSubscription.paymentType}`
                    )}
                  </>
                )}

                {domiciliation.userMailSubscription.amount === 0 && (
                  <>{t('priceFrequencyShort.free')}</>
                )}
                <MailsOffersDetails
                  open={openMailsOfferDetailsDrawer}
                  onClose={() => setOpenMailsOfferDetailsDrawer(false)}
                />
                {/* TODO: j'ai pas de client pour tester */}
                <Button
                  type='a'
                  className='text-right underline !text-interaction'
                  textColor='blue'
                  onClick={() => setOpenMailsOfferDetailsDrawer(true)}
                >
                  {t('optionDrawerTitle')}
                </Button>
              </InfosListElementValue>
            </InfosListElement>
          </InfosList>
        </Panel>
      )}
      <div className='bg-neutral-0 rounded-medium p-3 flex flex-col gap-2 mb-3'>
        <div className='flex gap-2  mobile:flex-col'>
          <div className='flex-1'>
            <h3 className='title-3 text-2'>{t('cgvTitle')}</h3>
            <div className='inline-flex'>
              <p className='body-2 text-neutral-600 inline'>
                {t('cgvText')}
                <a
                  href={`https://sedomicilier.${country}/cgvus`}
                  target='_blank'
                  rel='noreferrer'
                  className='text-interaction underline'
                >
                  {t('cgvLinkText')}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      {!domiciliation.cancelationDate && (
        <DomiciliationButton>
          <Button
            bgColor='red'
            justifyContent='center'
            onClick={() => navigate(Routing.SUBSCRIPTION_TERMINATION)}
          >
            {t('terminate')}
          </Button>
        </DomiciliationButton>
      )}
    </DomiciliationPageWrapper>
  )
}

export const DomiciliationPageWrapper = styled.div`
  padding: 0 45px;
  position: relative;
`
export const DomiciliationPageTitle = styled.h2`
  color: ${Colors.black};
  font-family: 'Inter';
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 22px;
`
export const DomiciliationPageCard = styled.div`
  padding: 16px;
  width: 420px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: ${Colors.lightBlueBackground};
  margin-bottom: 48px;
  @media (max-width: 900px) {
    width: 100%;
  }
`
export const DomiciliationButton = styled.div`
  width: 210px;
  position: absolute;
  top: -70px;
  right: 45px;

  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    width: 100%;
    position: initial;
  }
`

export default Information
