import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import colors from 'src/components/UIKit/colors'
import DocumentState from '../stepState/documentState'

const BeneficialOwnerStatus = ({ targetType, status }) => {
  const { t } = useTranslation()

  const getDenomination = () => {
    if (targetType) {
      return t(`procedure:beneficialOwner.${targetType}`)
    } else {
      return t('common:missing')
    }
  }

  return (
    <BeneficialOwnerStatusWrapper>
      <BOLine>
        <BOInquiry>{t('procedure:beneficialOwner.denomination')}</BOInquiry>
        <BOInformation>{getDenomination()}</BOInformation>
      </BOLine>
      <BOLine>
        <BOInquiry>{t('procedure:beneficialOwner.document')}</BOInquiry>
        <DocumentState state={status} />
      </BOLine>
    </BeneficialOwnerStatusWrapper>
  )
}

export const BeneficialOwnerStatusWrapper = styled.div``
export const BOLine = styled.div`
  display: flex;
  gap: 16px;

  font-size: 14px;
  font-weight: 400;
  font-family: 'Inter';
  line-height: 150%;
`
export const BOInquiry = styled.div`
  color: ${colors.grey};
`
export const BOInformation = styled.div`
  color: ${(props) => (props.missing ? `${colors.orange}` : `${colors.black}`)};
`

export default BeneficialOwnerStatus
