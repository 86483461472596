import React, { useContext } from 'react'
import { useWindowDimensions } from 'src/components/shared/useWindowDimensions'
import MailsBox from '@/CustomerArea/userMails/mailsBox'
import { TailwindConfig } from '@sedomicilier/material-sd'
import SelectedMailDetails from '@/CustomerArea/userMails/selectedMailDetails'
import {
  IUserMailContextValue,
  UserMailContext
} from '@/contexts/UserMailContext'

const UserMails: React.FC = () => {
  const { selectedMail } = useContext<
    IUserMailContextValue | Record<string, never>
  >(UserMailContext)

  const { width } = useWindowDimensions()
  const mobile =
    width <= parseInt(TailwindConfig.breakpointsCustomerArea.mobile.max, 10)

  return (
    <div className='flex'>
      {/* Display left side: tablet and above || mobile and no selected mail */}
      {(!mobile || (mobile && !selectedMail)) && <MailsBox />}

      {/* Display right side: tablet and above || mobile and selected mail */}
      {(!mobile || (mobile && selectedMail)) && <SelectedMailDetails />}
    </div>
  )
}

export default UserMails
