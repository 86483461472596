import gql from 'graphql-tag'

export const CGV_ACCEPTANCE_FRAGMENT = gql`
  fragment CgvAcceptanceFragment on CgvAcceptance {
    id
    uuid
    url
    plainUrl
    fileUrl
    name
  }
`
