import gql from 'graphql-tag'

export const PAYMENT_SOURCE_FRAGMENT = gql`
  fragment PaymentSourceFragment on PaymentSource {
    id
    name
    token
    sourceType
    currentSource
    last4Digits
    expirationDate
    state
  }
`
