import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation } from 'react-router-dom'
import { Col } from 'antd'
import Breadcrumb, { BreadcrumbRow } from 'src/components/shared/Breadcrumb'
import Routing from './Routing'
import { TerminationSubscriptionPage } from './styles'

const TerminationSubscription = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const [breadcrumbs, setBreadcrumbs] = useState([
    { text: t('nav:subscription.subscription') },
    { text: t('nav:termination') }
  ])
  const [title, setTitle] = useState<string>(
    t('cancelationProcedure:titles.resiliation')
  )

  useEffect(() => {
    const path = location.pathname.split('/')
    path.shift()
    setBreadcrumbs(
      path.map((item) => ({ text: t(`nav:subscription.${item}`) }))
    )

    const [lastElement] = path.slice(-1)
    setTitle(t(`cancelationProcedure:titles.${lastElement}`))
  }, [location])

  return (
    <TerminationSubscriptionPage className='cancelation_procedure'>
      <BreadcrumbRow>
        <Col flex={1}>
          <Breadcrumb elements={breadcrumbs} />
        </Col>
      </BreadcrumbRow>

      <h2>{title}</h2>

      <Routing />
      <Outlet />
    </TerminationSubscriptionPage>
  )
}

export default TerminationSubscription
