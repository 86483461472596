import { gql } from '@apollo/client'
import { DOMICILIATION_FRAGMENT } from '../Fragments'

export const POSTAL_PROCURATION_CONFIRMATION_MUTATION = gql`
  mutation postalProcurationConfirmation {
    postalProcurationConfirmation {
      domiciliation {
        ...DomiciliationFragment
      }
    }
  }
  ${DOMICILIATION_FRAGMENT}
`

export const CONFORMITY_UPDATE_MUTATION = gql`
  mutation conformityUpdate($siren: String!, $vatNumber: String!) {
    conformityUpdate(siren: $siren, vatNumber: $vatNumber) {
      domiciliation {
        ...DomiciliationFragment
      }
    }
  }
  ${DOMICILIATION_FRAGMENT}
`
