import { gql } from '@apollo/client'
import { KYC_LOGIC_RULE_FRAGMENT } from '@/graphql/Fragments'

export interface PartnerDataType {}

export const SUMMARY_PAGE_QUERY = gql`
  {
    domiciliation {
      id
      forwardingAddress {
        id
        fullAddress
      }
      kycLogicRules {
        ...KycLogicRulesFragment
      }
      documentHost {
        location
      }
    }
    contact {
      personalInformation {
        leaderContactDetail {
          firstname
          lastname
          email
          phoneNumber
          nationalityName
          position
        }
      }
      society {
        name
        brandName
        apeCode
        apeCategory
        apeActivity
        shareholderCount
      }
    }
  }
  ${KYC_LOGIC_RULE_FRAGMENT}
`
