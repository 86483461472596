import { Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'

const AccountantInfos: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div className='[&_input]:h-5 flex gap-2 [&>div]:flex-1'>
      <Form.Item
        name='accountantName'
        label={t('accounting:accountant.form.label.name')}
        rules={[
          {
            required: true,
            message: t('accounting:accountant.form.validation.name')
          }
        ]}
        className='m-0'
      >
        <Input placeholder={t('accounting:accountant.form.placeholder.name')} />
      </Form.Item>

      <Form.Item
        name='accountantEmail'
        label={t('accounting:accountant.form.label.email')}
        className='m-0'
      >
        <Input
          placeholder={t('accounting:accountant.form.placeholder.email')}
        />
      </Form.Item>
    </div>
  )
}

export default AccountantInfos
