import { CompanyActivityType } from '@/interfaces/companyActivity'
import { gql } from '@apollo/client'

export interface CompanyActivityDataType {
  companyActivities: CompanyActivityType[]
}

export const COMPANY_ACTIVITIES_QUERY = gql`
  query companyActivities {
    companyActivities {
      category
      activities {
        name
        ape
      }
    }
  }
`
