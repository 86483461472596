import { useTranslation } from 'react-i18next'
import ProcedureCard from '../ProcedureCard'
import { Dispatch, SetStateAction, useState } from 'react'
import { RcFile } from 'antd/es/upload'
import { LEGAL_DOCUMENT_CREATE_MUTATION } from '@/graphql/mutations/legalDocument'
import { notification } from 'antd'
import { DOMICILIATION_QUERY } from '@/graphql/queries/domiciliation'
import { useMutation } from '@apollo/client'
import { IdentityWrapper } from './styles'
import IdentityDrawers from '../identityDrawers'
import { IContactDetail } from '@/interfaces/contactDetail'
import { SHAREHOLDER_MUTATION } from '@/graphql/mutations/shareholder'
import house from 'src/images/home/house.svg'
import profile from 'src/images/profile/profile-dark-blue.svg'
import { NOTIFICATIONS_QUERY } from '@/graphql/queries/notification'
import {
  IDocumentInformation,
  IKycLogicMissingDocument
} from '@/interfaces/kycLogicRule'

interface IdentityProps {
  targetName: string
  targetType: string
  targetId: string
  rules: IKycLogicMissingDocument[]
  contactDetail: IContactDetail
  idx: number
  moralShareholderName: string
}

interface Submit {
  societyName?: string
  nationality?: string
  lastname?: string
  firstname?: string
}

export interface DocumentsType {
  state: string
  allowDocumentTypes: IDocumentInformation[]
  category: string
  setFileType: Dispatch<SetStateAction<string | null>>
  idNowSDK: boolean
  onClick: () => void
}

const Identity = ({
  targetName,
  targetType,
  targetId,
  rules,
  idx,
  contactDetail,
  moralShareholderName
}: IdentityProps) => {
  const { t } = useTranslation()

  const [legalDocumentCreate] = useMutation(LEGAL_DOCUMENT_CREATE_MUTATION)
  const [updateShareholder] = useMutation(SHAREHOLDER_MUTATION)

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [addressOpen, setAddressOpen] = useState(false)
  const [identityDrawerOpen, setIdentityDrawerOpen] = useState(false)
  const [shareholderDrawerOpen, setShareholderDrawerOpen] = useState(false)

  const [selectedFile, setSelectedFile] = useState<RcFile | null>(null)
  const [fileVerso, setFileVerso] = useState<RcFile | null>(null)
  const [fileType, setFileType] = useState<null | string>(null)
  const [drawerCategory, setDrawerCategory] = useState<null | string>(null)
  const [drawerAllowDocumentTypes, setDrawerAllowDocumentTypes] = useState<
    string[]
  >([])
  const [info, setInfo] = useState({
    firstname: contactDetail.firstname || '',
    lastname: contactDetail.lastname || ''
  })

  const denominationChoices = [
    {
      icon: profile,
      name: t('procedure:beneficialOwner.drawer.physical'),
      fileType: 'physical'
    },
    {
      icon: house,
      name: t('procedure:beneficialOwner.drawer.moral'),
      fileType: 'moral'
    }
  ]

  const [selectedDenomination, setSelectedDenomination] = useState(
    denominationChoices[0]
  )

  const states = {
    selectedFile,
    setSelectedFile,
    fileVerso,
    setFileVerso,
    setFileType,
    selectedDenomination,
    setSelectedDenomination,
    info,
    setInfo
  }

  const drawers = {
    drawerOpen,
    setDrawerOpen,
    addressOpen,
    setAddressOpen,
    identityDrawerOpen,
    setIdentityDrawerOpen,
    shareholderDrawerOpen,
    setShareholderDrawerOpen
  }

  const onSendDocument = () => {
    legalDocumentCreate({
      variables: {
        fileType: fileType,
        targetId: targetId,
        fileRecto: selectedFile,
        fileVerso: fileVerso
      },
      refetchQueries: [
        { query: DOMICILIATION_QUERY },
        { query: NOTIFICATIONS_QUERY }
      ],
      awaitRefetchQueries: true
    }).then(({ data }) => {
      if (data.error) {
        notification.error({
          message: t('common:error'),
          description: data.error.message,
          duration: 5
        })
      } else {
        notification.success({
          message: t('common:success'),
          description: t('profile:uploadModal.success'),
          duration: 5
        })
        setDrawerOpen(false)
        setAddressOpen(false)
        setIdentityDrawerOpen(false)
        setShareholderDrawerOpen(false)
        setSelectedFile(null)
        setFileVerso(null)
      }
    })
  }

  const onShareholderSubmit = (arg: Submit) => {
    if (arg?.societyName) {
      updateShareholder({
        variables: {
          id: targetId,
          legalType: selectedDenomination.fileType,
          lastname: arg.lastname,
          firstname: arg.firstname,
          name: arg.societyName,
          nationality: arg.nationality
        }
      }).then(async () => {
        await onSendDocument()
      })
    } else {
      updateShareholder({
        variables: {
          id: targetId,
          legalType: selectedDenomination.fileType,
          lastname: info.lastname,
          firstname: info.firstname
        }
      }).then(async () => {
        await onSendDocument()
      })
    }
  }

  const onClickHandler = (rule) => {
    if (
      targetType === 'Shareholder_Moral' ||
      targetType === 'Shareholder_Physical'
    ) {
      return setShareholderDrawerOpen(true)
    } else if (rule.category === 'address_proof') {
      return setAddressOpen(true)
    } else if (
      rule.category === 'identity_proof' ||
      rule.category === 'residence_permit'
    ) {
      return setIdentityDrawerOpen(true)
    } else {
      return setDrawerOpen(true)
    }
  }

  const documents: DocumentsType[] = []
  rules.map((rule) => {
    const el = {
      state: rule.state,
      allowDocumentTypes: rule.documents,
      category: rule.category,
      setFileType: setFileType,
      idNowSDK: rule.idNowSdkAvailable,
      onClick: () => onClickHandler(rule)
    }
    documents.push(el)
  })

  return (
    <IdentityWrapper>
      <IdentityDrawers
        drawers={drawers}
        states={states}
        targetType={targetType}
        drawerCategory={drawerCategory}
        drawerAllowDocumentTypes={drawerAllowDocumentTypes}
        onSendDocument={onSendDocument}
        setFileType={setFileType}
        idx={idx}
        onShareholderSubmit={onShareholderSubmit}
        denominationChoices={denominationChoices}
        contactDetail={contactDetail}
        moralShareholderName={moralShareholderName}
      />
      <ProcedureCard
        targetId={targetId}
        targetType={targetType}
        fullName={targetName}
        documents={documents}
        setDrawerCategory={setDrawerCategory}
        setDrawerAllowDocumentTypes={setDrawerAllowDocumentTypes}
        idx={idx}
        moralShareholderName={moralShareholderName}
        contactDetail={contactDetail}
      />
    </IdentityWrapper>
  )
}

export default Identity
