/* eslint-disable @typescript-eslint/no-var-requires */
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import '/node_modules/flag-icons/css/flag-icons.min.css'

const countries = require('i18n-iso-countries')
const countriesCode = require('/node_modules/country-json/src/country-by-abbreviation.json')

countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
countries.registerLocale(require('i18n-iso-countries/langs/fr.json'))

interface CountrySelectProps {
  defaultValue?: string
  onSelect: (value) => void
  className?: string
}

const CountrySelect = ({
  defaultValue,
  onSelect,
  className
}: CountrySelectProps) => {
  const { i18n, t } = useTranslation()
  const currentLng = i18n.language
  const { Option } = Select

  const countriesOption = countriesCode.map((countryCode, index) => {
    if (countries.getName(countryCode.abbreviation, currentLng.toLowerCase())) {
      return (
        <Option
          key={index}
          value={countryCode.abbreviation}
          label={countries.getName(
            countryCode.abbreviation,
            currentLng.toLowerCase()
          )}
        >
          <OptionWithFlag>
            <span
              className={`fi fi-${countryCode.abbreviation.toLowerCase()}`}
            />
            {countries.getName(
              countryCode.abbreviation,
              currentLng.toLowerCase()
            )}
          </OptionWithFlag>
        </Option>
      )
    } else {
      return null
    }
  })
  return (
    <Select
      allowClear
      showSearch
      filterOption={(input, option) =>
        (option?.label ?? '')
          .toString()
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      onSelect={onSelect}
      defaultValue={defaultValue}
      placeholder={t('common:select')}
      className={className}
    >
      {countriesOption}
    </Select>
  )
}

export const OptionWithFlag = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
export default CountrySelect
