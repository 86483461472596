import { useState, useRef, useEffect, SetStateAction } from 'react'
import { Select } from 'antd'
import {
  SEARCH_SOCIETY,
  SearchSocietyDataType
} from '@/graphql/queries/society'
import { useLazyQuery } from '@apollo/client'
import ISearchSociety from '@/interfaces/searchSociety'
import DropDown from './dropdown'

export interface searchSocietyOptionType {
  label: string
  value: string
  address: string
}

interface SearchSelectProps {
  loading: boolean
  setLoading: (value: SetStateAction<boolean>) => void
  onChange: (society: ISearchSociety) => void
  setManualSearch: (value: SetStateAction<boolean>) => void
  placeholder?: string
}

const SearchSelect: React.FC<SearchSelectProps> = ({
  loading,
  setLoading,
  onChange,
  placeholder,
  setManualSearch
}) => {
  const searchValueRef = useRef<string>('')
  const [searchValue, setSearchValue] = useState<string>('')
  const [options, setOptions] = useState<searchSocietyOptionType[]>([])

  const [searchSociety, { loading: searchLoading, data: searchData }] =
    useLazyQuery<SearchSocietyDataType>(SEARCH_SOCIETY)

  useEffect(() => {
    setLoading(searchLoading)
  }, [searchLoading])

  useEffect(() => {
    if (searchData?.searchSociety) {
      setOptions(
        searchData.searchSociety.map((data) => ({
          label: data.name,
          value: data.siren,
          address: data.address
        }))
      )
    }
  }, [searchData])

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms))
  const onSearch = (value: string) => {
    setSearchValue(value)
    if (value.length >= 3) {
      searchValueRef.current = value
      setLoading(true)

      delay(2000).then(() => {
        if (value === searchValueRef.current) {
          searchSociety({ variables: { query: value } })
        }
      })
    }
  }

  const overrideOnChange = (value: string) => {
    const society = searchData!.searchSociety.find(
      (soc) => soc.siren === value
    )!
    onChange(society)
  }

  const Option = Select.Option

  return (
    <Select
      className='w-full mb-4 h-9'
      loading={loading}
      onChange={overrideOnChange}
      onSearch={onSearch}
      searchValue={searchValue}
      placeholder={placeholder}
      showSearch
      filterOption={false}
      dropdownRender={(menu: React.ReactNode) => (
        <DropDown loading={loading} setManualSearch={setManualSearch}>
          {menu}
        </DropDown>
      )}
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
      data-testid='select-society'
    >
      {options.map((society: searchSocietyOptionType, i: number) => {
        return (
          <Option
            key={society.value}
            data-testid={`society-${i}`}
            className='h-[62px] flex items-center text-base font-medium'
          >
            <div className='gap-3'>
              <p className='block text-4 font-semibold'>{society.label} </p>
              <p className='block text-5 text-neutral-600'>{society.address}</p>
            </div>
          </Option>
        )
      })}
    </Select>
  )
}

export default SearchSelect
