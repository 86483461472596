import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import MainSettingsContentWithSidebar from './MainSettingsContentWithSidebar'
import { CommonLoader } from '@/components/shared/Loader'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'

const Index = () => {
  const { t } = useTranslation()
  const { loadingDomiciliation, errorDomiciliation }: any =
    useContext(DomiciliationContext)

  return loadingDomiciliation ? (
    <CommonLoader />
  ) : errorDomiciliation ? (
    <span>{`${t('common:error')}! ${errorDomiciliation.message}`}</span>
  ) : (
    <div className='container' role='main'>
      <div>
        <MainSettingsContentWithSidebar />
      </div>
    </div>
  )
}

export default Index
