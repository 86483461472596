import React, { useContext, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { HOTLINE_CREATE_OR_UPDATE_MUTATION } from '@/graphql/mutations/hotline'
import { Button, Form, Checkbox, Input, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import { useNavigate } from 'react-router-dom'
import Routing from '@/CustomerArea/Routing'
import { openErrorPopup, openValidationPopup } from 'src/components/UIKit'
import PropTypes from 'prop-types'
import { useStripe } from '@stripe/react-stripe-js'
import { CurrentCgvAcceptanceContext } from '@/contexts/currentCgvAcceptanceContext'

const HotlineForm = ({ hotlinePhoneNumbers }) => {
  const { domiciliation, refetch }: any = useContext(DomiciliationContext)
  const currentCgvAcceptance = useContext(CurrentCgvAcceptanceContext)
  const { hotline } = domiciliation
  const { t } = useTranslation()
  const stripe: any = useStripe()
  const navigate = useNavigate()

  const hotlinePlanSummary = hotline
    ? hotline.hotlinePlan?.name.replace(/\//, t('common:by'))
    : t('services:hotlines.illimited_plan')
  const hotlinePlanPrice = hotline
    ? t('common:currency', { value: hotline?.hotlinePlan?.priceHt }) +
      ' ' +
      t('services:HT')
    : t('common:currency', { value: 49 }) + ' ' + t('services:HT')
  const absenceReasons = ['vacation', 'out', 'meeting', 'other']

  const [showOtherAbsenceReason, setShowOtherAbsenceReason] = useState(false)

  const [createOrUpdateHotline] = useMutation(HOTLINE_CREATE_OR_UPDATE_MUTATION)

  const onFinish = (values: any) => {
    const absentReasonOther =
      values.absentReason !== 'other' ? '' : values.absentReasonOther
    const hotlinePhoneNumberId = hotline?.hotlinePhoneNumber
      ? hotline.hotlinePhoneNumber.id
      : values.phoneNumberSelected

    createOrUpdateHotline({
      variables: {
        hotlinePhoneNumberId,
        originalPhoneNumber: values.redirectionNumber,
        companyActivity: values.companyActivity,
        absentReason: values.absentReason,
        absentReasonOther,
        activityDescription: values.activityDescription,
        emergencyPhoneNumber: values.emergencyPhoneNumber,
        cgvUuid: currentCgvAcceptance?.uuid
      }
    }).then((res) => {
      if (res.data.error) {
        openErrorPopup(t('common:error'), res.data.error.message)
      } else if (res.data.hotlineCreateOrUpdate.clientSecret) {
        stripe
          .confirmCardPayment(res.data.hotlineCreateOrUpdate.clientSecret)
          .then((result) => {
            if (result.error) {
              openErrorPopup(t('common:error'), result.error.message)
            } else {
              refetch()
              openValidationPopup(
                t('services:hotlines:validations:title_success'),
                t('services:hotlines:validations:create_success')
              )
              navigate(Routing.SERVICES_HOTLINES_INDEX)
            }
          })
      } else {
        const textValidation = hotlinePhoneNumbers
          ? t('services:hotlines:validations:create_success')
          : t('services:hotlines:validations:update_success')
        refetch()
        openValidationPopup(
          t('services:hotlines:validations:title_success'),
          textValidation
        )
        navigate(Routing.SERVICES_HOTLINES_INDEX)
      }
    })
  }

  const handleAbsenceReason = (value) => {
    if (value === 'other') {
      return setShowOtherAbsenceReason(true)
    } else {
      setShowOtherAbsenceReason(false)
    }
    return
  }

  useEffect(() => {
    if (hotline?.absentReason === 'other') {
      setShowOtherAbsenceReason(true)
    }
  }, [])

  const initialValues = {
    phoneNumberSelected: hotline?.hotlinePhoneNumber?.phoneNumber,
    redirectionNumber: hotline?.originalPhoneNumber,
    companyActivity: hotline?.companyActivity,
    activityDescription: hotline?.activityDescription,
    absentReason: hotline?.absentReason,
    absentReasonOther: hotline?.absentReasonOther,
    emergencyPhoneNumber: hotline?.emergencyPhoneNumber,
    cgvAccepted: false,
    cgvUuid: currentCgvAcceptance?.uuid
  }

  return (
    <FormWrapper>
      <Form
        name='basic'
        initialValues={initialValues}
        onFinish={onFinish}
        autoComplete='off'
        layout='vertical'
        requiredMark={false}
      >
        {(!hotline?.hotlinePhoneNumber || hotline.state != 'activated') && (
          <Form.Item
            name='phoneNumberSelected'
            label={t('services:hotlines:choose_number')}
            rules={[
              {
                required: true,
                message: t('services:hotlines:validations:phone_number')
              }
            ]}
          >
            <Select placeholder='Sélectionnez dans la liste'>
              {hotlinePhoneNumbers.map((hotlinePhoneNumber) => (
                <Select.Option key={hotlinePhoneNumber.id}>
                  {hotlinePhoneNumber.phoneNumber}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          label={t('services:hotlines:choose_redirection_number')}
          name='redirectionNumber'
          rules={[
            {
              required: true,
              message: t(
                'services:hotlines:validations:redirection_phone_number'
              )
            }
          ]}
        >
          <Input placeholder='Saisissez un numéro' />
        </Form.Item>
        <Form.Item
          name='companyActivity'
          label='Décrivez en quelques mots l’activité de votre entreprise'
          rules={[
            {
              required: true,
              message: t('services:hotlines:validations:activity')
            }
          ]}
        >
          <Input.TextArea
            rows={5}
            className='text-area-hotline'
            placeholder='Commencez à écrire'
          />
        </Form.Item>
        <Form.Item
          name='activityDescription'
          label='Comment voulez-vous que votre appel soit receptionné ?'
          rules={[
            {
              required: true,
              message: t('services:hotlines:validations:activity')
            }
          ]}
        >
          <Input.TextArea
            rows={5}
            placeholder={t(
              'services:hotlines:choose_call_description_placeholder'
            )}
            className='text-area-hotline'
          />
        </Form.Item>
        <Form.Item
          name='absentReason'
          label='Raison de votre absence'
          rules={[
            {
              required: true,
              message: t('services:hotlines:validations:absent_reason')
            }
          ]}
        >
          <Select
            placeholder='Sélectionnez dans la liste'
            onSelect={(value) => handleAbsenceReason(value)}
          >
            {absenceReasons.map((reason, index) => {
              return (
                <Select.Option key={index} value={reason}>
                  {t(`services:hotlines.absentReasons.${reason}`)}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>

        {showOtherAbsenceReason && (
          <Form.Item
            name='absentReasonOther'
            rules={[
              {
                required: true,
                message: t('services:hotlines:validations:absent_reason_other')
              }
            ]}
          >
            <Input placeholder='Autre raison' />
          </Form.Item>
        )}

        <Form.Item
          name='emergencyPhoneNumber'
          label='Choisissez un numéro de contact d’urgence'
          rules={[
            {
              required: true,
              message: t('services:hotlines:validations:emergency_number')
            }
          ]}
        >
          <Input placeholder='Commencez à écrire' />
        </Form.Item>

        <HotlinePlanWrapper>
          <HotlinePlanTitle>{hotlinePlanSummary}</HotlinePlanTitle>
          <HotlinePlanDescription>
            <HotlinePlanMonthly>
              {t('services:hotlines:monthly_price')}
            </HotlinePlanMonthly>
            <div>{hotlinePlanPrice}</div>
          </HotlinePlanDescription>
        </HotlinePlanWrapper>

        {currentCgvAcceptance?.url && (
          <Form.Item
            name='cgvAccepted'
            className={'form-item-checkbox'}
            valuePropName='checked'
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          t('services:hotlines:validations:cgv_acceptance')
                        )
                      )
              }
            ]}
          >
            <Checkbox>
              {t('services:hotlines:cgv_acceptance:checkbox_text')}
              <a
                href={domiciliation?.currentCgvAcceptance?.url}
                target={'_blank'}
                rel='noreferrer'
              >
                {t('services:hotlines:cgv_acceptance:link_label')}
              </a>
            </Checkbox>
          </Form.Item>
        )}

        <Form.Item>
          <Button type='primary' htmlType='submit'>
            {t('services:validate_service')}
          </Button>
        </Form.Item>
        <PaymentNotice className='terms'>
          {t('services:hotlines.terms')}
        </PaymentNotice>
      </Form>
    </FormWrapper>
  )
}

HotlineForm.propTypes = { hotlinePhoneNumbers: PropTypes.node.isRequired }

export const FormWrapper = styled.div`
  button {
    width: 100%;
    height: 56px;
    background-color: #1576e1;
    border-radius: 8px;
    & > span {
      font-size: 16px;
      font-family: 'Inter';
    }
    &:hover {
      background: #0e519b;
    }
  }
  .ant-select-selector {
    height: 48px !important;
    border-radius: 8px !important;
    display: flex;
    align-items: center;
  }
  .text-area-hotline {
    border-radius: 8px;
  }

  input {
    height: 48px;
    border-radius: 8px;
  }

  .form-item-checkbox {
    label {
      height: fit-content;
    }
  }
`
export const HotlinePlanWrapper = styled.div`
  background: whitesmoke;
  padding: 30px;
  border: none;
  border-radius: 8px;
  margin-bottom: 16px;
`
export const HotlinePlanTitle = styled.div`
  font-size: 18px;
  color: #282c2e;
  font-family: 'AvenirLTPro-Roman';
`
export const HotlinePlanDescription = styled.div`
  display: flex;
  justify-content: space-between;
  color: #282c2e;
`
export const HotlinePlanMonthly = styled.div`
  color: #7b7e80;
`
export const PaymentNotice = styled.p`
  text-align: center;
`

export default HotlineForm
