import styled from 'styled-components'

export const TerminationSubscriptionPage = styled.div`
  margin: 0px 45px 0px 45px;
  display: flex;
  flex-flow: column wrap;
  font-family: 'Inter', 'Open Sans', serif;
  color: #171a1c;

  h2 {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }

  h4 {
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
    font-family: Inter, 'Open Sans', serif;
  }

  .full-width {
    width: 100%;
  }
`

export const TerminationSubscriptionHeaderWrapper = styled.div`
  width: 100%;
`

export const TerminationSubscriptionBodyWrapper = styled.div`
  width: 100%;
  max-width: unset;
  display: flex;
  flex-flow: row wrap;
  gap: 24px;
  justify-content: space-between;
  margin-top: 23px;

  @media (min-width: 1024px) {
    flex-flow: row nowrap;
  }
`

export const TerminationSummaryWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;

  h4 {
    margin-bottom: 0;
  }

  .subtitle {
    max-width: 450px;
    font-family: Inter, 'Open Sans', serif;
    color: #6c767f;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 33px;
  }

  @media (min-width: 1024px) {
    min-width: 342px;
  }
`

export const TerminationSummaryBlocs = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
  margin-top: 23px;

  .termination-bloc {
    border: 1px solid #d3dbe1;
    border-radius: 8px;
    padding: 16px;
  }

  .termination-bloc-head {
    display: flex;
    flex-flow: nowrap;
    gap: 16px;
    margin-bottom: 13px;

    .termination-bloc-title {
      width: auto;
      flex-grow: 0;
    }
    .termination-tag {
      font-family: Inter, 'Open Sans', serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      padding: 3px 6px;
      border-radius: 2px;
      background-color: #f5f7fb;
      color: #75818c;

      &.orange {
        background-color: #fbf0e8;
        color: #e06611;
      }
      &.green {
        background-color: #fbf0e8;
        color: #e06611;
      }
    }
  }

  .termination-bloc-body {
    p {
      margin: 0;

      &.refusal-text {
        color: #75818c;
        font-style: italic;
      }
    }

    ul {
      padding: 0;

      li {
        color: #282c2e;
        font-size: 14px;
        line-height: 21px;
        font-family: 'Inter', 'Open Sans', serif;
        padding: 0;

        & .label {
          margin-top: 13px;
          font-weight: 600;
        }
      }
    }

    .form-kbis-wrapper {
      margin-top: 13px;
      //remove last item bottom margin
      .ant-form-item:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: 1024px) {
    max-width: 342px;
  }
`

export const SubscriptionSummaryWrapper = styled.div`
  width: 100%;
  ul {
    margin-top: 23px;
    padding: 24px;
    background-color: #f5f7fb;
    border-radius: 8px;
    li {
      color: #6c767f;
      font-size: 16px;
      line-height: 24px;
      font-family: 'Open Sans', serif;
      & .summary-row-label {
        font-weight: 600;
      }
    }
  }

  @media (min-width: 1024px) {
    max-width: 360px;
  }
`
