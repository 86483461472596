// @ts-nocheck
import React from 'react'
import {
  graphql,
  DataProps,
  withApollo,
  WithApolloClient
} from '@apollo/client/react/hoc'
import { DOMICILIATION_QUERY } from '@/graphql/queries/domiciliation'
import IDomiciliation from '@/interfaces/domiciliation'

type IPropsGraphql = DataProps<{ domiciliation: IDomiciliation }>

type IProps = WithApolloClient<IPropsGraphql>

interface IState {
  currentDomiciliationId?: string
}

class DomiciliationReloader extends React.Component<IProps, IState> {
  public static getDerivedStateFromProps(props: IProps, state: IState) {
    if (
      !props.data.loading &&
      !props.data.error &&
      props.data.domiciliation &&
      props.data.domiciliation.id
    ) {
      state.currentDomiciliationId = props.data.domiciliation.id
    }

    return state
  }

  constructor(props: IProps) {
    super(props)

    this.state = {
      currentDomiciliationId: undefined
    }
  }

  public render() {
    // force rerender with a state in attribute "key"
    return (
      <React.Fragment key={this.state.currentDomiciliationId}>
        {this.props.children}
      </React.Fragment>
    )
  }
}

export default graphql(DOMICILIATION_QUERY)(
  withApollo<IPropsGraphql>(DomiciliationReloader)
)
