import { ADDRESS_DETAIL_FRAGMENT } from '../Fragments/addressDetail'
import { CONTACT_DETAIL_FRAGMENT } from '../Fragments/contactDetail'
import { POLITICALLY_EXPOSED_FRAGMENT } from '../Fragments/politicallyExposed'
import { IShareholder } from '@/interfaces/shareholder'
import { gql } from '@apollo/client'

export interface ShareholdersData {
  shareholders: IShareholder[]
}

export const SHAREHOLDERS_QUERY = gql`
  query shareholders {
    shareholders {
      id
      societyName
      legalType
      legalStatus
      addressDetail {
        ...AddressDetailFragment
      }
      contactDetail {
        ...ContactDetailFragment
      }
      politicallyExposed {
        ...PoliticallyExposedFragment
      }
    }
  }
  ${ADDRESS_DETAIL_FRAGMENT}
  ${CONTACT_DETAIL_FRAGMENT}
  ${POLITICALLY_EXPOSED_FRAGMENT}
`
