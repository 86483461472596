import styled from 'styled-components'
import Colors from './colors'
import Fonts from './fonts'

const Stylesheet = styled.div`
  h1 {
    font-family: ${Fonts.openSans};
    color: ${Colors.greyHeading};
    margin: 0;
  }

  h2 {
    color: ${Colors.greyHeading};
    font-family: ${Fonts.openSans};
    margin: 0;
  }

  h3 {
    color: ${Colors.greyHeading};
    font-family: ${Fonts.openSans};
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 10px 0;
  }

  h4 {
    color: ${Colors.greyHeading};
    font-family: ${Fonts.openSans};
    font-size: 16px;
    margin: 0;
  }

  p {
    color: ${Colors.greyHeading};
    font-size: 14px;
  }

  span {
    color: ${Colors.greyBody};
    font-family: ${Fonts.openSans};
    font-size: 14px;
  }

  a {
    color: ${Colors.blue};
    text-decoration: none;

    &:hover {
      color: ${Colors.darkBlue};
    }
  }

  ul {
    list-style-type: square;

    li {
      color: ${Colors.greyBody};
      font-size: 14px;
    }
  }
`

export default Stylesheet
