import * as React from 'react'
import { Translation } from 'react-i18next'
import { Modal } from 'antd'
import NewCard from './NewCard'
import ExistingCard from './ExistingCard'
import { IRetryPaymentProps } from './Interfaces'
import ConfirmPaymentIntent from './ConfirmPaymentIntent'
import { IFailedPayment } from '@/interfaces/failedPayment'

enum Option {
  Existing,
  New
}

interface IState {
  option?: Option
}

const canBeConfirmed = (unpaidInvoice: IFailedPayment) => {
  return (
    unpaidInvoice.state === 'requires_action' &&
    unpaidInvoice.paymentIntent &&
    unpaidInvoice.paymentIntent.paymentIntentSecret
  )
}

class RetryPaymentModal extends React.Component<IRetryPaymentProps, IState> {
  constructor(props: IRetryPaymentProps) {
    super(props)

    this.state = {
      option: Option.Existing
    }
  }

  public render() {
    return (
      <Translation ns={['invoices']}>
        {(t) => (
          <Modal
            data-id='retry-payment-modal'
            open={true}
            onCancel={this.props.onCloseModal}
            title={t('invoices:retry.title')}
            footer={null}
          >
            <div>
              {canBeConfirmed(this.props.unpaidInvoice) && (
                <div className='3Dsecure-informations'>
                  <p>
                    Votre banque demande confirmation du paiement. En
                    confirmant, vous allez être redirigé sur la page
                    d&apos;authentification de paiement de votre banque.
                  </p>

                  <ConfirmPaymentIntent {...this.props} />
                </div>
              )}

              {!canBeConfirmed(this.props.unpaidInvoice) &&
                this.props.paymentSources.length > 0 && (
                  <div className='choices'>
                    <div className='radio'>
                      <input
                        id='option-existing'
                        name='option'
                        type='radio'
                        value={Option.Existing}
                        checked={this.state.option === Option.Existing}
                        onChange={this.changeOption.bind(this, Option.Existing)}
                      />
                      <label htmlFor='option-existing'>
                        <p className='label-radio'>
                          {t('invoices:retry.option.existing')}
                        </p>
                      </label>
                    </div>

                    <div className='radio'>
                      <input
                        id='option-new'
                        name='option'
                        type='radio'
                        value={Option.New}
                        checked={this.state.option === Option.New}
                        onChange={this.changeOption.bind(this, Option.New)}
                      />
                      <label htmlFor='option-new'>
                        <p className='label-radio'>
                          {t('invoices:retry.option.new')}
                        </p>
                      </label>
                    </div>
                  </div>
                )}

              {!canBeConfirmed(this.props.unpaidInvoice) &&
                this.state.option === Option.Existing &&
                this.props.paymentSources.length > 0 && (
                  <div className='stripe'>
                    <p className='title-2'>
                      {t('invoices:retry.option.existing')}
                    </p>
                    <ExistingCard {...this.props} />
                  </div>
                )}

              {!canBeConfirmed(this.props.unpaidInvoice) &&
                (this.props.paymentSources.length === 0 ||
                  this.state.option === Option.New) && (
                  <div className='stripe'>
                    <p className='title-2'>{t('invoices:retry.option.new')}</p>
                    <p className='hint'>{t('invoices:retry.option.hintNew')}</p>

                    <NewCard {...this.props} />
                  </div>
                )}
            </div>
          </Modal>
        )}
      </Translation>
    )
  }

  private changeOption(value: Option) {
    this.setState({ option: value })
  }
}

export default RetryPaymentModal
