import React from 'react'
import ChartIcon from '@/images/chart.svg'
import { Progress, Spin } from 'antd'
import { useTranslation } from 'react-i18next'

interface IProps {
  referralInformations: any
}

const InformationDetails = (props: IProps) => {
  const { t } = useTranslation()
  const { referralInformations } = props

  const progressPercent = (refereeCount: number | undefined) => {
    if (!refereeCount) {
      return 0
    } else if (refereeCount > 5) {
      return 100
    } else {
      return refereeCount * 20
    }
  }

  const getDiscount = () => {
    const discount: number =
      referralInformations?.referrerCurrentReduction * 0.01
    if (!referralInformations) return <Spin />
    else {
      return t('referrer:details.referral', {
        discount: discount
      })
    }
  }

  const getCount = () => {
    const count: number =
      referralInformations?.confirmedCount > 5
        ? 5
        : referralInformations?.confirmedCount
    if (!referralInformations) return <Spin />
    else {
      return t('referrer:details.count', {
        count: count
      })
    }
  }

  const getTotalReduction = () => {
    const reduction: number =
      referralInformations?.totalReductionAcquired * 0.01
    if (!referralInformations) return <Spin />
    else {
      return t('referrer:details.totalReduction', {
        reduction: reduction
      })
    }
  }

  const getRewardCount = () => {
    const count: number = referralInformations?.balance * 0.01
    if (!referralInformations) return <Spin />
    else {
      return t('referrer:details.rewardCount', {
        count: count
      })
    }
  }

  return (
    <div className='referrer_overview'>
      <h2 className='mb-2'>
        <img className='inline' src={ChartIcon} alt={'chart'} />
        {t('referrer:details.title')}
      </h2>
      <div className='blocs'>
        <div className='bloc'>
          <div className='bloc-title'>{t('referrer:details.profile')}</div>
          <div className='bloc-content'>{getDiscount()}</div>
        </div>
        <div className='bloc bloc-double'>
          <div className='bloc-title'>{t('referrer:details.referralFull')}</div>
          <div className='bloc-content'>
            {getCount()}
            <Progress
              percent={progressPercent(
                referralInformations && referralInformations.confirmedCount
              )}
              showInfo={false}
              trailColor={'#FFE4ED'}
              strokeColor={'#FB82A9'}
            />
          </div>
        </div>
        <div className='bloc'>
          <div className='bloc-title'>{t('referrer:details.totalReward')}</div>
          <div className='bloc-content'>{getTotalReduction()}</div>
        </div>
        <div className='bloc'>
          <div className='bloc-title'>{t('referrer:details.reward')}</div>
          <div className='bloc-content'>{getRewardCount()}</div>
        </div>
        <div className='bloc'>
          <div className='bloc-title'>
            {t('referrer:details.totalReferrals')}
          </div>
          <div className='bloc-content'>
            {referralInformations ? (
              referralInformations.confirmedCount
            ) : (
              <Spin />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default InformationDetails
