import { NavLink } from 'react-router-dom'
import Notification from '../Notifications'
import { Dispatch, ReactNode, SetStateAction } from 'react'

interface IProps {
  to: string
  authorized: boolean
  children?: React.ReactNode
  onClick?: () => void
  icon: ReactNode
  setShowMobileMenu: Dispatch<SetStateAction<boolean>>
  label: string
  notification?: number
  target?: string
}

const MobileNavItem = (props: IProps) => {
  // const closeNav = () => {
  //   document.body.className = document.body.className.replace(' overflow-hidden', '')
  //   props.setCollapsed(true)
  // }

  if (props.authorized) {
    return (
      <NavLink
        to={props.to}
        onClick={() => props.setShowMobileMenu(false)}
        className='flex p-3 hover:no-underline items-center justify-between gap-[10px] '
        target={props.target}
      >
        <Content {...props} />
      </NavLink>
    )
  }

  return (
    <span className='flex p-3'>
      <Content {...props} />
    </span>
  )
}

const Content = ({ notification, icon, label }: IProps) => {
  return (
    <>
      {icon}
      <span className='flex-1'>{label}</span>
      {!!notification && <Notification number={notification} />}
    </>
  )
}

export default MobileNavItem
