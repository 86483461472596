import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/store'
import { modules, entryModule } from '@/workflows/modules'
import { generateFlow } from '@/workflows/utils'

export interface WorkflowState {
  path: string
  flow: string[]
}

const initialState: WorkflowState = {
  path: '/legal-flow/forwarding-address',
  flow: [entryModule.path]
}

export const workflowSlice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    nextPage: (state, action: PayloadAction<string | undefined>) => {
      const currentModule = modules.find((mod) => mod.path === state.path)!
      const nextModule = currentModule.nextPage(action?.payload)

      if (nextModule) {
        state.flow = generateFlow(state.flow, state.path, nextModule.path)
        state.path = nextModule.path
      }
    },
    previousPage: (state) => {
      const previousPathIndex = state.flow.indexOf(state.path) - 1
      if (previousPathIndex >= 0) state.path = state.flow.at(previousPathIndex)!
    },
    setPath: (state, action: PayloadAction<string>) => {
      state.path = action.payload
    }
  }
})

export const { nextPage, previousPage, setPath } = workflowSlice.actions
export const selectWorkflow = (state: RootState) => state.workflow

export default workflowSlice.reducer
