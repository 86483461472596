import styled from 'styled-components'
import infoOrangeIcon from 'src/images/information/info-orange.svg'
import infoBlueIcon from 'src/images/information/info-blue.svg'
import { Colors } from '@/components/UIKit'

interface NoticeInformationProps {
  children: React.ReactNode
  bgColor: 'orange' | 'blue'
  icon?: boolean
}

const NoticeInformation = ({
  children,
  bgColor,
  icon
}: NoticeInformationProps) => {
  const getRightIcon = () => {
    if (bgColor === 'orange') {
      return (
        <NoticeInformationIcon
          className='inline'
          src={infoOrangeIcon}
          alt='information icone'
        />
      )
    } else if (bgColor === 'blue' && icon) {
      return (
        <NoticeInformationIcon
          className='inline'
          src={infoBlueIcon}
          alt='information icone'
        />
      )
    } else {
      return
    }
  }

  return (
    <NoticeInformationWrapper bgColor={bgColor}>
      {getRightIcon()}
      <NoticeInformationText>{children}</NoticeInformationText>
    </NoticeInformationWrapper>
  )
}

export const NoticeInformationWrapper = styled.div`
  padding: ${(props) =>
    props.bgColor === 'orange' ? '14px 10px' : '10px 16px'};
  margin-bottom: 8px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  background-color: ${(props) =>
    props.bgColor === 'orange'
      ? Colors.lightOrange
      : Colors.mediumBlueBackground};
  border-radius: 8px;
`
export const NoticeInformationIcon = styled.img`
  margin: 0;
`
export const NoticeInformationText = styled.div`
  font-size: 14px;
  font-family: 'Inter';
  line-height: 150%;
  color: ${Colors.black};
`

export default NoticeInformation
