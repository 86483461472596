import styled from 'styled-components'

export const Button = styled.a`
  &:hover {
    color: #0454ad;
    text-decoration: none;
  }

  padding: 10px 20px;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;
  border-radius: 4px;

  color: #1576e1;
  font-size: 14px;
  align-self: center;
  text-decoration: none;

  .anticon {
    vertical-align: middle;
    font-size: 15px;
  }
`
