import { USER_CGV_ACCEPTANCE_FRAGMENT } from '@/graphql/Fragments'
import { gql } from '@apollo/client'

export const USER_CGV_ACCEPTANCE_RECORD_MUTATION = gql`
  mutation UserCgvAcceptanceRecord($cgvAcceptanceUuid: String!) {
    userCgvAcceptanceRecord(cgvAcceptanceUuid: $cgvAcceptanceUuid) {
      userCgvAcceptance {
        ...UserCgvAcceptanceFragment
      }
    }
  }
  ${USER_CGV_ACCEPTANCE_FRAGMENT}
`
