import { Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'

interface CompanyNamesProps {}

const CompanyNames: React.FC<CompanyNamesProps> = () => {
  const { t } = useTranslation()
  return (
    <>
      <Form.Item
        name='legalCompanyName'
        label={t('company:form.companyLegalName')}
        rules={[
          {
            required: true,
            message: t('common:validation.legalCompanyName')
          }
        ]}
      >
        <Input data-testid='company-name' />
      </Form.Item>

      <Form.Item name='brandName' label={t('company:form.businessName')}>
        <Input data-testid='brand-name' />
      </Form.Item>
    </>
  )
}

export default CompanyNames
