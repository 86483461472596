import React, { Dispatch, SetStateAction } from 'react'
import { FormInstance, Input, Select, Space } from 'antd'
import '/node_modules/flag-icons/css/flag-icons.min.css'
import countries, { isValid } from 'i18n-iso-countries'
import fr from 'i18n-iso-countries/langs/fr.json'
import en from 'i18n-iso-countries/langs/en.json'
import {
  CountryCode,
  getCountries,
  getCountryCallingCode
} from 'libphonenumber-js'

countries.registerLocale(fr)
countries.registerLocale(en)

export interface PhoneInputProps {
  locale: string
  countryDefaultValue?: string
  form?: FormInstance
  placeholder?: string
  setAlpha2CountryCode?: Dispatch<SetStateAction<CountryCode>>
  defaultPhoneValue?: string
  phoneTestid?: string
  countryCodeTestid?: string
}

export const PhoneInput: React.FC<PhoneInputProps> = ({
  countryDefaultValue,
  placeholder,
  setAlpha2CountryCode,
  locale,
  phoneTestid,
  countryCodeTestid,
  form,
  defaultPhoneValue
}) => {
  const { Option } = Select

  const sortedCountries = () => {
    const countryCodes = getCountries()
    const validCountryCodes = countryCodes.filter((code) => isValid(code))
    const countriesInfo = validCountryCodes.map((code) => {
      const name = countries.getName(code, locale.toLowerCase())
      return {
        name,
        code
      }
    })
    return countriesInfo.sort((a, b) => a.name!.localeCompare(b.name!))
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    form?.setFieldValue('phone', e.target.value)
  }

  return (
    <Space.Compact className='w-full'>
      <Select
        data-testid={countryCodeTestid}
        showSearch
        optionLabelProp='label'
        filterOption={(input, option) =>
          (option?.key ?? '')
            .toString()
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        onSelect={(value) => setAlpha2CountryCode!(value as CountryCode)}
        defaultValue={countryDefaultValue}
        style={{ width: '70px', height: '40px', border: 'none' }}
        dropdownStyle={{ width: '270px' }}
      >
        {sortedCountries().map((country) => {
          return (
            <Option
              data-testid={`${countryCodeTestid}-${country.code.toLowerCase()}`}
              key={country.name}
              value={country.code}
              label={
                <span
                  data-testid={`flag-${country.code.toLowerCase()}`}
                  className={`fi fi-${country.code.toLowerCase()}`}
                />
              }
            >
              <div className='flex items-center gap-1'>
                <span className={`fi fi-${country.code.toLowerCase()}`} />
                <span className='w-4'>
                  +{getCountryCallingCode(country.code)}
                </span>
                {country.name}
              </div>
            </Option>
          )
        })}
      </Select>

      <Input
        name='phone'
        defaultValue={defaultPhoneValue}
        data-testid={phoneTestid}
        placeholder={placeholder}
        onChange={onChange}
      />
    </Space.Compact>
  )
}
