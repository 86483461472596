import styled from 'styled-components'
import { Constants, Colors } from 'src/components/UIKit'
import { Row } from 'antd'

export const ContentRow = styled(Row)`
  width: 100%;
  flex-flow: column nowrap;

  @media (max-width: ${Constants.breakpoints['xlarge-breakpoint-i']}) {
    height: auto;

    & > .ant-col {
      // same as PackCard
      max-width: 550px;
    }

    #forwarding_packs_right_column {
      margin-top: 40px;
    }
  }
`

export const PackContainer = styled.div`
  display: flex;

  @media (max-width: ${Constants.breakpoints['large-breakpoint-i']}) {
    flex-direction: column;
  }
`

export const PackContent = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`

export const PackTitle = styled.div`
  margin-bottom: 25px;
  span {
    font-size: 16px;
    font-weight: 600;
  }
`

export const OldPrice = styled.span`
  color: #7b7e80;
  font-size: 14px;
`

export const PackPrice = styled.div`
  color: ${Colors.black};
  font-family: OpenSans-semibold;
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
`
