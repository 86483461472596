import React from 'react'
import { useTranslation } from 'react-i18next'
import { CommonLoader } from '@/components/shared/Loader'
import { Tag } from 'antd'
import GiftIcon from '@/images/gift.svg'

interface IProps {
  referralInformations?: any
  loading: boolean
  error: any
}

const List = (props: IProps) => {
  const { t } = useTranslation()
  const { referralInformations, loading, error } = props

  const tagColor = (status: string) => {
    if (status === 'email_sent' || status === 'deactivated') {
      return '#F5F7FB'
    } else if (status === 'in_progress') {
      return '#FBF0E8'
    } else if (status === 'confirmed') {
      return '#E2F2EA'
    } else {
      return '#F5F7FB'
    }
  }

  return (
    <div className='referrer_rewards'>
      <h2 className='mb-2'>
        <img className='inline' src={GiftIcon} alt={'gift'} />
        {t('referrer:refereeListTitle')}
      </h2>
      <div className='referees-outer'>
        <table className='referees'>
          <thead>
            <tr>
              <th>{t('referrer:referee')}</th>
              <th>{t('referrer:status.title')}</th>
              <th>{t('referrer:bonus')}</th>
            </tr>
          </thead>
          <tbody>
            {(loading || error) && (
              <tr>
                <td colSpan={3}>
                  <CommonLoader />
                </td>
              </tr>
            )}
            {referralInformations?.referrals.map((referee: any) => {
              return (
                <tr key={referee.id}>
                  <td>{referee.refereeEmail || t('referrer:guest')}</td>
                  <td>{t(`referrer:status.${referee.status}.title`)}</td>
                  <td>
                    <Tag
                      color={tagColor(referee.status)}
                      className={referee.status}
                    >
                      {t(`referrer:status.${referee.status}.bonus`, {
                        bonus: referee.bonus / 100
                      })}
                    </Tag>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default List
