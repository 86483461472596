import { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import IInformationChange from '@/interfaces/informationChange'
import { ContactContext } from 'src/contexts/contactContext'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import { INFORMATION_CHANGES_QUERY } from '@/graphql/queries/informationChange'
import { Can } from 'src/contexts/abilityContext'
import {
  AddressModal,
  EmailModal,
  PhoneModal
} from 'src/components/profile/shared'
import { CommonLoader } from 'src/components/shared/Loader'
import useAlert from 'src/hooks/useAlert'
import styled from 'styled-components'
import Button from 'src/components/UIKit/components/Button/buttonWithBorder'
import colors from 'src/components/UIKit/colors'
import { Constants } from 'src/components/UIKit'

const EditableCoordinates = () => {
  const { t } = useTranslation()
  const { contact }: any = useContext(ContactContext)
  const { domiciliation }: any = useContext(DomiciliationContext)
  const { setAlert } = useAlert()
  const { data, loading, error } = useQuery(INFORMATION_CHANGES_QUERY, {
    variables: {
      contactId: parseInt(contact.id, 10)
    }
  })

  const [
    showModalUpdateForwardingAddress,
    setShowModalUpdateForwardingAddress
  ] = useState<boolean>(false)
  const [showModalUpdatePhone, setShowModalUpdatePhone] =
    useState<boolean>(false)
  const [showModalUpdateEmail, setShowModalUpdateEmail] =
    useState<boolean>(false)

  const closeAllModals = () => {
    setShowModalUpdateForwardingAddress(false)
    setShowModalUpdatePhone(false)
    setShowModalUpdateEmail(false)
  }

  const handleEditFowardingAddress = () => {
    closeAllModals()
    setShowModalUpdateForwardingAddress(true)
  }

  const handleEditEmail = () => {
    closeAllModals()
    setShowModalUpdateEmail(true)
  }

  const handleEditPhone = () => {
    closeAllModals()
    setShowModalUpdatePhone(true)
  }

  // handle loading and error states
  if (loading) {
    return <CommonLoader />
  } else if (error) {
    return <span>{`${t('common:error')}! ${error.message}`}</span>
  }

  return (
    <>
      {showModalUpdateEmail && (
        <EmailModal
          onCloseModal={closeAllModals}
          initialValues={contact}
          setAlert={setAlert}
        />
      )}
      {showModalUpdatePhone && <PhoneModal onCloseModal={closeAllModals} />}
      <Can I='customer_manage' a='Domiciliation'>
        {showModalUpdateForwardingAddress && (
          <AddressModal
            onCloseModal={closeAllModals}
            initialValues={domiciliation}
          />
        )}
      </Can>

      <EditableCoordinatesTitle>
        {t('profile:my_coordinates')}
      </EditableCoordinatesTitle>

      <EditableCoordinatesWrapper>
        <InformationSectionUpdate>
          <InformationSectionText>
            <InformationSectionTitle>
              {t('profile:email')}
            </InformationSectionTitle>
            <InformationSectionData>{contact.email}</InformationSectionData>
          </InformationSectionText>
          {data?.informationChanges?.find(
            (c: IInformationChange) => c.oldValue === contact.email
          ) && (
            <ChangeExistingNotice>
              {t('profile:emailPendingChange')}
            </ChangeExistingNotice>
          )}
          <InformationSectionButton>
            <Button bgColor='white' onClick={handleEditEmail}>
              {t('profile:edit')}
            </Button>
          </InformationSectionButton>
        </InformationSectionUpdate>

        <InformationSectionUpdate border={true}>
          <InformationSectionText>
            <InformationSectionTitle>
              {t('profile:phone_number')}
            </InformationSectionTitle>
            <InformationSectionData>
              {contact.phoneNumber}
            </InformationSectionData>
          </InformationSectionText>
          {data?.informationChanges?.find(
            (c: IInformationChange) => c.oldValue === contact.phoneNumber
          ) && (
            <ChangeExistingNotice>
              {t('profile:phoneNumberPendingChange')}
            </ChangeExistingNotice>
          )}
          <InformationSectionButton>
            <Button bgColor='white' onClick={handleEditPhone}>
              {t('profile:edit')}
            </Button>
          </InformationSectionButton>
        </InformationSectionUpdate>

        <Can I='customer_read' a='Domiciliation'>
          <InformationSectionUpdate>
            <InformationSectionText>
              <InformationSectionTitle>
                {t('profile:forwarding_address')}
              </InformationSectionTitle>
              <InformationSectionData>
                {domiciliation?.forwardingAddress?.fullAddress}
              </InformationSectionData>
            </InformationSectionText>
            <Can I='customer_manage' a='Domiciliation'>
              <InformationSectionButton>
                <Button bgColor='white' onClick={handleEditFowardingAddress}>
                  {t('profile:edit')}
                </Button>
              </InformationSectionButton>
            </Can>
          </InformationSectionUpdate>
        </Can>
      </EditableCoordinatesWrapper>
    </>
  )
}

export const EditableCoordinatesTitle = styled.h2`
  font-size: 20px;
  color: ${colors.black};
  font-weight: 600;
  line-height: 140%;
`
export const EditableCoordinatesWrapper = styled.div`
  border: 1px solid #e3e6e8;
`
export const InformationSectionUpdate = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  font-size: 14px;
  ${(props) =>
    props.border &&
    `
    border-top: 1px solid #e3e6e8;
    border-bottom: 1px solid #e3e6e8;
  `}
  @media (max-width: ${Constants.breakpoints['mobile-breakpoint-i']}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`
export const InformationSectionText = styled.div`
  flex: 1;
`
export const InformationSectionTitle = styled.div`
  font-weight: 400;
  color: #6c767f;
  line-height: 150%;
`
export const InformationSectionData = styled.div`
  font-weight: 500;
`
export const InformationSectionButton = styled.div``

export const ChangeExistingNotice = styled.p`
  color: ${colors.pink} !important;
  font-size: 14px;
`
export default EditableCoordinates
