import { useTranslation } from 'react-i18next'
import ExtraServiceCard from '@/app/LegalFlow/Partner/extraServiceCard'
import creditCardIcon from 'src/images/credit-card/credit-card.svg'
import accountantIcon from 'src/images/documents/accountant-documents.svg'

interface ExtraServicesProps {
  serviceInterests: string[]
}

const ExtraServices: React.FC<ExtraServicesProps> = ({ serviceInterests }) => {
  const { t } = useTranslation()

  const options = [
    {
      id: 'bank',
      icon: creditCardIcon,
      tagLabel: t('partner:bank.tag')
    },
    {
      id: 'accountant',
      icon: accountantIcon
    },
    {
      id: 'insurance',
      icon: accountantIcon
    },
    {
      id: 'financial_product',
      icon: creditCardIcon
    }
  ]

  const filteredInterests = options.filter((item) =>
    serviceInterests.includes(item.id)
  )

  return (
    <div className='flex flex-col gap-4'>
      {filteredInterests.map((option) => (
        <ExtraServiceCard
          key={option.id}
          id={option.id}
          tagLabel={option.tagLabel}
          icon={option.icon}
        />
      ))}
    </div>
  )
}

export default ExtraServices
