import React, { useState, useContext } from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { IProps } from './Interfaces'
import { MutationFunction, useQuery } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { PAYMENT_SOURCE_CREATE_MUTATION } from '@/graphql/mutations/paymentSource'
import { STRIPE_SETUP_INTENT_SECRET } from '@/graphql/queries/stripeSetupIntentSecret'
import { useTranslation } from 'react-i18next'
import { cardElementProps } from '@/components/shared/Stripe'
import { CommonLoader } from '@/components/shared/Loader'
import { ContactContext } from 'src/contexts/contactContext'

const CardSection = (props: IProps) => {
  const { t } = useTranslation()
  const { contact }: any = useContext(ContactContext)

  const [submitting, setSubmitting] = useState(false)
  const [stripeError, setStripeError] = useState<string | undefined>()

  const stripe: any = useStripe()
  const elements: any = useElements()

  const {
    loading,
    error,
    data: setupIntentData
  } = useQuery(STRIPE_SETUP_INTENT_SECRET, {
    fetchPolicy: 'no-cache'
  })

  const handleSubmit = async (
    paymentSourceCreate: MutationFunction,
    ev: any
  ) => {
    setSubmitting(true)
    ev.preventDefault()

    const cardElement = elements.getElement(CardElement)
    stripe
      .confirmCardSetup(setupIntentData!.setupIntentSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            email: contact.email
          }
        }
      })
      .then((result: any) => {
        if (result.error) {
          setSubmitting(false)
          setStripeError(result.error.message)
        } else {
          setStripeError(undefined)

          paymentSourceCreate({
            variables: { token: result.setupIntent.payment_method }
          }).then((mutationResult: any) => {
            setSubmitting(false)
            if (mutationResult.data.error) {
              setStripeError(mutationResult.data.error.message)
            } else {
              setStripeError(undefined)
              props.onCloseModal()
            }
          })
        }
      })
  }

  if (loading) {
    return <CommonLoader />
  }

  if (error) {
    return <span>{`${t('common:error')}! ${error.message}`}</span>
  }

  return (
    <Mutation mutation={PAYMENT_SOURCE_CREATE_MUTATION}>
      {(paymentSourceCreate) => {
        if (setupIntentData.setupIntentSecret !== undefined) {
          return (
            <div className='cardSection center'>
              <div className='cb-wrapper'>
                <CardElement options={cardElementProps} />
                {stripeError !== undefined && (
                  <div className='form-errors'>{stripeError}</div>
                )}
              </div>
              <div>
                <button
                  type='button'
                  onClick={props.onCloseModal}
                  className='section-button white-button mr-4'
                >
                  {t('common:cancel')}
                </button>
                <button
                  type='button'
                  onClick={(e) => handleSubmit(paymentSourceCreate, e)}
                  disabled={submitting}
                  className='section-button primary-button'
                >
                  {t('common:record')}
                </button>
              </div>
            </div>
          )
        } else {
          return <div>Erreur, veuillez recharger la page</div>
        }
      }}
    </Mutation>
  )
}

export default CardSection
