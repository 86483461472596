import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { Skeleton } from 'antd'
import { REFERRAL_INFORMATIONS_QUERY } from '@/graphql/queries/referrer'
import ShareCode from './components/ShareCode'
import InformationDetails from './components/InformationDetails'
import List from './components/List'
import { Wrapper, Warning } from './styles'
import WarningIcon from '@/images/error-orange.svg'

const Referrer = () => {
  const { t } = useTranslation()

  const { data, loading, error, refetch } = useQuery(
    REFERRAL_INFORMATIONS_QUERY
  )
  const referralInformations = data?.referralInformations

  return (
    <Wrapper className='page-referrer'>
      <h1>
        {referralInformations ? (
          t('referrer:title', {
            referrerHighReduction:
              referralInformations.referrerHighReduction / 100,
            refereeReduction: referralInformations.refereeReduction / 100
          })
        ) : (
          <Skeleton title={false} />
        )}
      </h1>
      <div className='referrer_top'>
        <div className='referrer_rules'>
          <h3>{t('referrer:rules.title')}</h3>
          <p>
            {referralInformations ? (
              <>
                {t('referrer:rules.p1', {
                  referrerLowReduction:
                    referralInformations.referrerLowReduction / 100,
                  refereeReduction: referralInformations.refereeReduction / 100
                })}
                <br />
                {t('referrer:rules.p2')}
                <br />
                <br />
                {t('referrer:rules.p3', {
                  referrerLowReduction:
                    referralInformations.referrerLowReduction / 100,
                  refereeReduction: referralInformations.refereeReduction / 100
                })}
                <span className='referrer_condition'>
                  &nbsp;
                  {t('referrer:rules.condition', {
                    referrerHighReduction:
                      referralInformations.referrerHighReduction / 100,
                    refereeReduction:
                      referralInformations.refereeReduction / 100
                  })}
                </span>
                <Warning>
                  <img src={WarningIcon} alt='warning' />
                  {t('referrer:rules.warning')}
                </Warning>
              </>
            ) : (
              <Skeleton />
            )}
          </p>
        </div>
        <ShareCode
          referralInformations={referralInformations}
          refetch={refetch}
        />
      </div>

      <InformationDetails referralInformations={data?.referralInformations} />
      <List
        referralInformations={data?.referralInformations}
        loading={loading}
        error={error}
      />
    </Wrapper>
  )
}

export default Referrer
