import { DOMICILIATION_FRAGMENT } from '@/graphql/Fragments'
import { gql } from '@apollo/client'

export const UPDATE_SERVICE_INTEREST = gql`
  mutation serviceInterestUpdate(
    $bankInterest: Boolean!
    $accountantInterest: Boolean!
    $insurance: Boolean!
    $financialProduct: Boolean!
  ) {
    serviceInterestUpdate(
      bankInterest: $bankInterest
      accountantInterest: $accountantInterest
      insurance: $insurance
      financialProduct: $financialProduct
    ) {
      domiciliation {
        ...DomiciliationFragment
      }
    }
  }
  ${DOMICILIATION_FRAGMENT}
`
