import { gql } from '@apollo/client'

export const REFERRAL_INFORMATIONS_QUERY = gql`
  query ReferralInformations {
    referralInformations {
      referrals {
        id
        status
        bonus
        refereeEmail
      }
      referrerCode
      confirmedCount
      balance
      referrerCurrentReduction
      referrerLowReduction
      referrerHighReduction
      refereeReduction
      totalReductionAcquired
    }
  }
`
