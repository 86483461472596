import { BasicLink } from '@sedomicilier/material-sd'
import { Divider } from 'antd'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

interface SummarySectionProps {
  icon: ReactNode
  children: ReactNode
  modifyPath: string
  bottomSeparator?: boolean
  title: string
}

const SummarySection: React.FC<SummarySectionProps> = ({
  icon,
  children,
  bottomSeparator,
  modifyPath,
  title
}) => {
  const { t } = useTranslation()
  return (
    <div>
      <div translate='no' className='flex gap-2 justify-between items-start'>
        {icon}
        <div className='flex-1'>
          <div className='flex justify-between items-center mb-1.5'>
            <h3 className='body-1 font-semibold'>{title}</h3>
            <BasicLink
              href={modifyPath}
              label={t('common:modify')}
              color='blue'
              hoverUnderline
              className='text-base'
            />
          </div>
          {children}
        </div>
      </div>
      {bottomSeparator && <Divider />}
    </div>
  )
}

export default SummarySection
