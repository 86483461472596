import { useContext } from 'react'
import Routing from '@/CustomerArea/Routing'
import { useTranslation } from 'react-i18next'
import { AbilityContext } from 'src/contexts/abilityContext'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import { NotificationContext } from 'src/contexts/notificationContext'
import SideMenuSection from './SideMenuSection'
import navigationSections from '@/components/layouts/SideMenu/NavigationSections'
import LanguageSelect from '@/components/languageSelect'

const SideMenu = () => {
  const { t } = useTranslation()
  const ability = useContext(AbilityContext)
  const { domiciliation } = useContext(DomiciliationContext)
  const { kyc } = useContext(NotificationContext)

  const sideMenuSections = navigationSections({
    domiciliation,
    Routing,
    kyc,
    ability,
    t
  })

  return (
    <nav className='w-[232px] min-w-[232px] bg-neutral-100 mobile:!hidden tablet:!hidden !font-medium !text-neutral-700 py-3 px-[10px] flex-col justify-between gap-1 desktop:!flex'>
      <div>
        {sideMenuSections.map((section) => {
          return (
            <SideMenuSection
              key={section.dataSpecId}
              display={section.display}
              route={section.route}
              authorized={section.authorized}
              dataSpecId={section.dataSpecId}
              icon={section.icon}
              logo={section.logo}
              affiliated={section.affiliated}
              notification={section.notification}
              sectionTitle={section.sectionTitle}
            />
          )
        })}
      </div>
      <LanguageSelect />
    </nav>
  )
}

export default SideMenu
