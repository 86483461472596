import { useContext } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import Routing from '@/CustomerArea/Routing'
import lightningSVG from 'src/images/lightning.svg'
import { useTranslation } from 'react-i18next'

const BuyPacks = () => {
  const { t } = useTranslation()
  const { domiciliation }: any = useContext(DomiciliationContext)
  const forwardingCredits = domiciliation.forwardingCredits
  return (
    <Link to={Routing.SUBSCRIPTION_PACKS}>
      <BuyPacksWrapper>
        {t('userMails:packs.balanceAvailable')} : {forwardingCredits}
        &nbsp;
        <img src={lightningSVG} className='inline' />
      </BuyPacksWrapper>
    </Link>
  )
}

export const BuyPacksWrapper = styled.div`
  padding: 4px 8px;
  background-color: #f4f4f4;
  border-radius: 50px;
  line-height: 18px;
  font-weight: 600;
  font-size: 14px;
  color: #373738;
  cursor: pointer;
  display: inline-block;
`

export default BuyPacks
