import React, { useState } from 'react'
import { IPaymentSource } from '@/interfaces/paymentSource'
import { useTranslation } from 'react-i18next'
import { SubmitLoader } from '@/components/shared/Loader'
import { IRetryPaymentProps } from './Interfaces'
import { useStripe } from '@stripe/react-stripe-js'
import { useMutation } from '@apollo/client'
import { UNPAID_INVOICE_PAID_MUTATION } from '@/graphql/mutations/invoices'

const ExistingCard = (props: IRetryPaymentProps) => {
  const { t } = useTranslation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [stripeError, setStripeError] = useState('')
  const [selectedPaymentSource, setSelectedPaymentSource] = useState<
    IPaymentSource | undefined
  >()

  const [unpaidInvoicePaid] = useMutation(UNPAID_INVOICE_PAID_MUTATION)

  const stripe: any = useStripe()

  const handleRetry = async () => {
    setIsSubmitting(true)

    if (selectedPaymentSource) {
      stripe
        .confirmCardPayment(props.unpaidInvoice.paymentIntentSecret, {
          payment_method: selectedPaymentSource!.token
        })
        .then((result) => {
          if (!result.error) {
            unpaidInvoicePaid({
              variables: { id: props.unpaidInvoice.id }
            }).then(() => {
              setIsSubmitting(false)
              props.onSuccess()
            })
          } else {
            setIsSubmitting(false)
            setStripeError(result.error.message)
            props.alertFailedPayment()
          }
        })
        .catch(() => {
          setIsSubmitting(false)
        })
    } else {
      setIsSubmitting(false)
      setStripeError('Veuillez choisir une carte')
    }
  }

  return (
    <>
      <div className='payment-source-selector'>
        {props.paymentSources.map((paymentSource: IPaymentSource) => {
          return (
            <div className='radio' key={paymentSource.id}>
              <input
                id={`payment-source-${paymentSource.id}`}
                name='payment-source'
                type='radio'
                value={paymentSource.id}
                checked={!!(paymentSource.id === selectedPaymentSource?.id)}
                onChange={() => {
                  setSelectedPaymentSource(paymentSource)
                }}
              />
              <label htmlFor={`payment-source-${paymentSource.id}`}>
                <p className='label-radio'>
                  <span className='last4'>{`• • • • ${paymentSource.last4Digits}`}</span>
                  {paymentSource.expirationDate && (
                    <span className='info-sub'>
                      {t('payments:expire_at')}{' '}
                      {t('common:date', {
                        date: new Date(paymentSource.expirationDate)
                      })}
                    </span>
                  )}
                </p>
              </label>
            </div>
          )
        })}
      </div>
      {stripeError !== undefined && (
        <div className='form-errors'>{stripeError}</div>
      )}
      <button
        type='submit'
        className='submit section-button'
        onClick={() => handleRetry()}
        disabled={isSubmitting}
      >
        <SubmitLoader isSubmitting={isSubmitting} />
        <span>{t('invoices:retry.btnRetry')}</span>
      </button>
    </>
  )
}

export default ExistingCard
