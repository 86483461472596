import { CONTACT_FRAGMENT, SOCIETY_FRAGMENT } from '@/graphql/Fragments'
import { gql } from '@apollo/client'

export const CONTACT_QUERY = gql`
  {
    contact {
      ...ContactFragment
    }
  }
  ${CONTACT_FRAGMENT}
`

export const CONTACTS_QUERY = gql`
  {
    contacts {
      id
      domiciliationStatus
      society {
        ...SocietyFragment
      }
    }
  }
  ${SOCIETY_FRAGMENT}
`
