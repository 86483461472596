import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { IFailedPayment } from '@/interfaces/failedPayment'
import { AbilityContext } from 'src/contexts/abilityContext'

interface IProps {
  unpaidInvoice: IFailedPayment
  retry: () => void
}

const UnpaidInvoice = (props: IProps) => {
  const { t } = useTranslation()
  const { unpaidInvoice, retry } = props
  const ability = useContext(AbilityContext)
  const canPay =
    ability.can('customer_manage', 'Invoice') && unpaidInvoice.paymentIntent

  let stateDisplay = t('invoices:retry.regularize')

  if (unpaidInvoice.state === 'processing') {
    stateDisplay = 'Paiement en cours'
  } else if (unpaidInvoice.state === 'requires_action') {
    stateDisplay = 'Confirmer'
  }

  return (
    <tr className='invoice-container unpaid'>
      <td className='id-setting'>
        <span className='id-setting'>{unpaidInvoice.invoiceNumber}</span>
      </td>
      <td className='label'>
        <span>{unpaidInvoice.formattedName}</span>
        <a
          className='download-invoice-link'
          href={unpaidInvoice.pdfUrl}
          download={true}
          target='_blank'
          rel='noreferrer'
        >
          {t('common:download')}
        </a>
        {canPay ? (
          <a className='regularize-invoice' href='#' onClick={retry}>
            {stateDisplay}
          </a>
        ) : (
          <></>
        )}
      </td>
      <td className='info-setting'>
        <span>
          {t('common:date', { date: new Date(unpaidInvoice.issueDate) })}
        </span>
      </td>
      <td className='invoice-actions'>
        <span className='price-setting'>
          {t('common:currency', { value: unpaidInvoice.total })}
        </span>
        <span className='status'>{t('invoices:status.failure')}</span>
      </td>
    </tr>
  )
}

export default UnpaidInvoice
