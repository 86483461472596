import gql from 'graphql-tag'

export const FORWARDING_ADDRESS_FRAGMENT = gql`
  fragment ForwardingAddressFragment on ForwardingAddress {
    id
    name
    streetAddress
    postalCode
    city
    country
    countryName
    fullAddress
  }
`
