import styled from 'styled-components'

export const IdentityWrapper = styled.div``
export const Notices = styled.div`
  margin-bottom: 32px;
`
export const NoticeText = styled.span``
export const NoticeStrongText = styled.span`
  font-weight: bold;
`
