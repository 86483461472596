import { ROLE_FRAGMENT } from './role'

import { gql } from '@apollo/client'

export const CURRENT_USER_FRAGMENT = gql`
  fragment CurrentUserFragment on CurrentUser {
    id
    email
    firstname
    lastname
    zendeskChatToken
    loginCount
    locale
    permissions {
      model
      action
    }
  }
`

export const DOMICILIATION_USERS_FRAGMENT = gql`
  fragment UsersFragment on User {
    id
    email
    firstname
    lastname
    role {
      ...RoleFragment
    }
    emailMd5
  }
  ${ROLE_FRAGMENT}
`
