import * as React from 'react'
import Routing from '@/CustomerArea/Routing'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@sedomicilier/pro-light-svg-icons'
import { useNavigate } from 'react-router-dom'

interface IProps {
  children: React.ReactNode
  route?: string
  onClose?: () => void
}

export const FullScreenLayout = (props: IProps) => {
  const navigate = useNavigate()
  const defaultOnClose = () => navigate(props.route || Routing.SERVICES_INDEX)

  return (
    <div className='service-fullscreen d-flex flex-column justify-content-center'>
      <div
        className='close filtre-close'
        onClick={props.onClose || defaultOnClose}
      >
        <FontAwesomeIcon icon={faTimes} />
      </div>
      {props.children}
    </div>
  )
}
