import { useState, useEffect, useRef } from 'react'

// Needed for @rails/actioncable
// global.addEventListener = () => {};
// global.removeEventListener = () => {};

export default function useChannel(actionCable) {
  const [connected, setConnected] = useState(false)
  const [subscribed, setSubscribed] = useState(false)
  const channelRef = useRef()

  const subscribe = (data, callbacks) => {
    const channel = actionCable.subscriptions.create(data, {
      received: (x) => {
        if (callbacks.received) {
          callbacks.received(x)
        }
      },
      initialized: () => {
        setSubscribed(true)
        if (callbacks.initialized) {
          callbacks.initialized()
        }
      },
      connected: () => {
        setConnected(true)
        if (callbacks.connected) {
          callbacks.connected()
        }
      },
      disconnected: () => {
        setConnected(false)
        if (callbacks.disconnected) {
          callbacks.disconnected()
        }
      }
    })
    channelRef.current = channel
  }

  useEffect(() => {
    return () => {
      unsubscribe()
    }
  }, [])

  const unsubscribe = () => {
    setSubscribed(false)
    if (channelRef.current) {
      actionCable.subscriptions.remove(channelRef.current)
      channelRef.current = undefined
    }
  }

  const send = (type, payload) => {
    if (subscribed && !connected) {
      throw new Error('useChannel - ERROR: not connected')
    }
    if (!subscribed) {
      throw new Error('useChannel - ERROR: not subscribed')
    }
    try {
      if (channelRef !== undefined && channelRef.current !== undefined) {
        // @ts-expect-error Dunno how it works (and don't care)
        channelRef.current.perform(type, payload)
      }
    } catch (e) {
      throw new Error('useChannel - ERROR: ' + e)
    }
  }

  return { subscribe, unsubscribe, send }
}
