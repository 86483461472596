import gql from 'graphql-tag'

export const SOCIETY_FRAGMENT = gql`
  fragment SocietyFragment on Society {
    id
    name
    brandName
    apeCode
    apeCategory
    apeActivity
    rcs
    legalStatus {
      id
      name
      isMicro
    }
  }
`
