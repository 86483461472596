import { useContext } from 'react'
import EditableCoordinates from '@/CustomerArea/settings/generalInformation/EditableCoordinates'
import { ContactContext } from 'src/contexts/contactContext'
import styled from 'styled-components'
import colors from 'src/components/UIKit/colors'
import CivilInformation from './civilInformation'
import { useTranslation } from 'react-i18next'

const Information = () => {
  const { t } = useTranslation()
  const { contact }: any = useContext(ContactContext)

  const personalInformation = contact.personalInformation

  return (
    <GeneralInfoWrapper>
      <GeneralInfoTitle>{t('profile:generalInformation')}</GeneralInfoTitle>
      {/* <h2 className="subtitle-sidebar">{t('profile:generalInformation')}</h2> */}

      {personalInformation?.legalType === 'physical' && (
        <FirstNameLastName translate='no' className='notranslate'>
          {personalInformation.leaderContactDetail.firstname}{' '}
          {personalInformation.leaderContactDetail.lastname}
        </FirstNameLastName>
      )}

      <CompanyName translate='no' className='notranslate'>
        {contact.society.name || 'Mon compte'}
      </CompanyName>

      {personalInformation?.legalType === 'physical' && (
        <CivilInformation personalInformation={personalInformation} />
      )}

      <EditableCoordinates />
    </GeneralInfoWrapper>
  )
}

export const GeneralInfoWrapper = styled.div`
  padding: 34px 24px 52px 24px;
  color: ${colors.black};
`
export const GeneralInfoTitle = styled.h2`
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  margin-bottom: 32px;
  font-family: 'Inter';
`
export const FirstNameLastName = styled.div`
  color: ${colors.black};
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  margin-bottom: 8px;
  font-family: 'Inter';
`
export const CompanyName = styled.div`
  color: #6c767f;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 16px;
`

export default Information
