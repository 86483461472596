import gql from 'graphql-tag'

export const NOTIFICATIONS_QUERY = gql`
  query notifications {
    notifications {
      userMails
      legalDocuments
    }
  }
`
