import { gql } from '@apollo/client'
import { CGV_ACCEPTANCE_FRAGMENT, ROOM_FRAGMENT } from '@/graphql/Fragments'

export const ROOM_CITIES = gql`
  query RoomCity {
    roomCities
  }
`

export const ROOM_MEETINGS_QUERY = gql`
  query RoomMeetings(
    $per: Int
    $page: Int
    $city: String
    $capacity: Int
    $startingAt: ISO8601DateTime
    $endingAt: ISO8601DateTime
  ) {
    roomMeetings(
      per: $per
      page: $page
      city: $city
      capacity: $capacity
      startingAt: $startingAt
      endingAt: $endingAt
    ) {
      ...RoomFragment
      currentCgvAcceptance {
        ...CgvAcceptanceFragment
      }
    }
  }
  ${ROOM_FRAGMENT}
  ${CGV_ACCEPTANCE_FRAGMENT}
`
