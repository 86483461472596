import { SOCIETY_FRAGMENT } from '@/graphql/Fragments'
import { gql } from '@apollo/client'

export const SOCIETY_UPDATE_MUTATION = gql`
  mutation societyUpdate($name: String, $brandName: String, $apeCode: String) {
    societyUpdate(name: $name, brandName: $brandName, apeCode: $apeCode) {
      society {
        ...SocietyFragment
      }
    }
  }
  ${SOCIETY_FRAGMENT}
`
