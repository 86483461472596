import { withTracker } from 'react-tag-commander'

const WithTrackerComponent = ({ component, vars }) => {
  if ((window as any).tC) {
    withTracker(component, vars)
    return component
  } else {
    return component
  }
}

export default WithTrackerComponent
