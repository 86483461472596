import PacksDetails from './packDetails'
import ParcelsManagement from './parcelsManagement'
import { Drawer } from '@sedomicilier/material-sd'
import { Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import letterIcon from 'src/images/mails/mails-yellow.svg'

interface MailsOffersDetailsProps {
  open: boolean
  onClose: () => void
}

const MailsOffersDetails: React.FC<MailsOffersDetailsProps> = ({
  open,
  onClose
}) => {
  const { t } = useTranslation('domiciliationPage')

  return (
    <Drawer
      testid='mails-offers-details-drawer'
      open={open}
      onClose={onClose}
      icon={<img src={letterIcon} alt='drawer letter icon' />}
    >
      <div className='text-neutral-600 body-1 [&_span]:font-semibold [&_a]:underline'>
        <h3 className='title-2 mb-3 mt-2'>
          {t('drawers.mailsOfferDetails.title')}
        </h3>
        <PacksDetails />
        <Divider />
        <ParcelsManagement />
      </div>
    </Drawer>
  )
}

export default MailsOffersDetails
