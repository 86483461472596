import gql from 'graphql-tag'

export const COUNTRY_CONFIG_FRAGMENT = gql`
  fragment CountryConfigFragment on CountryConfig {
    id
    countryCode
    frontPath
    phone
    htText
  }
`
