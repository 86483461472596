import ProtectedLink from 'src/components/ProtectedLink'
import ImageArrowPointToRight from 'src/images/arrow-point-to-right.svg'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface NavigationLinkProps {
  to: string
  onClick: () => void
  sectionName: string
  authorized?: boolean
}

const NavigationLink: React.FC<NavigationLinkProps> = ({
  to,
  onClick,
  authorized,
  sectionName
}) => {
  const { t } = useTranslation()
  return (
    <div className='nav-element'>
      {authorized ? (
        <ProtectedLink to={to} onClick={onClick} authorized={authorized}>
          <div className='inner-settings-nav'>
            <span>{t(`nav:settingsNav.${sectionName}`)}</span>
            <img
              src={ImageArrowPointToRight}
              alt={'arrow right'}
              className='nav-arrow-right'
            />
          </div>
        </ProtectedLink>
      ) : (
        <NavLink to={to} onClick={onClick}>
          <div className='inner-settings-nav'>
            <span>{t(`nav:settingsNav.${sectionName}`)}</span>
            <img
              src={ImageArrowPointToRight}
              alt={'arrow right'}
              className='nav-arrow-right'
            />
          </div>
        </NavLink>
      )}
    </div>
  )
}

export default NavigationLink
