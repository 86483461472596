import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import Routing from '@/CustomerArea/Routing'
import { Colors } from 'src/components/UIKit'
import { CurrentCartContext } from 'src/contexts/currentCartContext'
import constants from 'src/constants'

const CartLink = () => {
  const navigate = useNavigate()
  const [count, setCount] = useState<number>(0)

  const currentCart = useContext(CurrentCartContext)

  const gotoSummary = () => navigate(Routing.CART)

  useEffect(() => {
    if (currentCart?.purchases) {
      setCount(currentCart.purchases.length)
    }
  }, [currentCart])

  return (
    <Wrapper onClick={gotoSummary} data-test='navbar-cart'>
      <span className='ico-bag text-neutral-700 text-1' />
      {count > 0 && <Badge double={count >= 10}>{count}</Badge>}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  cursor: pointer;
  align-self: center;
  min-height: 20px;
`

const Badge = styled.span`
  padding-left: 9px;
  padding-right: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
  font-size: 8px;
  background: #000000;
  line-height: 11px;
  color: #ffffff;
  padding: 2px ${(props) => (props.double ? '2' : '4.4')}px;
  vertical-align: top;
  margin-left: -10px;

  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    background-color: ${Colors.blue};
  }
`

export default CartLink
