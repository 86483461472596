import { useTranslation } from 'react-i18next'
import {
  NoticeInformation,
  CustomIcon,
  LegalflowSection
} from '@sedomicilier/material-sd'
import StatusCategory from '@/app/LegalFlow/LegalRepresentative/statusCategory'
import { useContext, useState } from 'react'
import Physical from './physical'
import Morale from './morale'
import { ContactContext } from '@/contexts/contactContext'
import { ContactContextProps } from '@/interfaces/contact'

const LegalRepresentative = () => {
  const { t } = useTranslation()
  const { contact } = useContext<ContactContextProps>(ContactContext)
  const legalType = contact?.personalInformation.legalType

  const [statusCategory, setStatusCategory] = useState<string | undefined>(
    legalType
  )

  return (
    <LegalflowSection title={t('legalRepresentative:title')}>
      <StatusCategory status={statusCategory} setStatus={setStatusCategory} />
      {!statusCategory ? (
        <NoticeInformation
          bgColor='grey'
          icon={
            <CustomIcon
              icon={
                <span className={'ico-star-full text-m text-interaction'} />
              }
            />
          }
        >
          {t('legalRepresentative:noticeInformation')}
        </NoticeInformation>
      ) : (
        <>
          {statusCategory === 'physical' && <Physical />}

          {statusCategory === 'moral' && <Morale />}
        </>
      )}
    </LegalflowSection>
  )
}

export default LegalRepresentative
