import AnimatedChevron from '@/components/animatedChevron'
import { useEffect, useState } from 'react'
import StepState from '../stepState'
import {
  ProcedureStepWrapper,
  ProcedureTopPart,
  ProcedureLeftPart,
  ProcedureStepNumber,
  ProcedureTitle,
  ProcedureRightPart,
  ProcedureContent
} from './styles'

interface ProcedureStepProps {
  title: string
  step: number
  state: 'locked' | 'todo' | 'validated' | 'completed'
  children: React.ReactNode
  opened?: boolean
  locked?: boolean
}

const ProcedureStep = ({
  title,
  step,
  state,
  children,
  locked,
  opened
}: ProcedureStepProps) => {
  const [open, setOpen] = useState<boolean>(opened || false)

  const onOpen = () => {
    if (locked || state === 'validated') {
      setOpen(false)
    } else {
      setOpen(!open)
    }
  }

  const noArrowIcon = locked || state === 'validated'

  useEffect(() => {
    if (state === 'todo') {
      setOpen(true)
    }
  }, [])

  return (
    <ProcedureStepWrapper>
      <ProcedureTopPart onClick={onOpen}>
        <ProcedureLeftPart>
          <ProcedureStepNumber>{step}</ProcedureStepNumber>
          <ProcedureTitle>{title}</ProcedureTitle>
        </ProcedureLeftPart>
        <ProcedureRightPart>
          <StepState state={state} />
          {!noArrowIcon && <AnimatedChevron open={open} />}
        </ProcedureRightPart>
      </ProcedureTopPart>
      {open && <ProcedureContent>{children}</ProcedureContent>}
    </ProcedureStepWrapper>
  )
}

export default ProcedureStep
