import { gql } from '@apollo/client'

export interface LeaderRolesData {
  leaderRoles: string[]
}

export const LEADER_ROLES_QUERY = gql`
  query leaderRoles {
    leaderRoles
  }
`
