import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import walkingIllustration from 'src/images/mail-scanning.svg'
import paymentFailurePaymentIllustration from 'src/images/payment-failure-mail.svg'
import Push from './Push'
import { useTranslation } from 'react-i18next'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'

const Pushes = () => {
  const { t } = useTranslation()
  const { domiciliation } = useContext(DomiciliationContext)
  const [showCenterReminderPush, setShowCenterReminderPush] = useState(true)
  const [
    showPaymentFailureMailOptionReminder,
    setShowPaymentFailureMailOptionReminder
  ] = useState(true)

  useEffect(() => {
    if (localStorage.getItem('centerReminder') === 'true') {
      setShowCenterReminderPush(false)
    }
    if (localStorage.getItem('paymentFailureMailOptionReminder') === 'true') {
      setShowPaymentFailureMailOptionReminder(false)
    }
  }, [])

  return (
    <div className='pt-4'>
      {showPaymentFailureMailOptionReminder &&
        domiciliation.communications.find(
          (item) => item.id === 'payment_failure_mail_option'
        ) && (
          <Push
            icon={paymentFailurePaymentIllustration}
            iconAlt='courrier vert ouvert'
            mainText={t('userMails:pushes.payment_failure_mail_option.title')}
            subText={t(
              'userMails:pushes.payment_failure_mail_option.description'
            )}
            bgColor='#FFDCDB'
            setState={setShowPaymentFailureMailOptionReminder}
            str='paymentFailureMailOptionReminder'
          />
        )}
      {showCenterReminderPush &&
        domiciliation.mailManagement !== 'provisioning' && (
          <Push
            icon={walkingIllustration}
            iconAlt="illustration d'un humain qui marche"
            mainText={t('userMails:pushes.no_need_to_move')}
            subText={t('userMails:pushes.no_mails_in_center')}
            bgColor='#E7F5DC'
            setState={setShowCenterReminderPush}
            str='centerReminder'
          />
        )}
    </div>
  )
}

export const PushesWrapper = styled.div``

export default Pushes
