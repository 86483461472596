import * as React from 'react'

const mql = window.matchMedia('(min-width: 901px)')

interface IProps {
  label: string
  fullAddress: string
  classNames?: string
}

class GmapLink extends React.Component<IProps, any> {
  constructor(props: IProps) {
    super(props)
  }

  public render() {
    const encodedFullAddress = encodeURIComponent(this.props.fullAddress)
    const url = `https://www.google.com/maps/search/?api=1&query=${encodedFullAddress}`

    if (mql.matches) {
      return (
        <a
          target='_blank'
          href={url}
          className={this.props.classNames}
          rel='noreferrer'
        >
          {this.props.label}
        </a>
      )
    } else {
      return (
        <a href={url} className={this.props.classNames}>
          {this.props.label}
        </a>
      )
    }
  }
}

export default GmapLink
