import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  ApolloLink
} from '@apollo/client'
import { onError, ErrorResponse } from '@apollo/client/link/error'
import { isDemo, toggleDemoModal } from '@/CustomerArea/demo/Utils'
import { notification } from 'antd'
import i18n from 'i18next'
import session from './session'
import { createUploadLink } from 'apollo-upload-client'

const cache = new InMemoryCache()

const refreshTokenMiddleware = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext()
    const {
      response: { headers }
    } = context
    const newToken = headers.get('X-JWT-Token')
    if (newToken) {
      session.refreshSession(newToken)
    }
    return response
  })
})

// Handle Connection error
const errorLink = onError(({ graphQLErrors, networkError }: ErrorResponse) => {
  const statusCode = networkError && (networkError as any).statusCode
  if (graphQLErrors !== undefined) {
    graphQLErrors.forEach((el: any) => {
      if (
        (statusCode === 401 && el.message === 'Unauthorized') ||
        (statusCode === 401 && el.message === 'Missing legal document') ||
        (statusCode === 401 && el.message === 'Incomplete subscription') ||
        (statusCode === 403 && el.message === 'Invalid Authenticity Token')
      ) {
        window.location.href = el.extensions.redirectUrl
        session.clearSession()
      } else if (el.message === 'Unauthorized action') {
        notification.error({
          message: i18n.t('common:unauthorizedAction'),
          description: i18n.t('common:unauthorizedActionDescription'),
          duration: 5
        })
      }
    })
  }
})

const demoLink = new ApolloLink((operation, forward) => {
  return forward!(operation).map((response) => {
    if (
      response.data &&
      response.data.operationType === 'mutation' &&
      response.data.showModal
    ) {
      toggleDemoModal(cache, true)
    }

    return response
  })
})

const httpLink = (countryCode: string) => {
  return createUploadLink({
    uri: process.env['REACT_APP_GRAPHQL_ENDPOINT_' + (countryCode || 'FR')],
    credentials: 'include',
    headers: {
      'X-JWT-Token': session.getSession()
    }
  })
}

export const links = (countryCode: string) => {
  return isDemo()
    ? ApolloLink.from([errorLink, demoLink, httpLink(countryCode)])
    : ApolloLink.from([
        errorLink,
        refreshTokenMiddleware,
        httpLink(countryCode)
      ])
}

const client = (countryCode) => {
  return new ApolloClient({
    uri: process.env['REACT_APP_GRAPHQL_ENDPOINT_' + (countryCode || 'FR')],
    cache: cache,
    link: links(countryCode),
    connectToDevTools: process.env.REACT_APP_ENVIRONMENT === 'development',
    resolvers: {}
  })
}

export const Apollo = (props: any) => {
  return (
    <ApolloProvider client={client(props.countryCode)}>
      {props.children}
    </ApolloProvider>
  )
}
