import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Modal } from 'antd'
import useAlert from 'src/hooks/useAlert'
import { useState } from 'react'
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js'
import { PhoneInput } from '@/components/phoneInput'
import { BasicButton } from '@sedomicilier/material-sd'
import { useMutation } from '@apollo/client'
import { PHONE_UPDATE_MUTATION } from '@/graphql/mutations/informationChange'
import { ContactContext } from '@/contexts/contactContext'

const PhoneModal = ({ onCloseModal }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { setAlert } = useAlert()

  const { contact }: any = React.useContext(ContactContext)

  const phoneValue = parsePhoneNumber(contact.phoneNumber)

  const [loading, setLoading] = useState<boolean>(false)
  const [alpha2CountryCode, setAlpha2CountryCode] = useState<CountryCode>(
    phoneValue.country || 'FR'
  )
  const [updatePhone] = useMutation(PHONE_UPDATE_MUTATION)

  const onFinish = (values) => {
    setLoading(true)
    const parsedPhoneNumber = values.phone
      ? parsePhoneNumber(values.phone, alpha2CountryCode)
      : null

    updatePhone({
      variables: {
        phoneNumber: parsedPhoneNumber?.number
      }
    }).then(({ data }) => {
      setLoading(false)
      if (data.error) {
        setAlert({ type: 'error', text: t('common:error'), size: 'small' })
      } else {
        onCloseModal()
        setAlert({ type: 'success', text: t('common:success'), size: 'small' })
      }
    })
  }

  return (
    <Modal
      data-id='domiciliation-phone-update'
      open={true}
      onCancel={onCloseModal}
      footer={null}
      title={t('enterprise:phone')}
    >
      <Form
        form={form}
        initialValues={{
          phone: phoneValue.nationalNumber
        }}
        layout='vertical'
        requiredMark={false}
        onFinish={onFinish}
      >
        <Form.Item
          name='phone'
          rules={[
            {
              required: true,
              message: t('common:validation.phone')
            },
            {
              message: t('common:validation.invalidNumber'),
              validator: (_, value) => {
                if (
                  value &&
                  parsePhoneNumber(value, alpha2CountryCode).isValid()
                ) {
                  return Promise.resolve()
                } else {
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise.reject()
                }
              }
            }
          ]}
        >
          <PhoneInput
            phoneTestid='phone-input'
            countryCodeTestid='country-code-select'
            form={form}
            defaultPhoneValue={phoneValue.nationalNumber}
            locale='FR'
            placeholder={t('common:placeholder.phone')}
            countryDefaultValue={alpha2CountryCode}
            setAlpha2CountryCode={setAlpha2CountryCode}
          />
        </Form.Item>
        <Form.Item>
          <BasicButton
            label={t('common:validate')}
            bgColor='blue'
            loading={loading}
            className='w-full justify-center body-1 font-semibold'
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default PhoneModal
