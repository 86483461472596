import { DOMICILIATION_QUERY } from '@/graphql/queries/domiciliation'
import { IPaymentSource } from '@/interfaces/paymentSource'
import { IFailedPayment } from '@/interfaces/failedPayment'
import { MutationFunction } from '@apollo/client'
import { INVOICES_QUERY } from '@/graphql/queries/invoices'
import { FAILED_PAYMENT_FRAGMENT } from '@/graphql/Fragments'

export const cardElementProps = {
  style: {
    base: {
      fontSize: '14px',
      color: '#282c2e',
      fontFamily: 'inherit',
      '::placeholder': {
        color: 'rgba(123, 126, 128, 0.57)'
      }
    },
    invalid: {
      color: 'red'
    }
  }
}

export const confirmUnpaidInvoice = (
  stripe: any | undefined,
  client: any,
  unpaidInvoice: IFailedPayment,
  mutate: MutationFunction
): Promise<IPaymentSource | void> => {
  return new Promise<IPaymentSource | void>((resolve, reject) => {
    if (stripe) {
      stripe
        .confirmCardPayment(
          unpaidInvoice.paymentIntent!.paymentIntentSecret!,
          {}
        )
        .then((result: any) => {
          if (result.error) {
            const cachedData = client.cache.extract()

            const failedPayments = Object.keys(cachedData)
              .filter((key) => key.match(/^FailedPayment:/))
              .filter((key) => {
                const failedPayment = cachedData[key] as any as IFailedPayment
                return failedPayment && failedPayment.id === unpaidInvoice.id
              })

            failedPayments.forEach((id) => {
              client.cache.writeFragment({
                id,
                fragment: FAILED_PAYMENT_FRAGMENT,
                fragmentName: 'FailedPaymentFragment',
                data: {
                  id: unpaidInvoice.id,
                  total: unpaidInvoice.total,
                  issueDate: unpaidInvoice.issueDate,
                  pdfUrl: null,
                  formattedName: unpaidInvoice.formattedName,
                  invoiceNumber: unpaidInvoice.invoiceNumber,
                  __typename: 'FailedPayment',
                  state: 'opened'
                }
              })
            })

            reject(result.error.message)
          } else {
            mutate({
              variables: { paymentIntentId: unpaidInvoice!.paymentIntent!.id },
              refetchQueries: [
                { query: INVOICES_QUERY },
                { query: DOMICILIATION_QUERY }
              ]
            })
              .then(() => resolve())
              .catch((error) => {
                reject(error.message)
              })
          }
        })
    } else {
      const error = new Error("Stripe.js hasn't loaded yet.")
      reject(error)
    }
  })
}
