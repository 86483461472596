import { DOCUMENT_HOST_FRAGMENT } from '@/graphql/Fragments/documentHost'
import { gql } from '@apollo/client'

export const UPSERT_DOCUMENT_HOST = gql`
  mutation upsertDocumentHost(
    $location: AccountingDocumentLocation!
    $accountantName: String
    $email: String
    $streetAddress: String
    $postalCode: String
    $city: String
    $country: String
    $noAccountant: Boolean
  ) {
    accountingDocumentLocation(
      location: $location
      accountantName: $accountantName
      email: $email
      streetAddress: $streetAddress
      postalCode: $postalCode
      city: $city
      country: $country
      noAccountant: $noAccountant
    ) {
      documentHost {
        ...DocumentHostFragment
      }
    }
  }
  ${DOCUMENT_HOST_FRAGMENT}
`
