import { useState } from 'react'
import { IUserMail } from '@/interfaces/userMail'
import UserMailProvider from '@/contexts/UserMailContext'
import UserMails from '@/CustomerArea/userMails/UserMails'

const Index = () => {
  const [selectedMail, setSelectedMail] = useState<IUserMail | undefined>(
    undefined
  )
  return (
    <UserMailProvider
      value={{
        selectedMail,
        setSelectedMail
      }}
    >
      <UserMails />
    </UserMailProvider>
  )
}

export default Index
