import { useState } from 'react'
import { Form, Input, FormInstance } from 'antd'
import { useTranslation } from 'react-i18next'
import { PlacesAutocomplete } from '@sedomicilier/material-sd'
import CountrySelect from '@/components/countries/countrySelect'
import { compact } from '@sedomicilier/material-sd'

interface AddressFormItemsProps {
  form: FormInstance
  initialAddress?: string
  baseField?: number | string
  listName?: number | string
  addressLabel?: string
  addressValidation?: string
}

const AddressFormItems: React.FC<AddressFormItemsProps> = ({
  form,
  initialAddress,
  baseField,
  listName,
  addressLabel,
  addressValidation
}) => {
  const { t } = useTranslation('forwardingAddress')
  const [manualAddress, setManualAddress] = useState<boolean>(false)

  return (
    <div className='[&_input]:h-5 [&_.ant-form-item]:mb-2'>
      {manualAddress ? (
        <>
          <Form.Item
            data-testid='street-address-input'
            name={compact([baseField, 'address', 'streetAddress'])}
            label={t('form.street_address')}
            rules={[
              { required: true, message: t('form.validations.street_address') }
            ]}
          >
            <Input
              data-testid='street-address-input'
              placeholder={t('form.placeholders.street_address')}
            />
          </Form.Item>
          <div className='flex [&>div]:flex-1 gap-2'>
            <Form.Item
              data-testid='city-input'
              className='w-1.5/5'
              name={compact([baseField, 'address', 'city'])}
              label={t('form.city')}
              rules={[{ required: true, message: t('form.validations.city') }]}
            >
              <Input
                data-testid='city-input'
                placeholder={t('form.placeholders.city')}
              />
            </Form.Item>
            <Form.Item
              data-testid='postal-code-input'
              className='w-1/5'
              name={compact([baseField, 'address', 'postalCode'])}
              label={t('form.postal_code')}
              rules={[
                { required: true, message: t('form.validations.postal_code') }
              ]}
            >
              <Input
                data-testid='postal-code-input'
                placeholder={t('form.placeholders.postal_code')}
              />
            </Form.Item>
            <Form.Item
              data-testid='country-input'
              className='w-1/3'
              initialValue='FR'
              name={compact([baseField, 'address', 'countryCode'])}
              label={t('form.country')}
              rules={[
                { required: true, message: t('form.validations.country') }
              ]}
            >
              <CountrySelect
                defaultValue='FR'
                onSelect={(value) =>
                  form.setFieldValue(
                    compact([listName, baseField, 'address', 'countryCode']),
                    value
                  )
                }
                className='h-5'
              />
            </Form.Item>
          </div>
        </>
      ) : (
        <Form.Item
          data-testid='address-input'
          name={compact([baseField, 'address'])}
          label={addressLabel || t('form.address')}
          rules={[
            {
              message: addressValidation || t('form.validations.address'),
              validator: (_, value) => {
                if (!value?.city) {
                  return Promise.reject(
                    new Error(t('form.validations.address'))
                  )
                }
                return Promise.resolve()
              }
            }
          ]}
        >
          <PlacesAutocomplete
            onSelect={(value) =>
              form.setFieldValue(
                compact([listName, baseField, 'address']),
                value
              )
            }
            initialAddress={initialAddress}
            setNotFound={() => setManualAddress(true)}
            NotFoundOption={
              <>
                <p className='text-4 font-semibold text-interation text-primary-500'>
                  {t('form.autocomplete.not_found')}
                </p>
                <p className='text-5 text-primary-500'>
                  {t('form.autocomplete.manual')}
                </p>
              </>
            }
          />
        </Form.Item>
      )}
      <Form.Item
        data-testid='address-add-info-input'
        name={compact([baseField, 'complement'])}
        label={t('form.additional_information')}
      >
        <Input
          data-testid='address-add-information-input'
          placeholder={t('form.placeholders.additional_information')}
        />
      </Form.Item>
    </div>
  )
}

export default AddressFormItems
