// @ts-nocheck
import React, { Dispatch, SetStateAction, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import { Alert } from 'antd'
import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'
import { PUBLIC_PAYMENT_SOURCE_CREATE_MUTATION } from '@/graphql/mutations/paymentSource'
import {
  Button,
  ClearBoth,
  CvcImg,
  H4,
  ImgCards,
  Label,
  LeftCheckbox,
  RightLabel
} from './styles'
import ImgCreditCards from 'src/images/cards.svg'
import ImgCVC from 'src/images/cvc.svg'
import { IUnloggedContactProps } from './ContactUnlogged'
import { notification } from 'antd'
import { useParams } from 'react-router-dom'

interface ICardInformation {
  cardNumber: boolean
  cardExpiry: boolean
  cardCvc: boolean
}

interface ISecondStepProps {
  regularizeInvoicesData: IUnloggedContactProps['unloggedContact']
  setStep: Dispatch<SetStateAction<number>>
}

const SecondStep: React.FC<ISecondStepProps> = ({
  regularizeInvoicesData,
  setStep
}) => {
  const { t } = useTranslation()
  const params = useParams<{ token: string }>()

  const [formLoading, setFormLoading] = useState<boolean>(false)
  const [stripeError, setStripeError] = useState<string>('')
  const [paymentSourceCreate] = useMutation(
    PUBLIC_PAYMENT_SOURCE_CREATE_MUTATION
  )

  const cardInformation: ICardInformation = {} as ICardInformation

  const stripe: any = useStripe()
  const elements: any = useElements()

  const success = () => {
    notification.success({
      message: t('invoices:regularize.form.success.message'),
      description: t('invoices:regularize.form.success.description'),
      duration: 15
    })
  }

  const cardOnChange = (values: any) => (item: any) => {
    cardInformation[item.elementType] = item.complete && !item.error
    values[item.elementType] = item.complete && !item.error
  }

  const FormSchema = Yup.object().shape({
    name: Yup.string().required(t('invoices:regularize.form.errors.required')),
    cardNumber: Yup.boolean().oneOf(
      [true],
      t('invoices:regularize.form.errors.required')
    ),
    cardExpiry: Yup.boolean().oneOf(
      [true],
      t('invoices:regularize.form.errors.required')
    ),
    cardCvc: Yup.boolean().oneOf(
      [true],
      t('invoices:regularize.form.errors.required')
    )
  })

  const pay = (values: any) => {
    setFormLoading(true)
    const cardNumberElement = elements.getElement(CardNumberElement)
    stripe
      .createToken(cardNumberElement)
      .then(({ token, error }) => {
        if (error) {
          throw error.message
        }

        return stripe.confirmCardPayment(
          regularizeInvoicesData?.regularizationInformations.paymentIntent
            .stripeClientSecret,
          {
            payment_method: {
              card: { token: token.id },
              billing_details: {
                name: values.name
              }
            },
            setup_future_usage: values.keepCard ? 'off_session' : 'on_session'
          }
        )
      })
      .then((data) => {
        if (data?.error?.message) {
          throw data.error.message
        }

        return paymentSourceCreate({
          variables: {
            token: data.paymentIntent.payment_method,
            forceDefault: values.keepCard,
            securityToken: encodeURIComponent(params.token!)
          }
        })
      })
      .then(() => {
        success()
        setStripeError('')
        setStep(3)
      })
      .catch((errorMsg) => {
        setStripeError(errorMsg)
      })
      .finally(() => {
        setFormLoading(false)
      })
  }

  return (
    <div>
      <h4>{t('invoices:regularize.payment')}</h4>

      <div className='progress'>
        <div className='progress-bar' style={{ width: '100%' }} />
      </div>

      <Formik
        initialValues={{
          name: '',
          cardNumber: false,
          cardExpiry: false,
          cardCvc: false,
          keepCard: true
        }}
        validationSchema={FormSchema}
        onSubmit={(values) => {
          pay(values)
        }}
      >
        {({ errors, touched, values }) => (
          <Form>
            <div className='boxes'>
              <div className='box box-left'>
                <h4>
                  {t('invoices:regularize.cbInformation')}{' '}
                  <ImgCards src={ImgCreditCards} />
                </h4>
                <Label>
                  {t('invoices:regularize.form.name')}
                  <br />
                  <Field className='stripe-element' name='name' />
                  {errors.name && touched.name ? (
                    <div className='error-input'>{errors.name}</div>
                  ) : null}
                </Label>
                <Label>
                  {t('invoices:regularize.form.number')}
                  <br />
                  <CardNumberElement
                    className='stripe-element'
                    onChange={cardOnChange(values)}
                  />
                  {errors.cardNumber && touched.cardNumber ? (
                    <div className='error-input'>{errors.cardNumber}</div>
                  ) : null}
                </Label>
                <Label>
                  {t('invoices:regularize.form.date')}
                  <br />
                  <CardExpiryElement
                    className='stripe-element-small'
                    onChange={cardOnChange(values)}
                  />
                  {errors.cardExpiry && touched.cardExpiry ? (
                    <div className='error-input'>{errors.cardExpiry}</div>
                  ) : null}
                </Label>
                <Label>
                  {t('invoices:regularize.form.crypto')}
                  <br />
                  <CvcImg src={ImgCVC} />
                  <CardCvcElement
                    className='stripe-element-small'
                    onChange={cardOnChange(values)}
                  />
                  {errors.cardCvc && touched.cardCvc ? (
                    <div className='error-input'>{errors.cardCvc}</div>
                  ) : null}
                </Label>
                <Label>
                  <LeftCheckbox>
                    <Field type='checkbox' name='keepCard' />
                  </LeftCheckbox>
                  <RightLabel>
                    {t('invoices:regularize.form.default')}
                  </RightLabel>
                  <ClearBoth />
                </Label>
              </div>

              <div className='box box-right'>
                <H4>
                  {t('invoices:regularize.totalToPay')}&nbsp;
                  {t('common:currency', {
                    value:
                      regularizeInvoicesData.regularizationInformations.total
                  })}
                </H4>
                {regularizeInvoicesData.publicFailedPayments.length}&nbsp;
                {regularizeInvoicesData.publicFailedPayments.length > 1
                  ? t('invoices:regularize.invoices')
                  : t('invoices:regularize.invoice')}
                {stripeError && <Alert message={stripeError} type='error' />}
                <br />
                <Button type='submit' disabled={formLoading}>
                  {t('invoices:regularize.form.submit')}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default SecondStep
