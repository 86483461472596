import { gql } from '@apollo/client'

export const PHONE_UPDATE_MUTATION = gql`
  mutation PhoneUpdate($phoneNumber: String!) {
    phoneUpdate(phoneNumber: $phoneNumber) {
      informationChange {
        oldValue
        newValue
        sentTo
        informationableType
      }
    }
  }
`

export const EMAIL_UPDATE_MUTATION = gql`
  mutation EmailUpdate($email: String!) {
    emailUpdate(email: $email) {
      informationChange {
        oldValue
        newValue
        sentTo
        informationableType
      }
    }
  }
`
