import styled from 'styled-components'

export const Wrapper = styled.div`
  .ant-progress {
    width: 250px;
    margin-left: 15px;
  }

  .ant-tag {
    &.confirmed {
      color: #008140;
    }

    &.email_sent,
    &.deactivated {
      color: #75818c;
    }

    &.in_progress {
      color: #e06611;
    }
  }
`

export const Warning = styled.span`
  display: flex;
  align-items: center;
  margin-top: 16px;
  width: 100%;
  background: #ffffff;
  color: #bd5003;
  border-radius: 8px;

  img {
    margin: 10px;
  }
`
