import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Row } from 'antd'
import { Highlighted, Constants, Colors } from 'src/components/UIKit'
import { IProduct } from '@/interfaces/product'

interface IProps {
  removePurchase: (id: string) => void
  purchase: IProduct
}

const Item = (props: IProps) => {
  const { t } = useTranslation()
  const { removePurchase, purchase } = props
  const [disabled, setDisabled] = useState(false)

  const onClickOnRemove = () => {
    setDisabled(true)
    removePurchase(purchase.id)
    setDisabled(false)
  }

  const isCreationFormality =
    purchase.identificationToken === 'creation-express'

  return (
    <Wrapper>
      <Informations>
        <Highlighted>
          {t(`products:title.${purchase.identificationToken}`)}
          {purchase.specialOffer && (
            <>
              &nbsp;
              <SpecialOffer>
                {t(
                  `products:special_offer.${purchase.identificationToken}`,
                  ''
                )}
              </SpecialOffer>
            </>
          )}
        </Highlighted>
        &nbsp;
        <span>
          {t(`products:description.${purchase.identificationToken}`, '')}
        </span>
      </Informations>
      <PriceSeparator>-</PriceSeparator>
      <div>
        <RightRow>
          <Highlighted>
            {t('common:currency', { value: purchase.amount / 100 })}
          </Highlighted>
        </RightRow>
        {!isCreationFormality && (
          <RightRow>
            <RemoveLink disabled={disabled} onClick={onClickOnRemove}>
              Supprimer
            </RemoveLink>
          </RightRow>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: -webkit-fill-available;

  @media (max-width: ${Constants.breakpoints['mobile-breakpoint-i']}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const Informations = styled.div`
  margin-right: auto;
  margin-top: 5px;
  align-self: flex-start;
  span {
    display: block;
  }
`

const PriceSeparator = styled.span`
  display: none;

  @media (max-width: ${Constants.breakpoints['mobile-breakpoint-i']}) {
    display: block;
  }
`

const RightRow = styled(Row)`
  justify-content: flex-end;

  @media (max-width: ${Constants.breakpoints['mobile-breakpoint-i']}) {
    justify-content: flex-start;
  }
`

const RemoveLink = styled.a`
  && {
    font-family: 'Open Sans';
    font-weight: 400;
    margin-top: 16px;
    font-size: 14px;
    color: ${Colors.black};
    text-align: right;
    text-decoration-line: none;

    &:hover {
      ${(props) => props.disabled && `color: ${Colors.black};`}
    }
  }
`

const SpecialOffer = styled.div`
  display: inline;
  color: ${Colors.green};
`

export default Item
