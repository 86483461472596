import { gql } from '@apollo/client'
import { HOTLINE_FRAGMENT } from '../Fragments'

export const HOTLINE_CREATE_OR_UPDATE_MUTATION = gql`
  mutation HotlineCreateOrUpdate(
    $hotlinePhoneNumberId: ID!
    $originalPhoneNumber: String!
    $companyActivity: String
    $activityDescription: String!
    $absentReason: AbsentReasons!
    $absentReasonOther: String
    $emergencyPhoneNumber: String
  ) {
    hotlineCreateOrUpdate(
      hotlinePhoneNumberId: $hotlinePhoneNumberId
      originalPhoneNumber: $originalPhoneNumber
      activityDescription: $activityDescription
      absentReason: $absentReason
      absentReasonOther: $absentReasonOther
      emergencyPhoneNumber: $emergencyPhoneNumber
      companyActivity: $companyActivity
    ) {
      hotline {
        ...HotlineFragment
      }
      clientSecret
    }
  }
  ${HOTLINE_FRAGMENT}
`

export const HOTLINE_DESTROY_MUTATION = gql`
  mutation HotlineDestroy {
    hotlineDestroy {
      hotline {
        ...HotlineFragment
      }
    }
  }
  ${HOTLINE_FRAGMENT}
`
