import styled from 'styled-components'
import { Space, Row } from 'antd'
import { Constants } from 'src/components/UIKit'

export const Centred = styled.div`
  height: calc(100vh - 240px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Box = styled.div`
  padding: 0 25px;
  margin: 0 25px 0 0;
  width: -webkit-fill-available;
  width: -moz-available;
  max-width: 650px;

  @media (min-width: ${Constants.breakpoints['mobile-breakpoint-o']}) {
    min-width: 450px;
  }
`

export const LargeSpace = styled(Space)`
  width: inherit;
`

export const Title = styled.h2`
  // font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  padding: 0 0 15px 0;
  margin: 0;
`

export const CircleInfoWrapper = styled.div`
  margin: 0 0 30px 0;
`

export const CircleInfoImg = styled.img`
  margin: 0 0 30px 0;
`

export const SubText = styled.div`
  max-width: 550px;
  height: auto;
  margin: 0 auto 20px auto;
  font-size: 12px;
  color: #7b7e80;
`

// TODO: Remove precedence boost (&&) when globalStyle will be on
export const PrettyLink = styled.span`
  && {
    color: #1576e1;
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    text-decoration: none;
    cursor: pointer;
  }
`

export const ContentRow = styled(Row)`
  width: 100%;
  flex-flow: row nowrap;
  justify-content: space-between;

  @media (max-width: ${Constants.breakpoints['xlarge-breakpoint-i']}) {
    height: auto;
    flex-flow: column nowrap;

    & > .ant-col {
      // same as PackCard
      max-width: 550px;
    }

    #card_select_right_column {
      margin-top: 40px;
    }
  }
`
