import React, { createContext } from 'react'
import { useQuery } from '@apollo/client'
import { CommonLoader } from 'src/components/shared/Loader'
import * as Cookies from 'js-cookie'
import { DomiciliationContextProps } from '@/interfaces/domiciliation'
import { DOMICILIATION_QUERY } from '@/graphql/queries/domiciliation'

export const DomiciliationContext = createContext(
  {} as DomiciliationContextProps
)

interface IProps {
  children: React.ReactNode
}

const DomiciliationProvider = (props: IProps) => {
  const { data, loading, error, refetch } = useQuery(DOMICILIATION_QUERY)

  // used by Bugsnag reporter
  if (data) {
    Cookies.set('domiciliation_id', data?.domiciliation?.id)
  }

  if (loading) {
    return <CommonLoader size={48} />
  }

  return (
    <DomiciliationContext.Provider
      value={{ domiciliation: data?.domiciliation, loading, error, refetch }}
    >
      {props.children}
    </DomiciliationContext.Provider>
  )
}

export default DomiciliationProvider
