import { persistStore } from 'redux-persist'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { setupStore } from '@/store'
import CurrentUserContextProvider from 'src/contexts/currentUserContext'
import AbilityContextProvider from 'src/contexts/abilityContext'
import ContactContextProvider from 'src/contexts/contactContext'
import DomiciliationProvider from 'src/contexts/DomiciliationContext'
import CurrentCartProvider from 'src/contexts/currentCartContext'
import PrivateRouting from './PrivateRouting'

const PrivateApps = () => {
  const store = setupStore()
  const persistor = persistStore(store)

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CurrentUserContextProvider>
          <AbilityContextProvider>
            <ContactContextProvider>
              <DomiciliationProvider>
                <CurrentCartProvider>
                  <PrivateRouting />
                </CurrentCartProvider>
              </DomiciliationProvider>
            </ContactContextProvider>
          </AbilityContextProvider>
        </CurrentUserContextProvider>
      </PersistGate>
    </Provider>
  )
}

export default PrivateApps
