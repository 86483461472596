import { DOMICILIATION_FRAGMENT } from '@/graphql/Fragments'
import { gql } from '@apollo/client'

export const DOMICILIATION_QUERY = gql`
  {
    domiciliation {
      ...DomiciliationFragment
    }
  }
  ${DOMICILIATION_FRAGMENT}
`

export const DOMICILIATION_STEPS_STATUSES = gql`
  {
    domiciliationStepsStatuses {
      step
      status
    }
  }
`
