import styled from 'styled-components'

export const LinkBack = styled.a`
  color: #1576e1 !important;
  text-decoration-line: underline !important;
  float: right;
`

export const Header = styled.div`
  &:after {
    content: ' ';
    display: block;
    clear: both;
    margin-bottom: 50px;
  }
`

export const Logo = styled.div`
  float: left;
`

export const H2 = styled.h2`
  color: #191d20;
  font-size: 26px;
  line-height: 140%;
  font-weight: 600;
`

export const H3 = styled.h3`
  color: #75818c;
  font-size: 20px;
`

export const H4 = styled.h4`
  font-size: 16px;
  line-height: 150%;
  color: #191d20;
  font-weight: 600;
`

export const ErrorTag = styled.span`
  background: #f2e6e6;
  border-radius: 64px;
  padding: 4px 12px;
  color: #cd100c;
`
export const Button = styled.button`
  background: #1576e1;
  border-radius: 4px;
  color: white;
  padding: 11px 12px;
  border: none;
  cursor: pointer;
  margin-top: 48px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  display: block;
  &:disabled {
    background: #b9c4cd;
    cursor: unset;
  }
`
export const ImgCards = styled.img`
  float: right;
`

export const CvcImg = styled.img`
  position: absolute;
  margin-left: 140px;
  margin-top: 5px;
`

export const Label = styled.label`
  height: auto;
  display: block;
  font-weight: 500;
  margin-top: 24px;
  font-size: 14px;
  line-height: 150%;
`

export const Light = styled.span`
  color: #75818c;
  font-size: 14px;
  line-height: 19px;
`

export const LeftCheckbox = styled.span`
  float: left;
  width: 25px;
`

export const RightLabel = styled.span`
  float: left;
  width: calc(100% - 40px);
`
export const ClearBoth = styled.br`
  clear: both;
`
