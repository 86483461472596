import React from 'react'
import { useTranslation } from 'react-i18next'
import Routing from '@/CustomerArea/Routing'
import Confirm from 'src/images/green-confirm.svg'
import styled from 'styled-components'

interface INoInvoicesProps {
  hasPaid?: boolean
}

const NoInvoices: React.FC<INoInvoicesProps> = ({ hasPaid }) => {
  const { t } = useTranslation()
  return (
    <NoInvoicesWrapper>
      <Img src={Confirm} alt='green confirm' />
      <NoInvoicesText>
        {hasPaid
          ? t('invoices:regularize.hasPaid')
          : t('invoices:regularize.noInvoices')}
      </NoInvoicesText>
      <Link href={Routing.ROOT}>{t('invoices:regularize.customerArea')}</Link>
    </NoInvoicesWrapper>
  )
}

export const NoInvoicesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: 160px auto 0 auto;
`
export const Img = styled.img`
  margin-bottom: 16px;
`

export const NoInvoicesText = styled.div`
  color: #191d20;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
  text-align: center;
`
export const Link = styled.a`
  display: flex;
  justify-content: center;
  background: #1576e1;
  border-radius: 8px;
  color: white !important;
  padding: 14px 24px;
  border: none;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
`

export default NoInvoices
