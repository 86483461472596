import React from 'react'
import { IUserMail } from '@/interfaces/userMail'
import { UserMailQueryType } from './Enum'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMailbox, faShareAlt } from '@sedomicilier/pro-light-svg-icons'
import { faEllipsisH } from '@sedomicilier/pro-regular-svg-icons'
import { Can } from 'src/contexts/abilityContext'
import { Divider, Dropdown } from 'antd'

interface IProps {
  userMail: IUserMail
  handleAction: (type: 'showModalShare') => void
  onNewUserMailQuery: (type: UserMailQueryType) => void
}

const Actions = (props: IProps) => {
  const { t } = useTranslation()

  if (
    props.userMail.shareable ||
    props.userMail.allowedOptions.includes(UserMailQueryType.provisioning)
  ) {
    return (
      <Dropdown
        menu={{ items: [] }}
        overlayStyle={{
          background: 'white',
          boxShadow: '0 5px 20px 0 #0000001a',
          borderRadius: '8px'
        }}
        dropdownRender={(menu) => (
          <div>
            {React.cloneElement(menu as React.ReactElement, {
              style: { boxShadow: 'none', background: 'none' }
            })}
            <Can I='customer_manage' a='UserMailQuery'>
              {props.userMail.allowedOptions.includes(
                UserMailQueryType.provisioning
              ) && (
                <a
                  onClick={() =>
                    props.onNewUserMailQuery(UserMailQueryType.provisioning)
                  }
                  className='flex p-3 hover:no-underline hover:bg-neutral-150 hover:text-neutral-600'
                >
                  <FontAwesomeIcon icon={faMailbox} className='flex-initial' />
                  <span className='flex-1 px-2 -mt-0.5'>
                    {t('userMails:actions.provisioning')}
                  </span>
                </a>
              )}
              <Divider style={{ margin: 0 }} />
            </Can>

            <Can I='customer_manage' a='UserMail'>
              {props.userMail.shareable && (
                <a
                  onClick={() => props.handleAction('showModalShare')}
                  className='flex p-3 hover:no-underline hover:bg-neutral-150 hover:text-neutral-600'
                >
                  <FontAwesomeIcon icon={faShareAlt} className='flex-initial' />
                  <span className='flex-1 px-2 -mt-0.5'>
                    {t('userMails:actions.share')}
                  </span>
                </a>
              )}
            </Can>
          </div>
        )}
      >
        <FontAwesomeIcon icon={faEllipsisH} />
      </Dropdown>
    )
  }
  return <></>
}

export default Actions
