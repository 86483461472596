interface OnboardingStepProps {
  label: string
  withArrow?: boolean
  disabled?: boolean
  onClick?: () => void
  icon: string | undefined
}

const OnboardingStep: React.FC<OnboardingStepProps> = ({
  label,
  withArrow,
  disabled,
  onClick,
  icon
}) => {
  return (
    <button
      className='flex items-center justify-between gap-2 rounded-medium border border-interaction p-2 bg-neutral-0 w-full disabled:opacity-50'
      onClick={onClick}
      disabled={!disabled}
    >
      <img src={icon} alt='first step' />
      <p
        dangerouslySetInnerHTML={{ __html: label }}
        className='flex-1 text-start body-1 [&>span]:text-interaction [&>span]:font-semibold'
      />
      {withArrow && (
        <span className='ico-arrow-right text-l text-interaction' />
      )}
    </button>
  )
}

export default OnboardingStep
