import { gql } from '@apollo/client'
import { CART_FRAGMENT } from '@/graphql/Fragments'

export const CURRENT_CART_QUERY = gql`
  {
    currentCart {
      ...CartFragment
    }
  }

  ${CART_FRAGMENT}
`
