import gql from 'graphql-tag'
import { LEGAL_FORMALITY_FRAGMENT } from './legalFormality'

export const PURCHASE_FRAGMENT = gql`
  fragment PurchaseFragment on Purchase {
    id
    identificationToken
    amount
    specialOffer
    status
    legalFormality {
      ...LegalFormalityFragment
    }
  }
  ${LEGAL_FORMALITY_FRAGMENT}
`
