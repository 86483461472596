import ICancelationProcedure from '@/interfaces/cancelationProcedure'

const endingStates: string[] = [
  'rescued',
  'ready_to_be_terminated',
  'official_notification',
  'done'
]

export function extractSubtype(cancelationProcedureType?: string) {
  if (!cancelationProcedureType) {
    return 'unknown'
  }

  const subtypes = cancelationProcedureType.split('::')
  subtypes.shift()

  return subtypes.join('')
}

export function mustDisplayEndDate(
  cancelationProcedure: ICancelationProcedure
) {
  return (
    endingStates.indexOf(cancelationProcedure.state || '') !== -1 &&
    cancelationProcedure.cancelationExpectedDate
  )
}
