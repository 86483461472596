import styled from 'styled-components'
import { Colors, Constants } from 'src/components/UIKit'
import { Button } from 'antd'

export const Tile = styled.div`
  max-width: ${(props) => props.tileWidth || 650}px;
  min-width: 350px;
  height: 100px;
  display: flex;

  justify-content: space-between;
  align-items: center;

  margin: 15px 0 0 0;
  padding: 15px;

  background: ${Colors.white};
  border: 1px solid ${Colors.greyBox};
  border-radius: 4px;

  .tile-left {
    display: flex;
    align-items: center;

    img {
      width: 48px;
      height: 48px;
      margin: 0 25px 0 0;
    }

    h4 {
      margin: 0 0 10px 0;
    }
  }

  .tile-right {
    height: 100%;
    min-width: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }

  a.informations {
    text-decoration: underline;
    color: ${Colors.black};

    &:hover {
      color: ${Colors.black};
      text-decoration: none;
    }
  }
`

export const Intro = styled.span`
  font-style: italic;
`

export const ModalBodyWrapper = styled.div`
  h6 {
    margin: 15px 0 5px 0;
  }

  ul {
    list-style-type: square;

    li {
      color: ${Colors.greyBody};
      font-size: 14px;
    }
  }
`

export const StatusWrapper = styled.div`
  max-width: ${(props) => props.tileWidth || 650}px;
  padding: 24px;
  border: 1px solid ${Colors.greyBox};
  border-radius: 4px;
  background-color: ${Colors.white};

  h4 {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 16px;
  }

  .upload-card {
    width: 100%;
    display: flex;
    justify-content: space-around;

    .ant-card-body {
      width: 100%;
      display: flex;
      flex-flow: column;
      align-items: center;
    }

    form {
      display: flex;
      flex-flow: column;
      align-items: center;

      button.submit {
        span {
          color: white;
        }
      }
    }
  }

  @media (max-width: ${Constants.breakpoints['large-breakpoint-i']}) {
    .uikit-progress-bar-steps {
      flex-direction: column;
      .uikit-progress-bar-step {
        margin: 15px 0 0 0;
        &:first-child {
          margin: 0;
        }
      }
    }
  }
`

export const FormButton = styled(Button)`
  border-color: #eb8500;
  background: #eb8500;

  &:hover,
  &:active,
  &:focus {
    border-color: #eb8500;
    background: #eb8500;
  }

  span {
    color: #fff;
    font-weight: 600;
  }
`
export const H3 = styled.h3`
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #131416;
`

export const UploadWrapper = styled.div`
  width: 80%;
  diplay: flex;
  flew-flow: column;
`
