import timbreIcon from '@/images/legalFlow/timbre.svg'
import { PlacesAutocompleteAddressType } from '@sedomicilier/material-sd/src/components/PlacesAutocomplete/placesAutocompleteAddressType'

interface EnvelopeProps {
  name: string
  address: PlacesAutocompleteAddressType
  complement?: string
}

const Envelope: React.FC<EnvelopeProps> = ({ name, address, complement }) => {
  return (
    <div
      data-testid='envelope'
      className='border-solid border rounded-lg border-neutral-100 w-full p-2
                 max-w-[350px] h-[170px] mb-3 flex justify-between items-center'
    >
      <div translate='no'>
        <span className='block text-6'>{name}</span>
        <span className='block text-6'>{address?.streetAddress}</span>
        <span className='block text-6'>{complement}</span>
        <span className='block text-6'>{`${address?.postalCode} ${address?.city}`}</span>
        <span className='block text-6'>{address?.country}</span>
      </div>
      <img className='self-start m-2' src={timbreIcon} alt='timbre' />
    </div>
  )
}

export default Envelope
