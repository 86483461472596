import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import colors from 'src/components/UIKit/colors'

const LeaderResidence = ({ selectedResidence, setSelectedResidence }) => {
  const { t } = useTranslation()
  return (
    <LeaderResidenceWrapper>
      <LeaderResidenceTitle>{t('procedure:residence')}</LeaderResidenceTitle>
      <ResidenceChoices>
        <ResidenceChoice
          selected={selectedResidence === 'personalHome'}
          onClick={() => setSelectedResidence('personalHome')}
        >
          {t('procedure:personalHome')}
        </ResidenceChoice>
        <ResidenceChoice
          selected={selectedResidence === 'hosted'}
          onClick={() => setSelectedResidence('hosted')}
        >
          {t('procedure:hosted')}
        </ResidenceChoice>
      </ResidenceChoices>
    </LeaderResidenceWrapper>
  )
}

export const LeaderResidenceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
`
export const LeaderResidenceTitle = styled.div`
  color: ${colors.black};
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
`
export const ResidenceChoices = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
export const ResidenceChoice = styled.button`
  border: ${(props) => (props.selected ? `1px solid ${colors.blue}` : 'none')};
  background-color: ${(props) =>
    props.selected ? colors.mediumBlueBackground : colors.lightBlueBackground};
  border-radius: 8px;
  padding: 15px;
`

export default LeaderResidence
