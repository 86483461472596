import styled from 'styled-components'
import { Colors } from 'src/components/UIKit'

export const DrawerTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${Colors.black};
  margin-bottom: 32px;
`
export const FileFormatNotice = styled.div`
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  color: ${Colors.grey};
  margin-top: 8px;
  margin-bottom: 32px;
`

export const DrawerContentWrapper = styled.div`
  .ant-upload {
    width: 100%;
  }
`
export const PlusIcon = styled.img`
  margin: 0;
  margin-right: 8px;
`
export const ButtonsWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const FileDisplay = styled.div`
  background-color: ${Colors.lightBlueBackground};
  border-radius: 8px;
  color: #686a6b;
  font-size: 14px;
  font-weight: 700;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const CustomModifyButton = styled.button`
  border: none;
  color: ${Colors.blue};
  background-color: transparent;
  font-size: 14px;
  font-weight: 600;
`
export const ValidationErrorNotice = styled.div`
  color: ${Colors.error};
  font-size: 12px;
  margin-top: 4px;
`
