import * as React from 'react'
import MobileBreadcrumb from '@/components/shared/MobileBreadcrumb'

interface MobileBreadcrumbHelperProps {
  Component: React.ComponentType
  subtitle: string
  onSetSidebarState: (open: boolean) => void
}

const MobileBreadcrumbHelper: React.FC<MobileBreadcrumbHelperProps> = ({
  Component,
  subtitle,
  onSetSidebarState
}) => {
  return (
    <>
      <MobileBreadcrumb
        title='settings'
        subtitle={subtitle}
        onSetSidebarState={onSetSidebarState}
      />
      <Component />
    </>
  )
}

export default MobileBreadcrumbHelper
