import styled from 'styled-components'
import { Colors } from 'src/components/UIKit'

export const ProcedureCardWrapper = styled.div`
  padding: 24px;
  border: 1px solid ${Colors.greyBorder};
  box-shadow: 0px 4px 21px 0px rgba(25, 29, 32, 0.08);
  border-radius: 16px;

  font-size: 14px;
  font-family: 'Inter';
  line-height: 150%;
  color: ${Colors.black};
`
export const TopPart = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
`
export const ProcedureCardIcon = styled.img`
  margin: 0;
`
export const SectionTitle = styled.div``
export const DynamicName = styled.span`
  font-weight: 600;
`
export const Documents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
