/* Specific to Formik */

import * as React from 'react'
import { FormikProps, Field } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@sedomicilier/pro-light-svg-icons'

const mql = window.matchMedia('(min-width: 901px)')

interface IState {
  isOpen: boolean
  mobileOptions: React.ReactNode[]
  options: Array<{ id: string; display: string }>
}

interface IProps extends FormikProps<any> {
  options: Array<{ id: string; display: string }>
  value: string
  name: string
  classNames?: string
  includeBlank?: boolean
  placeholder?: string
  setSelectValue?: (el: string) => void
}

/* eslint-disable no-invalid-this */
class CustomSelect extends React.Component<IProps, IState> {
  private wrapperRef: any

  constructor(props: IProps) {
    super(props)

    const buildOptions = [...this.props.options]
    const builtMobileOptions = this.props.options.map(
      ({ id, display }: { id: string; display: React.ReactNode }) => (
        <option key={id} value={id}>
          {display}
        </option>
      )
    )

    if (this.props.includeBlank === true || !mql.matches) {
      buildOptions.unshift({ id: '', display: '' })
      builtMobileOptions.unshift(<option key='' value='' />)
    }

    this.state = {
      isOpen: false,
      options: buildOptions,
      mobileOptions: builtMobileOptions
    }

    this.handleClose = this.handleClose.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.renderDisplay = this.renderDisplay.bind(this)
    this.renderOptions = this.renderOptions.bind(this)
    this.setValue = this.setValue.bind(this)
    this.isPlaceholder = this.isPlaceholder.bind(this)
    this.wrapperRef = React.createRef()
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  public render() {
    const { isOpen } = this.state
    if (mql.matches) {
      return (
        <div
          ref={this.wrapperRef}
          className={`custom-select ${isOpen ? 'open' : 'closed'} ${this.props.classNames}`}
        >
          <div
            className={`field ${this.isPlaceholder() ? 'placeholder-option' : ''}`}
            onClick={isOpen ? this.handleClose : this.handleOpen}
          >
            <div className='display'>{this.renderDisplay()}</div>
            <div className='button'>
              {isOpen ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </div>
          </div>
          {isOpen ? (
            <div className='options'>{this.renderOptions()}</div>
          ) : null}
          {isOpen ? (
            <div className='overlay' onClick={this.handleClose} />
          ) : null}
        </div>
      )
    } else {
      return (
        <Field
          component='select'
          name={this.props.name}
          className={this.props.classNames}
        >
          {this.state.mobileOptions}
        </Field>
      )
    }
  }

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  private isPlaceholder = () => {
    return (
      this.props.placeholder !== undefined &&
      this.renderDisplay() === this.props.placeholder
    )
  }

  private handleClose = () => this.setState({ isOpen: false })
  private handleOpen = () => this.setState({ isOpen: true })

  private handleClick = (value: any) => () => {
    this.setValue(value)
    this.handleClose()
  }

  private handleClickOutside(event: any) {
    if (
      this.wrapperRef &&
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.handleClose()
    }
  }

  private renderDisplay = () => {
    const { value } = this.props
    const { options } = this.state
    const found = options.find((option: { id: any }) => option.id === value)
    if (found) {
      return found.display
    }
    if (this.props.placeholder !== undefined) {
      return this.props.placeholder
    } else {
      return 'Select an option'
    }
  }

  private renderOptions = () => {
    return this.state.options.map(
      (option: {
        id: string | number | undefined
        display: React.ReactNode
      }) => (
        <div
          className='option'
          key={option.id}
          onClick={this.handleClick(option.id)}
        >
          {option.display}
        </div>
      )
    )
  }

  private setValue = (value: any) => {
    const valToSet = value ? value.toString() : ''
    this.props.setFieldValue(this.props.name, valToSet)

    if (this.props.setSelectValue !== undefined) {
      this.props.setSelectValue(valToSet)
    }
  }
}
/* eslint-enable no-invalid-this */

export default CustomSelect
