import { USER_NOTIFICATION_FRAGMENT } from '@/graphql/Fragments'
import IUserNotification from '@/interfaces/userNotification'
import { gql } from '@apollo/client'

export interface UserNotificationRecordData {
  userNotificationRecord: IUserNotification
}

export const USER_NOTIFICATION_RECORD_MUTATION = gql`
  mutation UserNotificationRecord(
    $notifiableType: String!
    $notifiableId: ID!
  ) {
    userNotificationRecord(
      notifiableType: $notifiableType
      notifiableId: $notifiableId
    ) {
      userNotification {
        ...UserNotificationFragment
      }
    }
  }
  ${USER_NOTIFICATION_FRAGMENT}
`
