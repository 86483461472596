/* eslint-disable camelcase */
import { IPaymentSource } from '@/interfaces/paymentSource'
import { IFailedPayment } from '@/interfaces/failedPayment'

export enum InvoiceOrderColumn {
  issue_date = 'issue_date'
}

export enum InvoiceOrderDirection {
  asc = 'asc',
  desc = 'desc'
}

export interface IOrder {
  column: InvoiceOrderColumn
  direction: InvoiceOrderDirection
}

export interface IRetryPaymentProps {
  paymentSources: IPaymentSource[]
  unpaidInvoice: IFailedPayment
  onCloseModal: () => void
  onSuccess: () => void
  alertFailedPayment: () => void
}
