'use client'

import { useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from '@/hooks/redux'
import { setPath } from '@/reducers/workflow'

export default function WorkflowNavigation({
  children
}: {
  children: React.ReactNode
}) {
  const location = useLocation()
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const storedPath = useSelector((state) => state.workflow.path)
  const pathInitialized = useRef(false)

  useEffect(() => {
    const pathname = location.pathname

    if (pathname === '/legal-flow' && storedPath === undefined) {
      pathInitialized.current = true
      dispatch(setPath('/legal-flow/forwarding-address'))
    } else if (pathname === '/legal-flow') {
      pathInitialized.current = true
      dispatch(setPath(storedPath))
    } else if (pathname !== storedPath) {
      dispatch(setPath(pathname))
    }
  }, [])

  useEffect(() => {
    const pathname = location.pathname

    if (pathname !== storedPath && pathInitialized.current) {
      navigate(storedPath)
    }

    if (!pathInitialized.current) pathInitialized.current = true
  }, [storedPath])

  return <>{children}</>
}
