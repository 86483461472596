import gql from 'graphql-tag'
import { APPOINTMENT_FRAGMENT_MAXI } from '../Fragments'

export const ROOM_BOOKING_CREATE = gql`
  mutation RoomBookingCreate(
    $roomId: ID!
    $startingAt: ISO8601DateTime!
    $endingAt: ISO8601DateTime!
  ) {
    roomBookingCreate(
      roomId: $roomId
      startingAt: $startingAt
      endingAt: $endingAt
    ) {
      appointment {
        ...AppointmentFragmentMaxi
      }
    }
  }
  ${APPOINTMENT_FRAGMENT_MAXI}
`

export const ROOM_BOOKING_CONFIRM = gql`
  mutation RoomBookingConfirm($appointmentId: ID!, $cgvUuid: String) {
    roomBookingConfirm(appointmentId: $appointmentId, cgvUuid: $cgvUuid) {
      success
    }
  }
`

export const ROOM_BOOKING_ABORT = gql`
  mutation RoomBookingAbort($appointmentId: ID!) {
    roomBookingAbort(appointmentId: $appointmentId) {
      success
    }
  }
`
