import { useEffect, useMemo } from 'react'
import ActionCable from 'action-cable-react-jwt'
import session from 'src/session'

export default function useActionCable(url) {
  const actionCable = useMemo(
    () => ActionCable.createConsumer(url, session.getSession()),
    []
  )

  useEffect(() => {
    return () => {
      actionCable.disconnect()
    }
  }, [])

  return { actionCable }
}
