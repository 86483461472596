const breakpoints = {
  'mobile-breakpoint-i': '900px',
  'mobile-breakpoint-o': '901px',
  'tablet-breakpoint': '1200px',
  'xlarge-breakpoint-i': '1199px',
  'xlarge-breakpoint-o': '1200px'
}

export default {
  breakpoints
}
