import { gql } from '@apollo/client'
import { CONTACT_FRAGMENT } from '../Fragments'

export const UPSERT_LEGAL_REPRESENTATIVE_MORAL = gql`
  mutation upsertLegalRepresentativeMoral(
    $name: String!
    $lastname: String!
    $firstname: String!
    $legalStatusName: String!
    $streetAddress: String
    $postalCode: String
    $city: String
    $country: String
    $complement: String
  ) {
    upsertLegalRepresentativeMoral(
      name: $name
      lastname: $lastname
      firstname: $firstname
      legalStatusName: $legalStatusName
      streetAddress: $streetAddress
      postalCode: $postalCode
      city: $city
      country: $country
      complement: $complement
    ) {
      contact {
        ...ContactFragment
      }
    }
  }
  ${CONTACT_FRAGMENT}
`
