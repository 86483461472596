import {
  CURRENT_USER_FRAGMENT,
  DOMICILIATION_USERS_FRAGMENT
} from '@/graphql/Fragments/users'
import { gql } from '@apollo/client'

export const CURRENT_USER_QUERY = gql`
  {
    currentUser {
      ...CurrentUserFragment
    }
  }

  ${CURRENT_USER_FRAGMENT}
`

export const DOMICILIATION_USERS_QUERY = gql`
  {
    domiciliationUsers {
      ...UsersFragment
    }
  }

  ${DOMICILIATION_USERS_FRAGMENT}
`
