import React, {
  useState,
  useEffect,
  useContext,
  PropsWithChildren
} from 'react'
import { Mutation } from '@apollo/client/react/components'
import {
  CommunicationSection,
  CommunicationStrengthRestriction
} from '@/interfaces/enum'
import ICommunication from '@/interfaces/communication'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@sedomicilier/pro-light-svg-icons'
import { Link, matchPath, useLocation } from 'react-router-dom'
import sectionMatches from './utilities/CommunicationSectionMatches'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import { DOMICILIATION_COMMUNICATION_VIEW_MUTATION } from '@/graphql/mutations/communication'

const Message = ({ communication }: { communication: ICommunication }) => {
  if (communication.link === null) {
    return <span>{communication.message}</span>
  } else {
    if (communication.link!.match(/^http/)) {
      return (
        <a href={communication.link} target='_blank' rel='noreferrer'>
          {communication.message}
        </a>
      )
    } else {
      return <Link to={communication.link!}>{communication.message}</Link>
    }
  }
}

const CommunicationNotification: React.FC<PropsWithChildren> = ({
  children
}) => {
  const location = useLocation()
  const { domiciliation } = useContext(DomiciliationContext)

  const [hidden, setHidden] = useState({
    mail_warning: true,
    payment_failure_mail_option: true
  })
  const [communications, setCommunications] = useState<ICommunication[]>([])

  useEffect(() => {
    if (domiciliation) {
      setCommunications(
        domiciliation.communications.filter(
          (c: ICommunication) =>
            c.intensity === CommunicationStrengthRestriction.notification &&
            toDisplay(c)
        )
      )
    }
  }, [domiciliation, location.pathname, hidden])

  const toDisplay = (communication: ICommunication) => {
    if (hidden[communication.id || communication.communicationType]) {
      return false
    }

    return (
      communication.sectionsToDisplay === undefined ||
      communication.sectionsToDisplay === null ||
      communication.sectionsToDisplay.length === 0 ||
      communication.sectionsToDisplay.find((section: CommunicationSection) => {
        return matchPath(location.pathname, sectionMatches[section]) !== null
      })
    )
  }

  const onClose = (c: ICommunication, markAsViewed: () => void) => {
    if (c.id !== null) {
      markAsViewed()
    }
    setHidden((previousHidden) => {
      const obj = {}
      obj[c.id || c.communicationType] = true
      return { ...previousHidden, ...obj }
    })
  }

  return (
    <>
      <div className='communication-notifications'>
        {communications.map((c: ICommunication) => (
          <div key={c.id || c.communicationType} className='notification'>
            <Mutation
              mutation={DOMICILIATION_COMMUNICATION_VIEW_MUTATION}
              variables={{ id: c.id }}
            >
              {(markAsViewed) => (
                <>
                  <button
                    type='button'
                    className='close historique-close'
                    onClick={() => onClose(c, markAsViewed)}
                  >
                    <FontAwesomeIcon size='xs' icon={faTimes} />
                  </button>
                  <Message communication={c} />
                </>
              )}
            </Mutation>
          </div>
        ))}
      </div>
      {children}
    </>
  )
}

export default CommunicationNotification
