import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { cardElementProps } from 'src/components/shared/Stripe'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'

interface IProps {
  setNewCardToken: (token: string | undefined) => void
}

const NewCard = (props: IProps) => {
  const { t } = useTranslation()

  const stripe: any = useStripe()
  const elements: any = useElements()

  const [error, setError] = useState<string>('')

  const generateNewCardToken = () => {
    const cardElement = elements.getElement(CardElement)
    if (cardElement) {
      stripe.createToken(cardElement).then((result) => {
        if (result.error) {
          props.setNewCardToken(undefined)
          setError(result.error.code)
        } else {
          props.setNewCardToken(result?.token?.id)
          setError('')
        }
      })
    }
  }

  return (
    <>
      <span>{`${t('payments:card')}`}</span>
      <BeautifulCardElement
        options={{ ...cardElementProps, hidePostalCode: true }}
        onChange={() => generateNewCardToken()}
      />
      {error !== '' && (
        <span className='validation-error'>
          {t(`payments:card_errors.${error}`)}
        </span>
      )}
    </>
  )
}

const BeautifulCardElement = styled(CardElement)`
  min-width: 350px;
  margin: 10px 0px 0px 0px;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  padding: 8px 6px 6px 6px;
`

export default NewCard
