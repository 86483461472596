import React from 'react'
import styled from 'styled-components'
import { Button as AntdButton } from 'antd'
import colors from '../../colors'
import Fonts from '../../fonts'

interface IProps {
  children: React.ReactNode
  onClick?: () => void
  loading?: boolean | { delay: number }
  href?: string
  disabled?: boolean
  className?: string
  styles?: any
}

const Button = (props: IProps) => {
  const { children } = props
  return <PrettyButton {...props}>{children}</PrettyButton>
}

const PrettyButton = styled(AntdButton)`
  width: -webkit-fill-available;
  width: -moz-available;
  height: 40px;
  background-color: ${colors.blue};
  border-radius: 8px;
  border: none;

  span {
    font-family: ${Fonts.openSans};
    font-size: 14px;
    color: ${colors.white};
  }

  &:hover {
    background-color: ${colors.darkBlue} !important;
  }

  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover,
  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:active {
    background: ${colors.greyDisable};
    color: ${colors.white};
    border: none;
    box-shadow: none;
  }

  &.inverse-color {
    background: ${colors.white};
    border: 1px solid ${colors.blue};

    span {
      color: ${colors.blue};
    }

    &:hover {
      border: 1px solid ${colors.darkBlue};
      background-color: ${colors.mediumBlueBackground} !important;
      span {
        color: ${colors.darkBlue};
      }
    }
  }
`

export default Button
