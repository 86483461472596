import { CONTACT_FRAGMENT } from '@/graphql/Fragments'
import { gql } from '@apollo/client'

export const UPSERT_SHAREHOLDERS = gql`
  mutation upsertShareholders($shareholders: [ShareholderInput!]!) {
    upsertShareholders(shareholders: $shareholders) {
      contact {
        ...ContactFragment
      }
    }
  }
  ${CONTACT_FRAGMENT}
`
