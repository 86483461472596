import { INotificationUnsubscription } from '@/interfaces/notificationUnsubscription'

export const serializeNotificationUnsubscription = (
  notificationUnsubscription: INotificationUnsubscription
) => {
  /* Careful we reverse the boolean state because we want to display the
   * subscribed notification but the database deal with unsubscriptions */
  const res: string[] = []
  if (notificationUnsubscription.marketing === false) {
    res.push('marketing')
  }
  if (notificationUnsubscription.support === false) {
    res.push('support')
  }
  if (notificationUnsubscription.newUserMail === false) {
    res.push('newUserMail')
  }
  return res
}

export const unserializeNotificationUnsubscription = (
  notificationUnsubscription: string[]
) => {
  /* Careful we reverse the boolean state because we want to display the
   * subscribed notification but the database deal with unsubscriptions */
  return {
    marketing: !notificationUnsubscription.includes('marketing'),
    support: !notificationUnsubscription.includes('support'),
    newUserMail: !notificationUnsubscription.includes('newUserMail')
  }
}
