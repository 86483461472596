import React, { createContext } from 'react'
import { useQuery } from '@apollo/client'
import { CommonLoader } from 'src/components/shared/Loader'
import * as Cookies from 'js-cookie'
import IUser from '@/interfaces/user'
import { CURRENT_USER_QUERY } from '@/graphql/queries/users'

interface IProps {
  children: React.ReactNode
}

export const CurrentUserContext = createContext({} as IUser)

const CurrentUserContextProvider = (props: IProps) => {
  const { data: userData, loading } = useQuery(CURRENT_USER_QUERY)
  const currentUser = userData?.currentUser

  if (loading) {
    return <CommonLoader size={48} />
  }

  // used by Bugsnag reporter
  Cookies.set('user_id', userData?.currentUser?.id)
  Cookies.set('user_email', userData?.currentUser?.email)

  return (
    <CurrentUserContext.Provider value={currentUser}>
      {props.children}
    </CurrentUserContext.Provider>
  )
}

export default CurrentUserContextProvider
