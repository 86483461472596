import React, { useContext } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { CancelationProcedureContext } from '@/contexts/CancelationProcedureContext'
import TerminationTag from '../../shared/TerminationTag'
import {
  extractSubtype,
  mustDisplayEndDate
} from '../helpers/cancelationProcedureHelper'

const TerminationSummaryBloc = () => {
  const { t } = useTranslation()

  const { dataCancelationProcedure }: any = useContext(
    CancelationProcedureContext
  )
  const cancelationProcedure =
    dataCancelationProcedure && dataCancelationProcedure.cancelationProcedure

  const type =
    cancelationProcedure &&
    extractSubtype(cancelationProcedure && cancelationProcedure.type)
  // kbis
  const tagtext = t(
    `cancelationProcedure:summary.terminationBloc.state.tags.texts.${cancelationProcedure.state}`
  )
  const tagcolor = t(
    `cancelationProcedure:summary.terminationBloc.state.tags.colors.${cancelationProcedure.state}`
  )

  return (
    <div className={'termination-bloc'}>
      <div className={'termination-bloc-head'}>
        <h4 className='termination-bloc-title'>
          {t(
            `cancelationProcedure:summary.terminationBloc.state.title.${type}`
          )}
        </h4>
        <TerminationTag text={tagtext} color={tagcolor} />
      </div>
      <div className={'termination-bloc-body'}>
        <ul>
          {mustDisplayEndDate(cancelationProcedure) && (
            <li>
              <p className='label'>
                {t(
                  'cancelationProcedure:summary.terminationBloc.state.labels.endOfSubscriptionDate'
                )}
              </p>
              <p className='value'>
                {cancelationProcedure.cancelationExpectedDate &&
                  moment(cancelationProcedure.cancelationExpectedDate).format(
                    'LL'
                  )}
              </p>
            </li>
          )}

          <li>
            <p className='label'>
              {t(
                `cancelationProcedure:summary.terminationBloc.state.labels.kind.${type}`
              )}
            </p>
            <p className='value'>
              {t(
                `cancelationProcedure:flow.form.fields.reason.options.${cancelationProcedure.reason}`
              )}
            </p>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default TerminationSummaryBloc
