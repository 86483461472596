import { Dispatch, SetStateAction } from 'react'
import { IUserMail } from '@/interfaces/userMail'
import { SelectedMailsProps } from '@/CustomerArea/userMails/UserMails/ListingMails'
import DateFilterAndMailsSelection from '@/CustomerArea/userMails/UserMails/ListingMails/Filters/dateFilterAndMailsSelection'
import MailsSorters from '@/CustomerArea/userMails/UserMails/ListingMails/Filters/mailsSorters'

interface FiltersComponentProps {
  onFilterChange: (prev) => void
  userMails: IUserMail[] | undefined
  selectedMails: SelectedMailsProps[]
  setSelectedMails: Dispatch<SetStateAction<SelectedMailsProps[]>>
  setPageLength: Dispatch<SetStateAction<number>>
  setCurrentPage: Dispatch<SetStateAction<number>>
}

const Filters = ({
  onFilterChange,
  userMails,
  selectedMails,
  setSelectedMails,
  setPageLength,
  setCurrentPage
}: FiltersComponentProps) => {
  return (
    <div className='flex flex-col gap-[12px] pb-[12px]'>
      <MailsSorters onFilterChange={onFilterChange} />

      <DateFilterAndMailsSelection
        setCurrentPage={setCurrentPage}
        onFilterChange={onFilterChange}
        userMails={userMails}
        selectedMails={selectedMails}
        setSelectedMails={setSelectedMails}
        setPageLength={setPageLength}
      />
    </div>
  )
}

export default Filters
