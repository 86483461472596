import { notification } from 'antd'

export const openValidationPopup = (title: string, description: string) => {
  openPopup(title, description, 5, 'success')
}

export const openErrorPopup = (title: string, description: string) => {
  openPopup(title, description, 0, 'error')
}

const openPopup = (
  title: string,
  description: string,
  duration: number,
  type: 'success' | 'error'
) => {
  notification[type]({
    message: title,
    description,
    duration
  })
}
