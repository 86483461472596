import { gql } from '@apollo/client'
import { KYC_LOGIC_RULE_FRAGMENT } from '@/graphql/Fragments'

export const KYC_LOGIC_RULES_QUERY = gql`
  {
    domiciliation {
      kycLogicRules {
        ...KycLogicRulesFragment
      }
    }
  }
  ${KYC_LOGIC_RULE_FRAGMENT}
`
