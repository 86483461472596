import { useContext } from 'react'
import styled from 'styled-components'
import { Colors } from '@/components/UIKit'
import Button from '@/components/UIKit/components/Button/buttonWithBorder'
import constants from '@/components/UIKit/constants'
import pencil from 'src/images/pencils/pencil-white.svg'
import { useTranslation } from 'react-i18next'
import CivilStatus from './civilStatus'
import { useState } from 'react'
import LeaderInformationDrawer from './leaderInformationDrawer'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'

const LeaderInformations = ({ contactName }) => {
  const { t } = useTranslation()
  const { domiciliation } = useContext(DomiciliationContext)
  const [open, setOpen] = useState(false)

  return (
    <LeaderInformationsWrapper>
      {open && (
        <LeaderInformationDrawer
          onClose={() => setOpen(false)}
          open={open}
          contactName={contactName}
        />
      )}
      <LeaderInformationsLeftPart>
        {t('common:informations')}
        <CivilStatus leaderContact={contactName} />
      </LeaderInformationsLeftPart>
      <LeaderInformationsRightPart>
        <Button
          bgColor={domiciliation.hostedAnswer ? 'white' : 'orange'}
          onClick={() => setOpen(true)}
        >
          {!domiciliation.hostedAnswer && (
            <ButtonIcon src={pencil} alt='pencil' />
          )}
          {domiciliation.hostedAnswer
            ? t('common:modify')
            : t('common:toComplete')}
        </Button>
      </LeaderInformationsRightPart>
    </LeaderInformationsWrapper>
  )
}

export const LeaderInformationsWrapper = styled.div`
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  border-radius: 16px;
  border: 1px solid ${Colors.greyBorder};
  box-shadow: 0px 4px 21px 0px rgba(25, 29, 32, 0.08);
  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    flex-direction: column;
    gap: 16px;
  }
`
export const LeaderInformationsLeftPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  color: ${Colors.black};
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
`
export const LeaderInformationsRightPart = styled.div``
export const ButtonIcon = styled.img`
  margin-right: 8px;
`
export default LeaderInformations
