import gql from 'graphql-tag'
import { SOCIETY_FRAGMENT } from './society'
import { PAYMENT_SOURCE_FRAGMENT } from './paymentSource'
import { COUNTRY_CONFIG_FRAGMENT } from './countryConfig'
import { PURCHASE_FRAGMENT } from './purchase'
import { PERSONAL_INFORMATION_FRAGMENT } from './personalInformation'

export const CONTACT_FRAGMENT = gql`
  fragment ContactFragment on Contact {
    id
    email
    phoneNumber
    vat
    stripeConnectedAccount
    hideDomideal
    domiciliationStatus
    society {
      ...SocietyFragment
    }
    personalInformation {
      ...PersonalInformationFragment
    }
    paymentSources {
      ...PaymentSourceFragment
    }
    countryConfig {
      ...CountryConfigFragment
    }
    purchases {
      ...PurchaseFragment
    }
    cgvAlreadyAccepted
  }
  ${SOCIETY_FRAGMENT}
  ${PERSONAL_INFORMATION_FRAGMENT}
  ${PAYMENT_SOURCE_FRAGMENT}
  ${COUNTRY_CONFIG_FRAGMENT}
  ${PURCHASE_FRAGMENT}
`
