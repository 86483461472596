import gql from 'graphql-tag'
import { ADDRESS_DETAIL_FRAGMENT } from './addressDetail'
import { CONTACT_DETAIL_FRAGMENT } from './contactDetail'

export const HOSTER_FRAGMENT = gql`
  fragment HosterFragment on Hoster {
    addressDetail {
      ...AddressDetailFragment
    }
    contactDetail {
      ...ContactDetailFragment
    }
  }
  ${ADDRESS_DETAIL_FRAGMENT}
  ${CONTACT_DETAIL_FRAGMENT}
`
