import gql from 'graphql-tag'

export const SHAREHOLDER_FRAGMENT = gql`
  fragment ShareholderFragment on Shareholder {
    id
    legalType
    contactDetail {
      firstname
      id
      lastname
      nationality
    }
    name
    nationality
    capitalHeld
  }
`
