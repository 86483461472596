import React, { useContext } from 'react'
import { IUserMail } from '@/interfaces/userMail'
import { UserMailQueryType } from '../Enum'
import { USER_MAIL_QUERY_ESTIMATION_QUERY } from '@/graphql/queries/userMail'
import { useQuery } from '@apollo/client'
import Content from './Content'
import { ContactContext } from 'src/contexts/contactContext'
import useAlert from 'src/hooks/useAlert'

interface IProps {
  userMail: IUserMail
  type: UserMailQueryType
  onCloseModal: () => void
}

const New = (props: IProps) => {
  const { contact }: any = useContext(ContactContext)
  const { setAlert } = useAlert()
  const { data, loading, error } = useQuery(USER_MAIL_QUERY_ESTIMATION_QUERY, {
    variables: {
      userMailId: props.userMail.id,
      queryType: props.type
    },
    fetchPolicy: 'cache-and-network'
  })

  if (error || loading) {
    return null
  }

  return (
    <Content
      {...props}
      contact={contact}
      userMailQueryEstimation={data.userMailQueryEstimation}
      setAlert={setAlert}
    />
  )
}

export default New
