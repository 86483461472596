import {
  HOTLINE_PHONE_NUMBER_QUERY,
  HOTLINE_PHONE_NUMBERS_SUBSCRIPTION
} from '@/graphql/queries/hotline'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { FullScreenLayout } from '../shared/Helpers'
import { CommonLoader } from '@/components/shared/Loader'
import { isDemo } from '@/CustomerArea/demo/Utils'
import TopPart from './hotlineTopPart'
import HotlineForm from './Form'

const ServicesHotlineForm = () => {
  const { t } = useTranslation()
  const { data, loading, error, subscribeToMore } = useQuery(
    HOTLINE_PHONE_NUMBER_QUERY
  )

  if (!isDemo()) {
    subscribeToMore({
      document: HOTLINE_PHONE_NUMBERS_SUBSCRIPTION,
      updateQuery: (
        previousResult,
        { subscriptionData }: { subscriptionData: any }
      ) => {
        if (!subscriptionData.data.hotlinePhoneNumberRefetch) {
          return previousResult
        } else {
          return {
            hotlinePhoneNumbers: subscriptionData.data.hotlinePhoneNumberRefetch
          }
        }
      }
    })
  }

  if (error) {
    return <span>{`${t('common:error')}! ${error.message}`}</span>
  } else if (loading) {
    return (
      <div className='fullscreen-container-loader'>
        <CommonLoader />
      </div>
    )
  }

  return (
    <FullScreenLayout>
      <div className='full-padding hotline-form'>
        <TopPart />
        <HotlineForm hotlinePhoneNumbers={data.hotlinePhoneNumbers} />
      </div>
    </FullScreenLayout>
  )
}

export default ServicesHotlineForm
