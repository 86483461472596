import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface IPushProps {
  icon: HTMLImageElement
  iconAlt: string
  mainText: string
  subText: string
  bgColor: string
  str: string
  setState: any
}

const Push: React.FC<IPushProps> = ({
  icon,
  iconAlt,
  mainText,
  subText,
  bgColor,
  str,
  setState
}) => {
  const { t } = useTranslation()
  const ignorePush = () => {
    localStorage.setItem(str, 'true')
    setState(false)
  }
  return (
    <PushWrapper bgColor={bgColor}>
      <PushContent>
        <PushIcon src={icon} alt={iconAlt} />
        <PushText>
          <MainPushText>{mainText}</MainPushText>
          <SubPushText>{subText}</SubPushText>
        </PushText>
      </PushContent>
      <PushIgnoreButton onClick={ignorePush}>
        {t('userMails:pushes.ignore')}
      </PushIgnoreButton>
    </PushWrapper>
  )
}

export const PushWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: ${(props) => props.bgColor};
  border-radius: 8px;
  margin-bottom: 8px;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
export const PushContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`
export const PushIcon = styled.img`
  @media (max-width: 900px) {
    order: 2;
  }
`
export const PushText = styled.div`
  flex: 1;
  color: #171a1c;
`
export const MainPushText = styled.div`
  font-size: 14px;
  font-weight: 600;
  padding-right: 24px;
`
export const SubPushText = styled.div`
  font-size: 12px;
  padding-right: 24px;
`
export const PushIgnoreButton = styled.button`
  text-decoration: underline;
  border: none;
  color: #171a1c;
  font-weight: 600;
  font-size: 14px;
  background-color: transparent;
  padding: 0;
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`

export default Push
