import React from 'react'
import { IItem } from './interfaces'
import { StepWrapper, StepTitle, Barre } from './styles'

interface IProps {
  item: IItem
  done: boolean
}

const ProgressBar = (props: IProps) => {
  const { item, done } = props

  return (
    <StepWrapper className='uikit-progress-bar-step'>
      <StepTitle done={done}>{item.title}</StepTitle>
      <Barre done={done} />
    </StepWrapper>
  )
}

export default ProgressBar
