import gql from 'graphql-tag'

export const POLITICALLY_EXPOSED_FRAGMENT = gql`
  fragment PoliticallyExposedFragment on PoliticallyExposed {
    id
    personallyExposed
    functionPerformed
    cessationDate
    closeRelationExposed
    closeRelationFunctionPerformed
    closeRelationType
  }
`
