import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip, Spin } from 'antd'
import styled from 'styled-components'
import InviteRefereeModal from './inviteRefereeModal'
import { CommonLoader } from '@/components/shared/Loader'

interface IProps {
  referralInformations?: any
  refetch: any
}

const ShareCode = (props: IProps) => {
  const { t } = useTranslation()
  const { referralInformations, refetch } = props

  const [inviteRefereeModalIsOpen, setInviteRefereeModalIsOpen] =
    useState<boolean>(false)

  const onCloseModal = () => {
    setInviteRefereeModalIsOpen(false)
  }

  const onInvitationSent = () => () => {
    refetch()
    setInviteRefereeModalIsOpen(false)
  }

  const openModal = () => {
    setInviteRefereeModalIsOpen(true)
  }

  const copy = (txt) => () => {
    const myTemporaryInputElement = document.createElement('input')
    myTemporaryInputElement.type = 'text'
    myTemporaryInputElement.value = txt
    document.body.appendChild(myTemporaryInputElement)
    myTemporaryInputElement.select()
    document.execCommand('Copy')
    document.body.removeChild(myTemporaryInputElement)
  }

  const getDiscount = () => {
    const price: number = referralInformations?.referrerCurrentReduction * 0.01
    if (!referralInformations) return <Spin />
    else {
      return t('referrer:share.shareInfo.discount', {
        price: price
      })
    }
  }

  return (
    <>
      <div className='referrer_share'>
        <h3>{t('referrer:share.title')}</h3>
        <div className='referrer_summary'>
          <div className='bloc'>
            <div className='bloc-title'>
              {t('referrer:share.shareInfo.yourInfo')}
            </div>
            <div className='bloc-content'>{getDiscount()}</div>
          </div>
          <div className='bloc'>
            <div className='bloc-title'>
              {t('referrer:share.shareInfo.relativeInfo')}
            </div>
            <div className='bloc-content'>{getDiscount()}</div>
          </div>
        </div>
        <div className='referrer_code'>
          <div className='referrer_code_txt'>
            {t('referrer:share.referrer_code')}
          </div>
          {referralInformations ? (
            <Tooltip
              trigger='click'
              placement='topLeft'
              title={t('referrer:share.pasted')}
            >
              <div
                title={t('referrer:share.paste')}
                className='referrer_code_digit inline'
                onClick={copy(referralInformations.referrerCode)}
              >
                {referralInformations.referrerCode}
              </div>
              <div
                title={t('referrer:share.paste')}
                className='referrer_code_icon inline'
                onClick={copy(referralInformations.referrerCode)}
              />
            </Tooltip>
          ) : (
            <CommonLoader />
          )}
        </div>

        <PrettyButton
          className='btn primary-btn btn-service section-button primary-button'
          onClick={openModal}
        >
          {t('referrer:share.button.inner')}
        </PrettyButton>
      </div>
      <InviteRefereeModal
        onCloseModal={onCloseModal}
        onInvitationSent={onInvitationSent()}
        isOpen={inviteRefereeModalIsOpen}
      />
    </>
  )
}

export default ShareCode

const PrettyButton = styled.button`
  margin-top: 15px;
  width: 100%;
`
