import { gql } from '@apollo/client'
import { CONTACT_OF_SHARING_FRAGMENT } from '@/graphql/Fragments'

export const CONTACT_OF_SHARING_QUERY = gql`
  {
    contactOfSharings {
      ...ContactOfSharingFragment
    }
  }

  ${CONTACT_OF_SHARING_FRAGMENT}
`
