// Inspired by https://github.com/jaredpalmer/formik-effect/issues/4

import * as React from 'react'

interface IProps {
  onChange: (from: any, to: any) => void
  values: any
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
class FormikEffect<Values> extends React.Component<IProps> {
  /* eslint-disable react/no-deprecated */
  public componentWillReceiveProps(nextProps: IProps) {
    const values = this.props.values
    const nextValues = nextProps.values
    if (values !== nextValues) {
      this.props.onChange(values, nextValues)
    }
  }
  /* eslint-enable react/no-deprecated */

  public render() {
    return null
  }
}

export default FormikEffect
