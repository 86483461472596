import { ReactNode } from 'react'
import { ConfigProvider } from 'antd'
/* eslint-disable camelcase */
import fr_FR from 'antd/lib/locale/fr_FR'
import { colors } from '@sedomicilier/material-sd/src/TailwindConfig/colors'

interface AntdConfigProps {
  children: ReactNode
}

const AntdConfig: React.FC<AntdConfigProps> = ({ children }) => {
  const customTheme = {
    token: {
      colorPrimary: colors['primary-500']
    },
    components: {
      Input: { controlHeight: 40 }
    }
  }

  return (
    <ConfigProvider locale={fr_FR} theme={customTheme}>
      {children}
    </ConfigProvider>
  )
}

export default AntdConfig
