function ArrowLeftBlack() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <g>
        <mask
          id='mask0_1621_12113'
          style={{ maskType: 'alpha' }}
          width='24'
          height='24'
          x='0'
          y='0'
          maskUnits='userSpaceOnUse'
        >
          <path
            fill='#D9D9D9'
            d='M24 24H48V48H24z'
            transform='rotate(180 24 24)'
          ></path>
        </mask>
        <g mask='url(#mask0_1621_12113)'>
          <path
            fill='#1C1B1F'
            d='M7.825 11H20v2H7.825l5.6 5.6L12 20l-8-8 8-8 1.425 1.4-5.6 5.6z'
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default ArrowLeftBlack
