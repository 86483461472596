import useAlert from 'src/hooks/useAlert'
import styled from 'styled-components'
import WhiteCheck from 'src/images/check/white-check-circle.svg'
import WhiteCross from 'src/images/cross/white-close-cross.svg'
import WhiteErrorIcon from 'src/images/error/white-exclamation-point.svg'
import '../../../../styles/_var.sass'
import { notification } from 'antd'

const AlertPopup = () => {
  const {
    text,
    description,
    type,
    size,
    setType,
    setText,
    setDescription,
    setCloseIconClicked,
    animationShow
  } = useAlert()

  const getIconByType = () => {
    let icon
    if (type === 'success') {
      icon = WhiteCheck
    } else {
      icon = WhiteErrorIcon
    }
    return icon
  }

  const alertBackgroundColor = () => {
    let bgColor = ''
    if (type === 'success') {
      bgColor = '#24B26E'
    } else {
      bgColor = '#FF5D59'
    }
    return bgColor
  }

  const onClose = () => {
    setType('')
    setText('')
    setDescription('')
    setCloseIconClicked(true)
  }

  const onNotificationClose = () => {
    setType('')
    setText('')
    setDescription('')
  }

  const notificationClassName = () => {
    let className = ''
    if (type === 'success') {
      if (description) {
        className = 'successNotification'
      } else {
        className = 'successNotificationWthDesc'
      }
    } else {
      if (description) {
        className = 'errorNotification'
      } else {
        className = 'errorNotificationWthDesc'
      }
    }
    return className
  }

  if (text && type) {
    if (size === 'small') {
      return (
        <SmallAlertPopup>
          {notification[type]({
            message: text,
            description: description,
            duration: 5,
            className: notificationClassName(),
            onClose: onNotificationClose
          })}
        </SmallAlertPopup>
      )
    } else {
      return (
        <AlertText
          className={`large-alert-popup ${animationShow}`}
          type={type}
          bgColor={alertBackgroundColor()}
        >
          <CenterContent>
            <CheckImg src={getIconByType()} alt={`${getIconByType()} mark`} />
            <div>{text}</div>
          </CenterContent>
          <CloseImg src={WhiteCross} onClick={onClose} />
        </AlertText>
      )
    }
  } else {
    return <></>
  }
}

export const SmallAlertPopup = styled.div`
  .ant-notification-notice-message {
    margin-bottom: 100px !important;
  }
`

export const AlertText = styled.div`
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.bgColor && `${props.bgColor}`};
  width: 100%;
  color: #fff;
  font-size: 16px;
`
export const CenterContent = styled.div`
  margin: 0 auto;
  display: flex;
`
export const CheckImg = styled.img`
  margin-right: 8px;
`
export const CloseImg = styled.img`
  margin: 0 24px 0 0;
  &:hover {
    cursor: pointer;
  }
`

export default AlertPopup
