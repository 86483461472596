import { PAYMENT_SOURCE_FRAGMENT } from '@/graphql/Fragments'
import { gql } from '@apollo/client'

export const CURRENT_PAYMENT_SOURCE = gql`
  {
    currentPaymentSource {
      ...PaymentSourceFragment
    }
  }
  ${PAYMENT_SOURCE_FRAGMENT}
`
