import styled from 'styled-components'
import { Colors } from 'src/components/UIKit'

export const Wrapper = styled.div``

export const StepsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 10px;

  &:first-child {
    margin-left: 0;
  }
`

export const StepTitle = styled.h5`
  font-size: 14px;
  color: ${(props) => (props.done ? Colors.blue : Colors.greyDisable)};
`

export const Barre = styled.div`
  width: 114px;
  height: 4px;
  left: 0px;
  top: 32px;

  background: ${(props) => (props.done ? Colors.blue : Colors.greyDisable)};
  border-radius: 32px;
`

export const Note = styled.div`
  height: 18px;
  font-size: 14px;
  margin-top: 24px;
  color: ${Colors.greyDisable};
`
