import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
  useStore
} from 'react-redux'
import type { RootState, AppDispatch } from '@/store'

const useAppDispatch: () => AppDispatch = useDispatch
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
  useStore
}
