import React, { useState, useContext } from 'react'
import IInvoice from '@/interfaces/invoice'
import { IFailedPayment } from '@/interfaces/failedPayment'
import { useTranslation } from 'react-i18next'
import { IOrder, InvoiceOrderColumn, InvoiceOrderDirection } from './Interfaces'
import ImageDoubleArrowIcon from '@/images/double-arrow-icon.svg'
import RetryPaymentModal from './RetryPaymentModal'
import { IPaymentSource } from '@/interfaces/paymentSource'
import { ContactContext } from 'src/contexts/contactContext'
import { AbilityContext } from 'src/contexts/abilityContext'
import Invoice from './components/Invoice'
import UnpaidInvoice from './components/UnpaidInvoice'
import useAlert from 'src/hooks/useAlert'

interface IProps {
  onChangeOrder: (order: IOrder) => void
  unpaidInvoices: IFailedPayment[]
  invoices: IInvoice[]
  order?: IOrder
  refresh: () => void
}

const List = (props: IProps) => {
  const { t } = useTranslation()
  const { setAlert } = useAlert()
  const { contact }: any = useContext(ContactContext)
  const paymentSources = contact.paymentSources.filter(
    (paymentSource: IPaymentSource) => paymentSource.sourceType === 'card'
  )
  const ability = useContext(AbilityContext)

  const [handleUnpaidInvoiceToRetry, setHandleUnpaidInvoiceToRetry] = useState<
    IFailedPayment | undefined
  >(undefined)

  const handleChangeOrder = (column: InvoiceOrderColumn) => {
    props.onChangeOrder({
      column,
      direction:
        props.order === undefined ||
        props.order.direction === InvoiceOrderDirection.desc
          ? InvoiceOrderDirection.asc
          : InvoiceOrderDirection.desc
    })
  }

  const openModal = (unpaidInvoiceToRetry: IFailedPayment) => {
    if (
      unpaidInvoiceToRetry.state !== 'processing' &&
      ability.can('customer_manage', 'Invoice')
    ) {
      setHandleUnpaidInvoiceToRetry(unpaidInvoiceToRetry)
    }
  }

  const onCloseModal = () => {
    setHandleUnpaidInvoiceToRetry(undefined)
  }

  const handleRefresh = () => {
    props.refresh()
  }

  const onRetrySuccess = () => {
    setHandleUnpaidInvoiceToRetry(undefined)
    setAlert({
      type: 'success',
      text:
        props.unpaidInvoices.length > 0
          ? t('invoices:retry.success.withRemaining')
          : t('invoices:retry.success.done'),
      size: 'small'
    })
    setTimeout(() => handleRefresh(), 2000)
  }

  const alertFailedPayment = () => {
    setAlert({
      type: 'error',
      text: t('invoices:retry.failed.message'),
      size: 'small'
    })
  }

  return (
    <>
      {handleUnpaidInvoiceToRetry && (
        <RetryPaymentModal
          paymentSources={paymentSources}
          unpaidInvoice={handleUnpaidInvoiceToRetry}
          onCloseModal={onCloseModal}
          onSuccess={onRetrySuccess}
          alertFailedPayment={alertFailedPayment}
        />
      )}
      <table className='table-auto w-full'>
        <thead>
          <tr>
            <th>{t('invoices:columns.id')}</th>
            <th>{t('invoices:columns.name')}</th>
            <th
              onClick={() => handleChangeOrder(InvoiceOrderColumn.issue_date)}
            >
              {t('invoices:columns.date')}
              <img src={ImageDoubleArrowIcon} className='arrow-button-left' />
            </th>
            <th>{t('invoices:columns.amount')}</th>
          </tr>
        </thead>
        <tbody>
          {props.unpaidInvoices.map((unpaidInvoice: IFailedPayment) => {
            if (unpaidInvoice.state !== 'paid') {
              return (
                <UnpaidInvoice
                  key={unpaidInvoice.id}
                  unpaidInvoice={unpaidInvoice}
                  retry={() => openModal(unpaidInvoice)}
                />
              )
            } else {
              return null
            }
          })}
          {props.invoices.map((invoice: IInvoice) => {
            return <Invoice key={invoice.id} {...invoice} />
          })}
        </tbody>
      </table>
    </>
  )
}

export default List
