import React, { useContext, useState } from 'react'
import Actions from '@/CustomerArea/userMails/Actions'
import { UserMailQueryType } from '@/CustomerArea/userMails/Enum'
import UserMailShare from '@/CustomerArea/userMails/user_mail_share/index'
import { UserMailContext } from '../../../../contexts/UserMailContext'
import UserMailQueryNew from '../user_mail_queries/New'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import useAlert from 'src/hooks/useAlert'
import { IUserMail } from '@/interfaces/userMail'

interface IStateType {
  showModalType: any
  showModalShare: any
}

const UserMailPreview = () => {
  const { t } = useTranslation()
  const { selectedMail }: any = useContext(UserMailContext)
  const { setAlert } = useAlert()
  const [state, setState] = useState<IStateType>({
    showModalType: undefined,
    showModalShare: false
  })

  const userMailWeightFormatted = (userMail: IUserMail) => {
    if (userMail.weight > 999) {
      return `${(userMail.weight / 1000).toLocaleString('fr-FR')} ${t('userMails:global.kgs')}`
    } else {
      return `${userMail.weight.toLocaleString('fr-FR')} ${t('userMails:global.grams')}`
    }
  }

  const onNewUserMailQuery = (type: UserMailQueryType) => {
    setState({ ...state, showModalType: type })
  }

  const onCloseModal = () => {
    setState({ showModalType: undefined, showModalShare: false })
  }

  const handleAction = (type: 'showModalShare') => {
    setState({ ...state, [type]: true })
  }
  const downloadScan = () => {
    window.open(selectedMail.scanFileUrl, '_blank')?.focus()
  }

  return (
    <React.Fragment>
      {state.showModalType && (
        <UserMailQueryNew
          userMail={selectedMail}
          onCloseModal={onCloseModal}
          type={state.showModalType}
        />
      )}
      {state.showModalShare && (
        <UserMailShare
          userMail={selectedMail}
          onCloseModal={onCloseModal}
          setAlert={setAlert}
        />
      )}

      <MailPreviewWrapper>
        <MailPreviewTopPart>
          <MailID>N°{selectedMail.id}</MailID>
          <Actions
            userMail={selectedMail}
            handleAction={handleAction}
            onNewUserMailQuery={onNewUserMailQuery}
          />
        </MailPreviewTopPart>
        {selectedMail.sendName && (
          <SenderName>
            {t('userMails:global.sender')} {selectedMail.sendName}
          </SenderName>
        )}
        {selectedMail.weight && (
          <SenderName>
            {t('userMails:global.weight')}{' '}
            {userMailWeightFormatted(selectedMail)}
          </SenderName>
        )}
        <br />
        {selectedMail?.scanFileThumbUrl && (
          <MailPreviewBottomPart onClick={downloadScan}>
            <MailPreview
              src={selectedMail.scanFileThumbUrl}
              alt='prévisualisation du courrier'
            />
          </MailPreviewBottomPart>
        )}
      </MailPreviewWrapper>
    </React.Fragment>
  )
}

export const MailPreviewWrapper = styled.div``
export const MailPreviewTopPart = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  .plus {
    top: unset;
  }
`
export const MailPreviewBottomPart = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 28px 0;
  background-color: #f5f7fb;
  border-radius: 8px;
  margin-bottom: 25px;
  position: relative;
  &:hover {
    background-color: #e3e6e8;
    opacity: 50%;
    cursor: pointer;
  }
`
export const MailPreview = styled.img`
  max-height: 121px;
`
export const MailID = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #171a1c;
`
export const SenderName = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-family: 'Inter';
  margin-bottom: 16px;
`

export default UserMailPreview
