import { ROLE_FRAGMENT } from '@/graphql/Fragments/role'
import { gql } from '@apollo/client'

export const ROLES_QUERY = gql`
  {
    roles {
      ...RoleFragment
    }
  }

  ${ROLE_FRAGMENT}
`
