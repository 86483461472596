// Classic colors
const black = '#171A1C'
const grey = '#6C767F'
const blue = '#1576E1'
const darkBlue = '#0454AD'
const green = '#1DBE6D'
const lightGreen = '#E7F5DC'
const yellow = '#FFCD6B'
const lightYellow = '#FFF1D7'
const pink = '#FB82A9'
const lightPink = '#FFE4ED'
const lightBlueBackground = '#F5F7FB'
const mediumBlueBackground = '#E7F2FF'
const darkBlueBackground = '#000F46'
const white = '#FFFFFF'
const orange = '#F26600'
const lightOrange = '#ffe1cc'
const red = '#F33F3F'
const redHover = '#f62626'

// Hover colors
const blackHover = '#2F3437'
const orangeHover = '#D05800'
const whiteHover = '#E7F2FF'

// Border colors
const greyBorder = '#e3e6e8'

// Fifty Shades of Grey
const greyHeading = '#282C2E'
const greyBody = '#75808A'
const greyBox = '#D3DBE1'
const greyDisable = '#B9C4CD'
const greyDivider = '#ECEFF0'

// Feedback colors
const success = '#008140'
const successHover = '#00582C'
const successBackground = '#E2F2EA'
const warning = '#E06611'
const warningHover = '#BD5003'
const warningBackground = '#FBF0E8'
const error = '#CD100C'
const errorHover = '#A30300'
const errorBackground = '#F2E6E6'

export default {
  black,
  blue,
  darkBlue,
  green,
  lightGreen,
  yellow,
  lightYellow,
  pink,
  red,
  redHover,
  lightPink,
  lightBlueBackground,
  mediumBlueBackground,
  darkBlueBackground,
  white,
  orange,
  lightOrange,
  greyBorder,
  blackHover,
  orangeHover,
  whiteHover,
  greyHeading,
  greyBody,
  greyBox,
  greyDisable,
  greyDivider,
  success,
  successHover,
  successBackground,
  warning,
  warningHover,
  warningBackground,
  error,
  errorHover,
  errorBackground,
  grey
}
