import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'antd'
import FormUserName from './FormUserName'
import IUser from '@/interfaces/user'
import useAlert from '@/hooks/useAlert'

interface IProps {
  onCloseModal: () => void
  user: IUser
}

const ChangeUserNameModal = (props: IProps) => {
  const { t } = useTranslation()
  const { setAlert } = useAlert()

  return (
    <Modal
      data-id='update-user-name'
      open={true}
      onCancel={props.onCloseModal}
      footer={null}
      title={t('security:update_of_the_name')}
    >
      <FormUserName {...props} setAlert={setAlert} />
    </Modal>
  )
}

export default ChangeUserNameModal
