import gql from 'graphql-tag'

export const DOCUMENT_HOST_FRAGMENT = gql`
  fragment DocumentHostFragment on DocumentHost {
    accountantName
    email
    city
    country
    id
    location
    noAccountant
    postalCode
    streetAddress
    fullAddress
  }
`
