import React from 'react'
import styled from 'styled-components'
import ForwardingPackIcon from 'src/images/icon-forwarding-pack.svg'
import DefaultLegalFormalitiesIcon from 'src/images/products/default-legal-formalities.svg'
import ChangementDenominationSocialIcon from 'src/images/products/changement-dénomination-social.svg'
import AddDirigeantOuNomCommercialIcon from 'src/images/products/add-dirigeant-ou-nom-commercial.svg'
import TransformationIcon from 'src/images/products/transformation.svg'
import EnsembleIcon from 'src/images/products/ensemble.svg'
import ModifierDirigeantIcon from 'src/images/products/modification-dirigeant.svg'
import ModifierFormeJuridiqueIcon from 'src/images/products/modifier-forme-juridique.svg'
import MiseEnSommeilIcon from 'src/images/products/mise-en-sommeil.svg'
import MentionLegalsIcon from 'src/images/products/mention-legals.svg'
import CgvIcon from 'src/images/products/cgv.svg'
import PacteIcon from 'src/images/products/pacte.svg'
import RsacIcon from 'src/images/products/rsac.svg'

interface IProps {
  identificationToken: string
}

const ProductIcon = (props: IProps) => {
  const { identificationToken } = props

  switch (identificationToken) {
    case 'modifier-objet-social':
    case 'augmenter-capital-social':
    case 'cession-action-ou-part-social':
    case 'ouverture-etablissement-secondaire':
      return <Icon src={DefaultLegalFormalitiesIcon} />
    case 'modifier-denomination-social':
      return <Icon src={ChangementDenominationSocialIcon} />
    case 'ajouter-ou-modifier-un-dirigeant':
    case 'ajouter-ou-modifier-nom-commercial':
    case 'ajouter-ou-modifier-nom-domaine':
    case 'ajouter-ou-modifier-nom-enseigne':
      return <Icon src={AddDirigeantOuNomCommercialIcon} />
    case 'transformation-sarl-ou-eurl-vers-sas-ou-sasu':
      return <Icon src={TransformationIcon} />
    case 'changement-adresse-personnelle':
    case 'declaration-beneficiaires-effectifs':
    case 'modifier-associes-sarl-sas-sasu-eurl':
    case 'modifier-associes-sas-vers-sasu-ou-eurl-vers-sarl':
    case 'modifier-associes-sci':
    case 'modifier-date-cloture':
      return <Icon src={ModifierDirigeantIcon} />
    case 'succursale':
      return <Icon src={EnsembleIcon} />
    case 'modifier-sarl-ou-eurl-vers-sas-ou-sasu':
      return <Icon src={ModifierFormeJuridiqueIcon} />
    case 'mise-en-sommeil':
    case 'reactivation-mise-en-sommeil':
    case 'cessation-activite':
      return <Icon src={MiseEnSommeilIcon} />
    case 'mentions-legales':
      return <Icon src={MentionLegalsIcon} />
    case 'cgv-par-avocat':
    case 'cgu-par-avocat':
      return <Icon src={CgvIcon} />
    case 'pacte-associes':
      return <Icon src={PacteIcon} />
    case 'inscription-rsac':
      return <Icon src={RsacIcon} />
    case 'forwarding-1-pack':
    case 'forwarding-5-pack':
    case 'forwarding-10-pack':
      return <Icon src={ForwardingPackIcon} />
    default:
      return <Icon src={DefaultLegalFormalitiesIcon} />
  }
}

const Icon = styled.img`
  width: 48px;
  height: 48px;
  margin: 0 25px 0 0;
  display: inline-block;
`

export default ProductIcon
