import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Routes, Route, useNavigate } from 'react-router-dom'
import { Alert } from 'antd'
import Routing from '@/CustomerArea/Routing'
import TerminationSubscriptionReason from './reason'
import TerminationSubscriptionSummary from './summary'
import TerminationWithdrawConfirm from './withdraw'
import TerminationSubscriptionConfirmation from './confirmation'
import EndingDate from './endingDate'
import { CANCELATION_PROCEDURE_CREATE_MUTATION } from '@/graphql/mutations/cancelationProcedure'

const TerminationSubscription = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [values, setValues] = useState<any>()
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [loading, setLoading] = useState<boolean>(false)

  const [cancelationProcedureCreate] = useMutation(
    CANCELATION_PROCEDURE_CREATE_MUTATION
  )

  const createProcedure = (additionnalValues: any) => {
    setErrorMessage(undefined)
    setLoading(true)

    cancelationProcedureCreate({
      variables: { ...values, ...additionnalValues }
    })
      .then((res: any) => {
        if (res.data.error) {
          throw res.data.error.message
        }
        setValues(undefined)
        navigate(Routing.SUBSCRIPTION_TERMINATION_SUMMARY)
      })
      .catch((err) => {
        if (typeof err === 'string') {
          setErrorMessage(err)
        } else {
          setErrorMessage(t('cancelationProcedure:errors.default'))
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      {!!errorMessage && <Alert message={errorMessage} type='error' />}
      <Routes>
        <Route
          path='/'
          element={
            <TerminationSubscriptionReason
              setValues={setValues}
              loading={loading}
            />
          }
        />
        <Route
          path='date-de-fin'
          element={
            <EndingDate
              loading={loading}
              setValues={setValues}
              values={values}
            />
          }
        />
        <Route
          path='confirmation'
          element={
            <TerminationSubscriptionConfirmation
              loading={loading}
              createProcedure={createProcedure}
              values={values}
            />
          }
        />
        <Route path='summary' element={<TerminationSubscriptionSummary />} />
        <Route
          path='withdraw'
          element={
            <TerminationWithdrawConfirm
              loading={loading}
              createProcedure={createProcedure}
            />
          }
        />
      </Routes>
    </>
  )
}

export default TerminationSubscription
