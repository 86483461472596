import styled from 'styled-components'
import { Colors, Constants } from 'src/components/UIKit'

export const Title = styled.h4`
  && {
    margin-left: 45px;
  }
`

export const Tile = styled.div`
  display: flex;
  justify-content: center;

  width: 360px;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid ${Colors.greyBox};
  border-radius: 4px;

  padding: 16px;
  margin: 0 0 40px 0;

  cursor: pointer;

  & > div {
    display: flex;
  }

  img {
    margin: 0 16px 0 0;
    width: 24px;
    height: 24px;
  }
`

export const LinkWrapper = styled.div`
  display: flex;
  gap: 16px;
  padding: 0 45px;

  @media (max-width: ${Constants.breakpoints['mobile-breakpoint-i']}) {
    flex-direction: column;
    align-items: center;
  }
`
