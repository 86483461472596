import { gql } from '@apollo/client'
import { DOMICILIATION_FRAGMENT } from '@/graphql/Fragments'

export const DOMICILIATION_FORWARDING_ADDRESS_UPDATE_MUTATION = gql`
  mutation DomiciliationForwardingAddressUpdate(
    $mailForwardingTargetName: String
    $streetAddress: String!
    $postalCode: String
    $city: String!
    $country: String!
    $complement: String
  ) {
    domiciliationForwardingAddressUpdate(
      mailForwardingTargetName: $mailForwardingTargetName
      streetAddress: $streetAddress
      postalCode: $postalCode
      city: $city
      country: $country
      complement: $complement
    ) {
      domiciliation {
        ...DomiciliationFragment
      }
    }
  }
  ${DOMICILIATION_FRAGMENT}
`
