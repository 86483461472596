import styled from 'styled-components'
import colors from '@/components/UIKit/colors'
import Button from '@/components/UIKit/components/Button/buttonWithBorder'
import constants from '@/components/UIKit/constants'
import downloadIcon from 'src/images/download/download-white.svg'
import { useTranslation } from 'react-i18next'
import DocumentState from './stepState/documentState'
import Routing from '@/CustomerArea/Routing'
import { useNavigate } from 'react-router'
import identityProofImage from 'src/images/legal-documents/kyc/identity_proof.svg'
import addressProofImage from 'src/images/legal-documents/kyc/address_proof.svg'
import residencePermit from 'src/images/legal-documents/kyc/residence-permit.svg'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import BeneficialOwnerStatus from './BeneficialOwnerInfo/BeneficialOwnerStatus'
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react'
import { ContactContext } from '@/contexts/contactContext'
import { DocumentsType } from '@/CustomerArea/Kyc/Identity'

interface DocumentRowProps {
  document: DocumentsType
  setDrawerCategory: Dispatch<SetStateAction<string | null>>
  setDrawerAllowDocumentTypes: (string) => void
  targetType: string
  shareholder: boolean
  targetId: string
}

const kycImages = {
  identity_proof: identityProofImage,
  residence_permit: residencePermit,
  address_proof: addressProofImage,
  host_attestation: addressProofImage,
  kbis: addressProofImage,
  certificate: addressProofImage,
  initial_certificate: addressProofImage,
  statuses: addressProofImage,
  initial_statuses: addressProofImage,
  postal_procuration: addressProofImage
}

const DocumentRow = ({
  targetType,
  document,
  setDrawerAllowDocumentTypes,
  setDrawerCategory,
  shareholder,
  targetId
}: DocumentRowProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { domiciliation } = useContext(DomiciliationContext)
  const { contact } = useContext(ContactContext)

  const [showCompleteButton, setShowCompleteButton] = useState(true)

  const openDrawer = () => {
    if (document.idNowSDK) {
      return navigate(
        `${Routing.VERIFICATION}/${document.category}/${targetId}`
      )
    } else {
      return document.onClick && document.onClick()
    }
  }

  const handleOnClick = () => {
    setDrawerAllowDocumentTypes(document.allowDocumentTypes)
    setDrawerCategory(document.category)
    openDrawer()
  }

  const tradStepCertificate = () => {
    if (domiciliation === undefined || document.category !== 'certificate') {
      return t(`procedure:rowTitle.${document.category}`)
    }
    if (contact.society.legalStatus.isMicro) {
      return t('procedure:rowTitle.certificate-micro')
    } else if (contact.society.legalStatus.name === 'Association') {
      return t('procedure:rowTitle.certificate-association')
    } else {
      return t('procedure:rowTitle.certificate')
    }
  }

  useEffect(() => {
    if (
      ['validated', 'pending', 'pending_upload_id_now', 'formality'].includes(
        document.state
      )
    ) {
      setShowCompleteButton(false)
    }
  }, [document.state])

  return (
    <>
      {shareholder ? (
        <BeneficialOwnerInfoWrapper>
          <BeneficialOwnerInfoLeft>
            <BeneficialOwnerStatus
              targetType={targetType}
              status={document.state}
            />
          </BeneficialOwnerInfoLeft>
          <BeneficialOwnerRight>
            {showCompleteButton && (
              <Button bgColor='orange' onClick={handleOnClick}>
                <ButtonIcon src={downloadIcon} alt='download' />
                {t('common:toComplete')}
              </Button>
            )}
          </BeneficialOwnerRight>
        </BeneficialOwnerInfoWrapper>
      ) : (
        <DocumentRowWrapper>
          <LeftPart>
            <DocumentRowIcon
              src={kycImages[document.category]}
              alt={document.category}
            />
            <DocumentRowText>
              <DocumentRowTitle>{tradStepCertificate()}</DocumentRowTitle>
              <DocumentState state={document.state} />
            </DocumentRowText>
          </LeftPart>
          {showCompleteButton && (
            <RightPart>
              <Button bgColor='orange' onClick={handleOnClick}>
                <ButtonIcon src={downloadIcon} alt='download' />
                {t('common:toComplete')}
              </Button>
            </RightPart>
          )}
        </DocumentRowWrapper>
      )}
    </>
  )
}

export const DocumentRowWrapper = styled.div`
  padding: 16px;
  border: 1px solid ${colors.greyBorder};
  border-radius: 8px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`
export const LeftPart = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
`
export const DocumentRowIcon = styled.img`
  width: 40px;
  margin: 0;
`
export const DocumentRowText = styled.div`
  @media (max-width: ${constants.breakpoints['large-breakpoint-i']}) {
    max-width: 240px;
  }
  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    max-width: inherit;
  }
`
export const DocumentRowTitle = styled.div``
export const RightPart = styled.div`
  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    width: 100%;
    button {
      justify-content: center;
    }
  }
`
export const ButtonIcon = styled.img`
  margin: 0;
  margin-right: 10px;
`

export const BeneficialOwnerInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`
export const BeneficialOwnerInfoLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  color: ${colors.black};
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
`
export const BeneficialOwnerRight = styled.div``

export default DocumentRow
