import { gql } from '@apollo/client'
import { INFORMATION_CHANGE_FRAGMENT } from '../Fragments'

export const INFORMATION_CHANGES_QUERY = gql`
  query ContactInformationChanges($contactId: Int!) {
    informationChanges(contactId: $contactId) {
      ...InformationChangeFragment
    }
  }
  ${INFORMATION_CHANGE_FRAGMENT}
`
