import gql from 'graphql-tag'

export const FORWARDING_PACKS = gql`
  {
    forwardingPacks {
      id
      count
      description
      identificationToken
      price
      legalFees
      title
      specialOfferTemplate {
        cardBackgroundColor
        cardCtaBackgroundColor
        cardCtaIcon
        cardTitle
        cardTitleColor
        cardTitleIcon
        description
        freeQuantity
        id
        title
      }
    }
  }
`

export const LEGAL_FORMALITIES = gql`
  {
    legalFormalities {
      id
      identificationToken
      description
      price
      legalFees
      title
    }
  }
`
