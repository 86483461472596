import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Routing from '@/CustomerArea/Routing'
import { ContactContext } from 'src/contexts/contactContext'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import { useNavigate } from 'react-router-dom'
import { LinkWrapper, Tile, Title } from './styles'
import Chair from 'src/images/chair.svg'
import ContactPhone from 'src/images/contact_phone.svg'
import Savings from 'src/images/savings.svg'

const Layout = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { contact }: any = useContext(ContactContext)
  const { domiciliation }: any = useContext(DomiciliationContext)

  const countryCode = contact.countryConfig.countryCode
  const hotline = domiciliation?.hotline
  const haveNextAppointment = domiciliation?.nextAppointments?.length > 0

  const redirectToHotline = () => {
    if (hotline && hotline.state == 'activated') {
      navigate(Routing.SERVICES_HOTLINES_INDEX)
    } else {
      navigate(Routing.SERVICES_HOTLINES_FORM)
    }
  }

  const redirectToRoomReservation = () => {
    if (haveNextAppointment) {
      navigate(Routing.SERVICES_ROOM_MEETINGS_INDEX)
    } else {
      navigate(Routing.SERVICES_ROOM_MEETINGS_BOOK)
    }
  }

  return (
    <div>
      <Title>{t('services:daily')}</Title>
      <LinkWrapper>
        <Tile onClick={() => redirectToRoomReservation()}>
          <div>
            <img src={Chair} />
            <span>{t('services:bookings.title')}</span>
          </div>
        </Tile>
        <Tile onClick={() => redirectToHotline()}>
          <div>
            <img src={ContactPhone} />
            <span>{t('services:hotlines.title')}</span>
          </div>
        </Tile>
        {!contact.hideDomideal && (
          <Tile
            onClick={() =>
              window.open(
                `${process.env['REACT_APP_PARTNERS_DEALS_' + countryCode]}`,
                '_blank'
              )
            }
          >
            <div>
              <img src={Savings} />
              <span>{t('services:partners.title')}</span>
            </div>
          </Tile>
        )}
      </LinkWrapper>
    </div>
  )
}

export default Layout
