import * as React from 'react'
import { useTranslation } from 'react-i18next'
import IInvoice from '@/interfaces/invoice'

const Invoice = (props: IInvoice) => {
  const { t } = useTranslation()

  return (
    <tr className='invoice-container'>
      <td className='id-setting'>
        <span className='id-setting'>{props.invoiceNumber}</span>
      </td>
      <td className='label'>
        <span>{props.formattedName}</span>

        <a
          href={props.pdfUrl}
          className='download-invoice-link'
          download={true}
          target='_blank'
          rel='noreferrer'
        >
          {t('common:download')}
        </a>
      </td>
      <td className='info-setting'>
        <span>{t('common:date', { date: new Date(props.issueDate) })}</span>
      </td>
      <td className='invoice-actions'>
        <span className='price-setting'>
          {t('common:currency', { value: props.total })}
        </span>
        <span className='status'>{t('invoices:status.paid')}</span>
      </td>
    </tr>
  )
}

export default Invoice
