import gql from 'graphql-tag'

export const ADDRESS_DETAIL_FRAGMENT = gql`
  fragment AddressDetailFragment on AddressDetail {
    id
    streetAddress
    postalCode
    city
    country
    countryName
    fullAddress
  }
`
