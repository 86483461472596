import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IPaymentSource } from '@/interfaces/paymentSource'
import PaymentModal from './PaymentModal'
import Payment from './Payment'
import { DEMO_MODAL_QUERY, isDemo, toggleDemoModal } from '../../demo/Utils'
import { ContactContext } from 'src/contexts/contactContext'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import { ApolloClient, InMemoryCache, useQuery } from '@apollo/client'

const Payments = () => {
  const { t } = useTranslation()
  const { contact }: any = React.useContext(ContactContext)
  const { domiciliation }: any = React.useContext(DomiciliationContext)

  const client = new ApolloClient({
    cache: new InMemoryCache()
  })

  const { data } = useQuery(DEMO_MODAL_QUERY)

  const [showModalPaymentSourceCreate, setShowModalPaymentSourceCreate] =
    useState<boolean>(false)

  const onCloseModal = () => {
    setShowModalPaymentSourceCreate(false)
  }

  const handlePaymentSourceCreate = () => {
    if (isDemo()) {
      toggleDemoModal(client.cache, true)
      data.refetch()
    } else {
      setShowModalPaymentSourceCreate(true)
    }
  }

  return (
    <div className='settings-block payment-section'>
      <script src='https://js.stripe.com/v3/' />
      {showModalPaymentSourceCreate && (
        <PaymentModal onCloseModal={onCloseModal} />
      )}
      <h2 className='subtitle-sidebar'>
        {t('payments:informationsTitle')}
        <button
          className='btn primary-btn btn-service section-button primary-button'
          onClick={handlePaymentSourceCreate}
        >
          {t('payments:add')}
        </button>
      </h2>
      <div className='explain-container'>
        {domiciliation?.paymentType !== 'monthly' ? (
          <p
            dangerouslySetInnerHTML={{ __html: t('payments:informations') }}
          ></p>
        ) : (
          <p
            dangerouslySetInnerHTML={{
              __html: t('payments:informationsMonthly')
            }}
          ></p>
        )}
      </div>

      {contact.paymentSources.map((paymentSource: IPaymentSource) => {
        return <Payment {...paymentSource} key={paymentSource.id} />
      })}
    </div>
  )
}

export default Payments
