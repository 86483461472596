import styled from 'styled-components'
import icon from 'src/images/belgium-postal-procuration.svg'
import { Colors } from 'src/components/UIKit'
import { useTranslation } from 'react-i18next'
import cloudDownload from 'src/images/download/download-cloud-blue.svg'
import Button from 'src/components/UIKit/components/Button/buttonWithBorder'

interface BelgiumPPActivationProps {
  postalProcuration: string
  confirmPostalProcuration: any
}

const BelgiumPPActivation = ({
  postalProcuration,
  confirmPostalProcuration
}: BelgiumPPActivationProps) => {
  const { t } = useTranslation()
  return (
    <DownloadBelgiumWrapper>
      <TopPart>
        <LeftPart>
          <DownloadList>
            <DownloadNumber>1</DownloadNumber>
            <DownloadText>
              <DownloadTitle>
                {t('profile:belgium_postal_procuration.first_step.title')}
              </DownloadTitle>
              <DownloadDescription>
                {t('profile:belgium_postal_procuration.first_step.description')}
                <BelgiumPostalProcurationWeb
                  href='https://www.bpost.be/fr/procuration-postale'
                  rel='noreferrer'
                  target='_blank'
                >
                  bpost.be
                </BelgiumPostalProcurationWeb>
              </DownloadDescription>
            </DownloadText>
          </DownloadList>

          <DownloadList>
            <DownloadNumber>2</DownloadNumber>
            <DownloadText>
              <DownloadTitle>
                {t('profile:belgium_postal_procuration.second_step.title')}
              </DownloadTitle>
              <DownloadDescription>
                {t(
                  'profile:belgium_postal_procuration.second_step.description'
                )}
                <BelgiumPostalProcurationKnowMore
                  href='https://www.bpost.be/fr/procuration-postale'
                  rel='noreferrer'
                  target='_blank'
                >
                  En savoir plus
                </BelgiumPostalProcurationKnowMore>
              </DownloadDescription>
            </DownloadText>
          </DownloadList>

          <DownloadList>
            <DownloadNumber>3</DownloadNumber>
            <DownloadText>
              <DownloadTitle>
                {t('profile:belgium_postal_procuration.third_step.title')}
              </DownloadTitle>
              <DownloadDescription>
                {t('profile:belgium_postal_procuration.third_step.description')}
              </DownloadDescription>
            </DownloadText>
          </DownloadList>
        </LeftPart>
        <DownloadBelgiumImg src={icon} alt='letter' />
      </TopPart>
      <Buttons>
        <Button
          type='a'
          buttonStyle
          bgColor='white'
          href={postalProcuration}
          target='_blank'
          width='fit-content'
        >
          {t('common:download')}
          <ButtonIcon src={cloudDownload} alt='icone cloud download' />
        </Button>
        <Button
          bgColor='blue'
          onClick={confirmPostalProcuration}
          width='fit-content'
        >
          {t('profile:belgium_postal_procuration.send')}
        </Button>
      </Buttons>
    </DownloadBelgiumWrapper>
  )
}

export const DownloadBelgiumWrapper = styled.div`
  padding: 16px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`
export const TopPart = styled.div`
  display: flex;
  justify-content: space-between;
`
export const DownloadBelgiumImg = styled.img`
  margin: 0;
  padding: 16px;
`
export const LeftPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const DownloadList = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`
export const DownloadNumber = styled.div`
  background-color: #e7f2ff;
  color: ${Colors.blue};
  font-weight: 700;
  font-size: 14px;
  border-radius: 50%;
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const DownloadText = styled.div`
  font-size: 14px;
`
export const DownloadTitle = styled.div`
  color: #2f3437;
  font-weight: 600;
  margin-bottom: 4px;
`
export const DownloadDescription = styled.div`
  color: #6c767f;
`
export const BelgiumPostalProcurationWeb = styled.a`
  color: ${Colors.blue} !important;
  text-decoration: underline !important;
  &:hover {
    text-decoration: none !important;
  }
`
export const BelgiumPostalProcurationKnowMore = styled.a`
  color: #6c767f !important;
  text-decoration: underline !important;
  &:hover {
    text-decoration: none !important;
  }
`
export const ButtonIcon = styled.img`
  margin-left: 8px;
`
export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`
export default BelgiumPPActivation
