import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'antd'
import Form from './AddressForm'
import { IProps } from './Interfaces'

const AddressModal = (props: IProps) => {
  const { t } = useTranslation()

  return (
    <Modal
      data-id='domiciliation-forwarding-address-update'
      open={true}
      onCancel={props.onCloseModal}
      footer={null}
      title={t('enterprise:forwarding_address')}
    >
      <Form {...props} />
    </Modal>
  )
}

export default AddressModal
