import React from 'react'
import { Spin } from 'antd'

export interface SpinnerProps {
  loading: boolean
  children: React.ReactNode
}

export const Spinner: React.FC<SpinnerProps> = ({ loading, children }) => {
  return loading ? (
    <div className='flex justify-center items-center'>
      <Spin size='large' />
    </div>
  ) : (
    <>{children}</>
  )
}
