import gql from 'graphql-tag'

export const INVOICE_FRAGMENT = gql`
  fragment InvoiceFragment on Invoice {
    id
    name
    subtotal
    total
    issueDate
    pdfUrl
    formattedName
    invoiceNumber
    state
  }
`
