import React, { useContext } from 'react'
import styled from 'styled-components'
import HeadPhoneIcon from 'src/images/headphone-icon.svg'
import { useTranslation } from 'react-i18next'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'

const TopPart = () => {
  const { t } = useTranslation()
  const { domiciliation }: any = useContext(DomiciliationContext)
  const url =
    'https://faq.sedomicilier.com/hc/fr/articles/10778354546578--Comment-souscrire-au-standard-t%C3%A9l%C3%A9phonique'
  return (
    <TopPartWrapper>
      <HotlineIcon
        src={HeadPhoneIcon}
        alt='icone casque audio'
        className='m-auto w-9 inline-block'
      />
      <HotlineTitle>{t('services:hotlines:phoneStandard')}</HotlineTitle>
      {domiciliation.hotlineFreeMonths > 0 && (
        <HotlineDescription>
          <DescriptionContent>
            <Promotion>
              {t('services:hotlines:oneMonthFree')} <br />
              {t('services:hotlines:withoutObligation')}
            </Promotion>
            {t('services:hotlines:opt')}
            <br />
            {t('services:hotlines:answerCalls')}
          </DescriptionContent>
          <SupportLink href={url} target='_blank'>
            {t('services:hotlines:checkOurConditions')}
          </SupportLink>
        </HotlineDescription>
      )}
    </TopPartWrapper>
  )
}

export const TopPartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`
export const HotlineIcon = styled.img`
  margin-bottom: 16px;
`
export const HotlineTitle = styled.h1`
  margin-bottom: 32px;
  font-weight: 600;
  font-size: 28px;
`
export const HotlineDescription = styled.p`
  font-size: 16px;
  color: #6c767f;
`
export const DescriptionContent = styled.div``
export const Promotion = styled.p`
  font-weight: 500;
  color: #171a1c;
`
export const SupportLink = styled.a`
  color: #6c767f !important;
  text-decoration: underline !important;
  &:hover {
    text-decoration: none !important;
  }
`

export default TopPart
