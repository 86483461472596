import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import OwnerType from '@/app/LegalFlow/Capital/ownerType'
import { FormInstance } from 'antd'
import PhysicalForm from '@/app/LegalFlow/Capital/physicalForm'
import MoraleForm from '@/app/LegalFlow/Capital/moraleForm'

interface BeneficialOwnerUnitProps {
  index: number
  id: number
  shareholderType?: string
  onDelete: () => void
  form: FormInstance
}

const BeneficialOwnerUnit: React.FC<BeneficialOwnerUnitProps> = ({
  id,
  index,
  onDelete,
  form,
  shareholderType
}) => {
  const { t } = useTranslation()
  const [status, setStatus] = useState(shareholderType || 'physical')

  return (
    <div className='flex flex-col gap-2 border border-neutral-200 p-3 rounded-medium'>
      <div className='flex justify-between items-center'>
        <p className='body-1 font-semibold'>
          {t('capital:owner', {
            index: index
          })}
        </p>
        <span
          className='ico-trash text-l text-interaction cursor-pointer'
          onClick={onDelete}
        />
      </div>

      <OwnerType status={status} setStatus={setStatus} />

      {status === 'physical' && <PhysicalForm form={form} id={id} />}
      {status === 'moral' && <MoraleForm form={form} id={id} />}
    </div>
  )
}

export default BeneficialOwnerUnit
