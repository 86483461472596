import { PERSONAL_INFORMATION_FRAGMENT } from '@/graphql/Fragments'
import { IPersonalInformation } from '@/interfaces/personalInformation'
import { gql } from '@apollo/client'

export interface LeaderContactDetailsData {
  contact: {
    personalInformation: IPersonalInformation
  }
}

export const LEADER_CONTACT_DETAILS_QUERY = gql`
  query leaderContactDetails {
    contact {
      personalInformation {
        ...PersonalInformationFragment
      }
    }
  }
  ${PERSONAL_INFORMATION_FRAGMENT}
`
