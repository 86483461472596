/* eslint-disable react/prop-types */
import * as React from 'react'
import { useContext } from 'react'
import styled from 'styled-components'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import { Form, Input } from 'antd'
import { ContactContext } from 'src/contexts/contactContext'

interface BillingInformationProps {
  children: React.ReactNode
}

const BillingInformation: React.FC<BillingInformationProps> = (props) => {
  const { contact }: any = useContext(ContactContext)
  const { domiciliation }: any = useContext(DomiciliationContext)

  const leaderAddress = contact?.personalInformation?.leaderAddressDetail

  return (
    <>
      {!domiciliation && !leaderAddress.fullAddress ? (
        <BillingInformationWrapper>
          <>
            <InformationNeed>
              Nous avons besoin de votre adresse de facturation.
            </InformationNeed>
            <Form.Item
              name='streetAddress'
              label='Numéro et nom de rue'
              rules={[
                {
                  required: true,
                  message: 'Veuillez renseigner votre numéro et nom de rue'
                }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name='city'
              label='Ville'
              rules={[
                { required: true, message: 'Veuillez renseigner votre ville' }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item name='postalCode' label='Code postal'>
              <Input />
            </Form.Item>
            <Form.Item name='country' label='Pays'>
              <Input disabled={true} placeholder='France' />
            </Form.Item>
          </>
          {props.children}
        </BillingInformationWrapper>
      ) : (
        props.children
      )}
    </>
  )
}

export const InformationNeed = styled.p`
  margin-bottom: 24px;
`

export const BillingInformationWrapper = styled.div`
  font-size: 14px;
  margin-top: 16px;
  padding: 40px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  & > div {
    margin-bottom: 8px;
  }
  & > div:last-child {
    margin-bottom: 16px;
  }
`

export default BillingInformation
