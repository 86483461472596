export enum CommunicationRestriction {
  alert = 'alert',
  paymentFailure = 'payment_failure',
  verificationFailure = 'verification_failure',
  verificationWaiting = 'verification_waiting',
  unpaidInvoices = 'unpaid_invoices',
  outdatedDefaultCard = 'outdated_default_card',
  mailWarning = 'mail_warning'
}

export enum CommunicationStrengthRestriction {
  notification = 'notification',
  soft = 'soft',
  hard = 'hard',
  extrem = 'extrem'
}

export enum CommunicationSection {
  dashboard = 'dashboard',
  payment = 'payment',
  profile = 'profile',
  userMails = 'user_mails'
}
