import styled from 'styled-components'

export const H1 = styled.h1`
  font-weight: 600;
  font-size: 22px;
  line-height: 140%;
  margin-bottom: 40px;
  display: none;
  font-family: 'publico';
  @media (max-width: 900px) {
    display: block;
  }
`

export const H2 = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
`

export const Wrapper = styled.div`
  padding: 0 50px;
  @media (max-width: 900px) {
    padding: 0 24px;
  }
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`

/* eslint-disable max-len */
// export const ItemList = styled.div`
//   &::after {
//     content: ' ';
//     clear: both;
//     display: block;
//     height: 40px;
//     width: 100%;
//     border-bottom: 1px solid #C4C7CA;
//     margin-bottom: 40px;
//   }
//   .name {
//     font-weight: 600;
//     font-size: 18px;
//     line-height: 140%;
//     color: #131416;
//   }
//   .description {
//     margin-top: 8px;
//     font-weight: 400;
//     font-size: 16px;
//     line-height: 150%;
//     color: #686A6B;
//   }
//   .actions {
//     margin-top: 16px;
//     a {
//       margin-right: 8px;
//       display: inline-block;
//       svg {
//         margin-left: 10px;
//       }
//     }
//     .section-button {
//       color: white !important;
//       @media(max-width: 900px) {
//         margin-bottom: 12px;
//       }
//     }
//     .secondary-button {
//       color: #1576E1;
//       font-weight: 400;
//       font-size: 14px;
//       line-height: 125%;
//       padding: 9px 24px 10px 24px;
//       border: 1px solid #1576E1;
//       border-radius: 4px;
//       background: white;
//       &:hover {
//         cursor: pointer;
//       }
//     }
//     .btn-download {
//       color: #1576E1;
//       font-weight: 400;
//       font-size: 14px;
//       line-height: 125%;
//       padding: 9px 24px 10px 24px;
//       border: 1px solid #1576E1;
//       border-radius: 4px;
//       background: white;
//       display: inline-block;
//     }
//     .btn-download-disabled {
//       color: #75818C;
//       font-weight: 400;
//       font-size: 14px;
//       line-height: 125%;
//       padding: 9px 24px 10px 24px;
//       border: 1px solid #75818C;
//       border-radius: 4px;
//       background: white;
//       cursor: not-allowed;
//       display: inline-block;
//     }
//     .ant-alert {
//        margin-top: 12px;
//        border-radius: 4px;
//        border: none;
//        background: #FBF0E8;
//        padding-left: 40px;
//        display: inline-block;
//        width: auto;
//        position: relative;
//        &::before {
//         content: ' ';
//         background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 15C10.2833 15 10.521 14.904 10.713 14.712C10.9043 14.5207 11 14.2833 11 14C11 13.7167 10.9043 13.479 10.713 13.287C10.521 13.0957 10.2833 13 10 13C9.71667 13 9.47933 13.0957 9.288 13.287C9.096 13.479 9 13.7167 9 14C9 14.2833 9.096 14.5207 9.288 14.712C9.47933 14.904 9.71667 15 10 15ZM9 11H11V5H9V11ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.262667 12.6833 0 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31267 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31233 6.1 0.787C7.31667 0.262333 8.61667 0 10 0C11.3833 0 12.6833 0.262333 13.9 0.787C15.1167 1.31233 16.175 2.025 17.075 2.925C17.975 3.825 18.6873 4.88333 19.212 6.1C19.7373 7.31667 20 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20Z' fill='%23E06611'/%3E%3C/svg%3E%0A");
//         width: 20px;
//         position: absolute;
//         left: 10px;
//         top: 10px;
//         height: 20px;
//        }
//     }
//     .ant-alert-message {
//        color: #BD5003;
//     }
//   }
// `
/* eslint-enable max-len */
