import { useContext, useState } from 'react'
import styled from 'styled-components'
import AddressModal from 'src/components/profile/shared/address/AddressModal'
import { Can } from 'src/contexts/abilityContext'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import BuyPacks from './BuyPacks'
import { useTranslation } from 'react-i18next'
import houseSVG from 'src/images/house.svg'

const UserAddressInformations = () => {
  const { t } = useTranslation()
  const { domiciliation } = useContext(DomiciliationContext)

  const [openModal, setOpenModal] = useState(false)
  return (
    <div className='grid grid-cols-5 gap-4'>
      <div className='desktop:col-span-3 col-span-5'>
        <UserInformationsWrapper>
          <ReexpeditionAddress>
            <img src={houseSVG} className='inline align-top mt-0.5 mr-1' />
            {domiciliation.forwardingAddress?.fullAddress}
          </ReexpeditionAddress>
          {openModal && (
            <AddressModal
              onCloseModal={() => setOpenModal(false)}
              initialValues={domiciliation}
            />
          )}
          <Can I='customer_manage' a='Domiciliation'>
            <AddressUpdate
              href='javascript:;'
              onClick={() => setOpenModal(true)}
            >
              {t('userMails:global.addressModification')}
            </AddressUpdate>
          </Can>
        </UserInformationsWrapper>
      </div>
      <div className='desktop:col-span-2 desktop:!text-right col-span-5'>
        {domiciliation.canExpressForward && <BuyPacks />}
      </div>
    </div>
  )
}

export const UserInformationsWrapper = styled.div`
  font-weight: 400;
  font-size: 14px;
  display: inline-block;
`

export const InformationsTitle = styled.h2`
  font-size: 20px;
  color: #171a1c;
  font-weight: 600;
  margin-bottom: 16px;
  @media (max-width: 900px) {
    font-size: 18px;
  }
`
export const ReexpeditionAddress = styled.div`
  color: #75818c;
`
export const AddressUpdate = styled.a`
  color: #1576e1;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`

export default UserAddressInformations
