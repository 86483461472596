import React, { useState, useContext } from 'react'
import { MutationFunction } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { NOTIFICATION_UNSUBSCRIPTION_UPDATE_MUTATION } from '@/graphql/mutations/notificationUnsubscription'
import { IProps, ISelected } from './Interfaces'
import {
  serializeNotificationUnsubscription,
  unserializeNotificationUnsubscription
} from './Utils'
import { useTranslation } from 'react-i18next'
import { AbilityContext } from 'src/contexts/abilityContext'

const Content = (props: IProps) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState<ISelected>({
    notificationUnsubscriptions: serializeNotificationUnsubscription(
      props.initialValues
    )
  })
  const ability = useContext(AbilityContext)

  const onChange = (
    type: string,
    notificationUnsubscriptionUpdate: MutationFunction
  ) => {
    if (selected.notificationUnsubscriptions.includes(type)) {
      const res = selected.notificationUnsubscriptions
      res.splice(res.indexOf(type), 1)
      setSelected({
        notificationUnsubscriptions: res
      })
    } else {
      const res = selected.notificationUnsubscriptions
      res.push(type)
      setSelected({
        notificationUnsubscriptions: res
      })
    }
    handleSubmit(notificationUnsubscriptionUpdate)
  }

  const handleSubmit = (notificationUnsubscriptionUpdate: MutationFunction) => {
    notificationUnsubscriptionUpdate({
      variables: unserializeNotificationUnsubscription(
        selected.notificationUnsubscriptions
      )
    })
  }

  return (
    <Mutation mutation={NOTIFICATION_UNSUBSCRIPTION_UPDATE_MUTATION}>
      {(notificationUnsubscriptionUpdate) => (
        <form name='notificationUnsubscriptionUpdateForm'>
          <div className='notification-element'>
            <div className='bloc-notification-large'>
              <p>{t('notifications:usermails')}</p>
              <p className='info-sub'>{t('notifications:usermails_text')}</p>
            </div>
            <div className='bloc-notification-right'>
              <input
                type='checkbox'
                id='newUserMail'
                name='userMails'
                checked={selected.notificationUnsubscriptions.includes(
                  'newUserMail'
                )}
                onChange={() =>
                  onChange('newUserMail', notificationUnsubscriptionUpdate)
                }
                disabled={ability.cannot('customer_manage', 'Notification')}
              />
              <label htmlFor='newUserMail' />
            </div>
          </div>
          <div className='notification-element'>
            <div className='bloc-notification-large'>
              <p>{t('notifications:support')}</p>
              <p className='info-sub'>{t('notifications:support_text')}</p>
            </div>
            <div className='bloc-notification-right'>
              <input
                type='checkbox'
                id='support'
                name='userMails'
                checked={selected.notificationUnsubscriptions.includes(
                  'support'
                )}
                onChange={() =>
                  onChange('support', notificationUnsubscriptionUpdate)
                }
                disabled={ability.cannot('customer_manage', 'Notification')}
              />
              <label htmlFor='support' />
            </div>
          </div>
          <div className='notification-element'>
            <div className='bloc-notification-large'>
              <p>{t('notifications:marketing')}</p>
              <p className='info-sub'>{t('notifications:marketing_text')}</p>
            </div>
            <div className='bloc-notification-right'>
              <input
                type='checkbox'
                id='marketing'
                name='userMails'
                checked={selected.notificationUnsubscriptions.includes(
                  'marketing'
                )}
                onChange={() =>
                  onChange('marketing', notificationUnsubscriptionUpdate)
                }
                disabled={ability.cannot('customer_manage', 'Notification')}
              />
              <label htmlFor='marketing' />
            </div>
          </div>
        </form>
      )}
    </Mutation>
  )
}

export default Content
