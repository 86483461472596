import { configureStore, combineReducers } from '@reduxjs/toolkit'
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import workflowReducer from '@/reducers/workflow'
import storage from './storage'
import societyInformationsReducer from '@/reducers/societyInformations'

const persistConfig = { key: 'root', storage: storage }

const rootReducer = combineReducers({
  workflow: workflowReducer,
  societyInformations: societyInformationsReducer
})

export const persistedReducer = persistReducer(persistConfig, rootReducer)
export const setupStore = (preloadedState?: RootState) => {
  return configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
      }),
    preloadedState
  })
}

export type RootState = ReturnType<typeof persistedReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
