import { Helmet } from 'react-helmet'

const DemoHeader = () => {
  return (
    <Helmet>
      <title>SeDomicilier - Démo</title>
      <meta
        name='description'
        // eslint-disable-next-line max-len
        content='Découvrez l’ensemble des possibilités sur votre futur espace client SeDomicilier, n°1 de la domiciliation en France'
      />
      <meta name='robots' content='noindex' />
    </Helmet>
  )
}

export default DemoHeader
