import { useState } from 'react'
import styled from 'styled-components'
import { Popover } from 'antd'
import closeIcon from 'src/images/close-cross-black.svg'
import { useTranslation } from 'react-i18next'

const NpaiModal = () => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState(false)

  const toggleModal = () => {
    setOpenModal(!openModal)
  }
  const modalContent = (
    <ModalWrapper>
      <CloseModal>
        <CloseIcon src={closeIcon} alt='close icon' onClick={toggleModal} />
      </CloseModal>
      <ModalTitle>{t('userMails:npaiModal.modalTitle')}</ModalTitle>
      <ModalContent>{t('userMails:npaiModal.modalContent')}</ModalContent>
    </ModalWrapper>
  )

  return (
    <NpaiModalWrapper onClick={(e) => e.stopPropagation()}>
      <Popover
        trigger='click'
        open={openModal}
        onOpenChange={toggleModal}
        content={modalContent}
      >
        <NpaiTitleToggle>{t('userMails:npaiModal.knowMore')}</NpaiTitleToggle>
      </Popover>
    </NpaiModalWrapper>
  )
}

export const NpaiModalWrapper = styled.span``
export const NpaiTitleToggle = styled.button`
  color: #cd100c;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  font-size: 12px;
  &:hover {
    text-decoration: none;
    color: #cd100c;
    cursor: pointer;
  }
`
export const ModalWrapper = styled.div`
  width: 250px;
  font-size: 12px;
  font-family: 'Open Sans';
`
export const ModalTitle = styled.p`
  font-weight: 600;
  padding: 12px 8px 0 8px;
`
export const ModalContent = styled.p`
  padding: 12px 8px 0 8px;
`
export const CloseModal = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const CloseIcon = styled.img`
  margin: 0;
  &:hover {
    cursor: pointer;
  }
`
export default NpaiModal
