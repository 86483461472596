const navigationSections = ({ domiciliation, kyc, Routing, ability, t }) => {
  const sections = [
    {
      display: true,
      route: Routing.ROOT,
      authorized: ability.can('customer_manage', 'Domiciliation'),
      dataSpecId: 'sideMenuLink-logo-root',
      icon: (
        <span className='ico-sd-picto text-1 text-interaction desktop:pb-1.5' />
      ),
      logo: true,
      affiliated: !domiciliation
    },
    {
      display: ability.can('customer_manage', 'Domiciliation') || domiciliation,
      route: Routing.ROOT,
      authorized: ability.can('customer_manage', 'Domiciliation'),
      dataSpecId: 'sideMenuLink-root',
      icon: <span className='ico-house-empty text-2 text-neutral-700' />,
      sectionTitle: t('nav:dashboard')
    },

    // Ma domiciliation
    {
      display: domiciliation,
      route: Routing.PROFILE,
      authorized: ability.can('customer_read', 'LegalDocument'),
      dataSpecId: 'sideMenuLink-profile',
      icon: <span className='ico-ping text-2 text-neutral-700' />,
      sectionTitle: t('nav:myDomiciliation')
    },

    // DOCUMENTS
    {
      display: domiciliation,
      route: Routing.PROFILE_ATTESTATIONS,
      authorized:
        ability.can('customer_read', 'LegalDocument') &&
        ability.can('customer_read', 'Domiciliation'),
      dataSpecId: 'sideMenuLink-profile-attestations',
      icon: <span className='ico-files text-2 text-neutral-700' />,
      sectionTitle: t('nav:documents')
    },

    // DEMARCHES
    {
      display: domiciliation,
      route: Routing.PROFILE_LEGAL_FILES,
      authorized:
        ability.can('customer_read', 'LegalDocument') &&
        ability.can('customer_read', 'Domiciliation'),
      dataSpecId: 'sideMenuLink-profile-legal_files',
      icon: <span className='ico-satchel text-2 text-neutral-700' />,
      sectionTitle: t('nav:procedure'),
      notification: kyc?.legalDocuments
    },

    // Courriers
    {
      display: ability.can('customer_read', 'UserMail') || domiciliation,
      route: Routing.USER_MAILS_INDEX,
      authorized: ability.can('customer_read', 'UserMail'),
      dataSpecId: 'sideMenuLink-userMail',
      icon: <span className='ico-letter-close text-2 text-neutral-700' />,
      sectionTitle: t('nav:userMails'),
      notification: kyc?.userMails > 0 ? kyc?.userMails : undefined
    },

    // Services
    {
      display: ability.can('customer_manage', 'Contact') || domiciliation,
      route: Routing.SERVICES_INDEX,
      authorized: ability.can('customer_manage', 'Contact'),
      dataSpecId: 'sideMenuLink-service',
      icon: <span className='ico-apps text-2 text-neutral-700' />,
      sectionTitle: t('nav:services')
    },

    // Paramètres
    {
      display: true,
      route: Routing.SETTINGS,
      authorized: true,
      dataSpecId: 'sideMenuLink-settings',
      icon: <span className='ico-setting text-2 text-neutral-700' />,
      sectionTitle: t('nav:settings')
    },

    // Mon Equipe
    {
      display:
        (ability.can('customer_read', 'User') &&
          ability.can('customer_read', 'Domiciliation')) ||
        domiciliation,
      route: Routing.TEAM,
      authorized:
        ability.can('customer_read', 'User') &&
        ability.can('customer_read', 'Domiciliation'),
      dataSpecId: 'sideMenuLink-team',
      icon: <span className='ico-silhouettes text-2 text-neutral-700' />,
      sectionTitle: t('nav:team')
    },

    // Parrainage
    {
      display: ability.can('customer_read', 'Referral') || domiciliation,
      route: Routing.REFERRER,
      authorized: ability.can('customer_read', 'Referral'),
      dataSpecId: 'sideMenuLink-referrer',
      icon: <span className='ico-silhouette-plus text-2 text-neutral-700' />,
      sectionTitle: t('nav:referrer')
    }
  ]
  return sections
}

export default navigationSections
