import gql from 'graphql-tag'

export const USER_NOTIFICATION_FRAGMENT = gql`
  fragment UserNotificationFragment on UserNotification {
    id
    contactId
    userId
    createdAt
  }
`
