import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

interface DocumentsStorageOptionsProps {
  storageLocation?: string
  setStorageLocation: Dispatch<SetStateAction<string | undefined>>
}

const DocumentsStorageOptions: React.FC<DocumentsStorageOptionsProps> = ({
  storageLocation,
  setStorageLocation
}) => {
  const { t } = useTranslation()
  const options = [
    {
      id: 'self',
      icon: <span className='ico-d-hut text-l' />,
      label: t('accounting:leaderHome')
    },
    {
      id: 'accountant',
      icon: <span className='ico-d-calculator text-l' />,
      label: t('accounting:accountant.title')
    },
    {
      id: 'other',
      icon: <span className='ico-dots text-l' />,
      label: t('accounting:other')
    }
  ]

  return (
    <div className='flex gap-2 mb-3'>
      {options.map((option) => {
        const basicStyle =
          'flex flex-col flex-1 gap-1 items-center justify-center p-3 border rounded-medium cursor-pointer'
        const optionStyle =
          storageLocation === option.id
            ? `${basicStyle} !border-2 !border-interaction`
            : `${basicStyle} border-neutral-200`

        return (
          <div
            key={option.id}
            data-testid={option.id}
            onClick={() => setStorageLocation(option.id)}
            className={optionStyle}
          >
            {option.icon}
            <div className='body-1 text-center'>{option.label}</div>
          </div>
        )
      })}
    </div>
  )
}

export default DocumentsStorageOptions
