import { Carousel } from 'antd'
import React from 'react'

interface IProps {
  items: Array<{ src: string; altText: string }>
}

const CarouselPhoto = (props: IProps) => {
  return (
    <Carousel autoplay className='h-auto'>
      {props.items.map((item, index) => (
        <div key={index} className='text-center'>
          <img src={item.src} className='max-h-20' alt={item.altText} />
        </div>
      ))}
    </Carousel>
  )
}

export default CarouselPhoto
