import React from 'react'
import { DatePicker } from 'antd'
import { FieldProps } from 'formik'
import dayjs, { Dayjs } from 'dayjs'
import styled from 'styled-components'

const FORMAT = 'YYYY-MM-DD'

interface IProps extends FieldProps {
  displayFormat?: string
  placeholder?: string
}

const FormikDatePicker: React.FC<IProps> = (props) => {
  const onChange = (date: Dayjs) => {
    props.form.setFieldValue(props.field.name, date?.format(FORMAT))
  }

  return (
    <DatePickerStyled
      id={props.field.name}
      format={FORMAT}
      minDate={dayjs()}
      maxDate={dayjs().add(8, 'month')}
      placeholder={props.placeholder || ''}
      onChange={onChange}
    />
  )
}

export const DatePickerStyled = styled(DatePicker)`
  width: 100%;
  height: 35px;
  border-radius: 0.25rem;
  border: solid 1px #dddddd;
  padding: 6px 8px 6px 12px;

  .ant-picker-input > Input {
    padding: 0;
  }
  .ant-picker-input input::placeholder {
    color: #a9abae;
  }
`

export default FormikDatePicker
