import styled from 'styled-components'

interface CenterValidationStatusProps {
  icon: any
  text: string
  alt: string
  type: 'success' | 'waiting'
}

const CenterValidationStatus = ({
  icon,
  text,
  alt,
  type
}: CenterValidationStatusProps) => {
  return (
    <CenterValidationStatusWrapper success={type === 'success'}>
      <img src={icon} alt={alt} />
      {text}
    </CenterValidationStatusWrapper>
  )
}

export const CenterValidationStatusWrapper = styled.div`
  background-color: ${(props) => (props.success ? '#E7F5DC' : '#ffe1cc')};
  color: ${(props) => (props.success ? '#169254' : '#B25512')};
  border-radius: 8px;
  padding: 18px 11px;
  width: fit-content;
  img {
    margin-right: 10px;
  }
`

export default CenterValidationStatus
