// @ts-nocheck
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SubmitLoader } from '@/components/shared/Loader'
import { useMutation } from '@apollo/client'
import { INVITE_REFEREE_QUERY } from '@/graphql/mutations/referrer'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import IGenericError from '@/interfaces/genericError'
import { openValidationPopup } from 'src/components/shared/ConfirmationPopups'
import { Modal } from 'antd'

interface IProps {
  isOpen: boolean
  onCloseModal: () => void
  onInvitationSent: () => void
}

const InviteRefereeModal = (props: IProps) => {
  const { t } = useTranslation()

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [referrerInviteReferee] = useMutation(INVITE_REFEREE_QUERY)
  const onFormSubmit = () => (values: any) => {
    setIsSubmitting(true)
    setErrorMsg('')

    referrerInviteReferee({
      variables: {
        email: values.email
      },
      update: (cache: any, { data }: { data: { error: IGenericError } }) => {
        if (data.error) {
          setErrorMsg(data.error.message)
        } else {
          openValidationPopup(
            t('referrer:invitation.success.title'),
            t('referrer:invitation.success.message', { email: values.email })
          )
          props.onInvitationSent()
        }
      }
    })
      .catch(() => setErrorMsg(t('referrer:invitation.error.message')))
      .finally(() => setIsSubmitting(false))
  }

  const validation = Yup.object().shape({
    email: Yup.string()
      .email(
        t('common:detailed_errors.invalid', { field: t('common:emailAddress') })
      )
      .required(
        t('common:detailed_errors.required-f', {
          field: t('common:emailAddress')
        })
      )
  })

  return (
    <>
      <Modal
        data-id='modal-invite-referee'
        open={props.isOpen}
        zIndex={999999}
        onCancel={props.onCloseModal}
        footer={null}
        title={t('referrer:invite_referee')}
      >
        <Formik
          onSubmit={onFormSubmit()}
          initialValues={{
            email: ''
          }}
          validationSchema={validation}
        >
          {({ errors, touched, submitForm }) => (
            <>
              <div className='mb-2'>
                <Field
                  type='email'
                  name='email'
                  placeholder='Email'
                  className={`border border-neutral-200 focus:outline-none
                  focus:border-primary rounded-md w-full py-2 px-3 text-neutral-700
                  ${touched.email && errors.email ? 'border-red-500' : ''}`}
                />
                {touched.email && errors.email && (
                  <div className='text-error-700 text-sm mt-1'>
                    {errors.email}
                  </div>
                )}

                {errorMsg && (
                  <div className='text-error-700 text-sm mt-1'>{errorMsg}</div>
                )}
              </div>

              <div className='flex justify-center mt-2'>
                <button
                  type='submit'
                  className='submit section-button'
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  <SubmitLoader isSubmitting={isSubmitting} />
                  <span>{t('common:validate')}</span>
                </button>
              </div>
            </>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export default InviteRefereeModal
