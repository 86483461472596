import { gql } from '@apollo/client'

export const INVITE_REFEREE_QUERY = gql`
  mutation ReferrerInviteReferee($email: String!) {
    referrerInviteReferee(email: $email) {
      referee {
        id
        status
        bonus
        refereeEmail
      }
    }
  }
`
