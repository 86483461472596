import * as React from 'react'
import ImageAr from '@/images/ar.svg'
import ImageDefaultLetter from '@/images/default-letter.svg'
import ImageDefaultLetterAr from '@/images/default-letter-ar.svg'
import ImageDefaultParcel from '@/images/default-parcel.png'
import { IUserMail } from '@/interfaces/userMail'
import { ITag } from '@/interfaces/tag'
import { UserMailMailType } from './Enum'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxAlt } from '@sedomicilier/pro-light-svg-icons'

export const MailType = (props: { type: string; className?: string }) => {
  return (
    <>
      {props.type === UserMailMailType.letterAr && (
        <img
          src={ImageAr}
          className={props.className || 'notification-content'}
        />
      )}
      {props.type === UserMailMailType.parcel && (
        <span className={`${props.className} notification-content parcel`}>
          <FontAwesomeIcon icon={faBoxAlt} />
        </span>
      )}
    </>
  )
}

export const Envelope = (props: { userMail: IUserMail }) => {
  if (props.userMail.scanFileThumbUrl) {
    return (
      <a href={props.userMail.scanFileUrl} target='_blank' rel='noreferrer'>
        <div className='w-full h-full flex justify-center items-center'>
          <img
            className='object-contain max-w-full max-h-full'
            src={props.userMail.scanFileThumbUrl}
          />
        </div>
      </a>
    )
  } else if (props.userMail.mailType === UserMailMailType.letterAr) {
    return (
      <img
        src={ImageDefaultLetterAr}
        className='w-full h-full object-contain'
      />
    )
  } else if (props.userMail.mailType === UserMailMailType.parcel) {
    return (
      <img src={ImageDefaultParcel} className='w-full h-full object-contain' />
    )
  } else {
    return (
      <img src={ImageDefaultLetter} className='w-full h-full object-contain' />
    )
  }
}

export const Tag = (props: { tag?: ITag; defaultText?: string }) => {
  if (props.tag) {
    return (
      <div className='tag-name' style={{ color: props.tag.color }}>
        <label
          className='line'
          style={{
            backgroundColor: props.tag.color,
            borderColor: props.tag.color
          }}
        />
        {props.tag.name}
      </div>
    )
  } else {
    return <div className='tag-name to-define'>{props.defaultText || '-'}</div>
  }
}
