import { LegalStatusType } from '@/interfaces/legalStatus'
import { gql } from '@apollo/client'

export interface LegalStatusesDataType {
  legalStatuses: LegalStatusType[]
}

export interface AllLegalStatusesDataType {
  allLegalStatuses: LegalStatusType[]
}

export const LEGAL_STATUSES_QUERY = gql`
  query legalStatus {
    legalStatuses {
      id
      name
      isMicro
    }
  }
`

export const ALL_LEGAL_STATUSES_QUERY = gql`
  query allLegalStatus {
    allLegalStatuses {
      id
      name
      isMicro
    }
  }
`
