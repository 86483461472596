import { useState } from 'react'
import BeneficialOwnerUnit from '@/app/LegalFlow/Capital/beneficialOwnerUnit'
import { BasicButton } from '@sedomicilier/material-sd'
import { Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { nextPage } from '@/reducers/workflow'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { UPSERT_SHAREHOLDERS } from '@/graphql/mutations/upsertShareholders'
import { IShareholder } from '@/interfaces/shareholder'
import dayjs from 'dayjs'

interface AddBeneficialOwnerProps {
  shareholders: IShareholder[]
}

const AddBeneficialOwner: React.FC<AddBeneficialOwnerProps> = ({
  shareholders
}) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [upsertShareholders] = useMutation(UPSERT_SHAREHOLDERS)
  const [loading, setLoading] = useState<boolean>(false)

  const parseMoral = (shareholder) => ({
    societyName: shareholder.societyName,
    lastname: shareholder.leaderLastname,
    firstname: shareholder.leaderFirstname,
    legalStatusName: shareholder.legalStatusName,
    complement: shareholder.complement,
    streetAddress: shareholder.address.streetAddress,
    postalCode: shareholder.address.postalCode,
    city: shareholder.address.city,
    country: shareholder.address.countryCode || shareholder.address.country
  })

  const parsePhysical = (shareholder) => ({
    lastname: shareholder.lastname,
    firstname: shareholder.firstname,
    personallyExposed: shareholder.personallyExposed,
    functionPerformed: shareholder.functionPerformed,
    cessationDate: shareholder.cessationDate,
    closeRelationExposed: shareholder.closeRelationExposed,
    closeRelationFunctionPerformed: shareholder.closeRelationFunctionPerformed,
    closeRelationType: shareholder.relationType
  })

  const onFinish = (values) => {
    if (!loading) {
      setLoading(true)
      const params = values.shareholders.map((shareholder) =>
        shareholder.shareholderType === 'moral'
          ? { moral: parseMoral(shareholder) }
          : { physical: parsePhysical(shareholder) }
      )

      upsertShareholders({ variables: { shareholders: params } }).then(() =>
        dispatch(nextPage())
      )
    }
  }

  const initialValues = shareholders.map((shareholder: IShareholder) => ({
    shareholderType: shareholder.legalType,
    societyName: shareholder.societyName,
    legalStatusName: shareholder.legalStatus,
    lastname: shareholder.contactDetail.lastname,
    firstname: shareholder.contactDetail.firstname,
    leaderLastname: shareholder.contactDetail.lastname,
    leaderFirstname: shareholder.contactDetail.firstname,
    address: shareholder.addressDetail?.fullAddress,
    personallyExposed: !!shareholder.politicallyExposed?.personallyExposed,
    functionPerformed: shareholder.politicallyExposed?.functionPerformed,
    cessationDate: shareholder.politicallyExposed?.cessationDate
      ? dayjs(shareholder.politicallyExposed.cessationDate)
      : undefined,
    closeRelationExposed:
      !!shareholder.politicallyExposed?.closeRelationExposed,
    closeRelationFunctionPerformed:
      shareholder.politicallyExposed?.closeRelationFunctionPerformed,
    closeRelationType: shareholder.politicallyExposed?.closeRelationType
  }))

  return (
    <Form
      form={form}
      requiredMark={false}
      layout='vertical'
      onFinish={onFinish}
      initialValues={{ shareholders: initialValues }}
    >
      <Form.List name='shareholders'>
        {(fields, { add, remove }) => (
          <>
            <div className='flex flex-col gap-3 my-2'>
              {fields.map((field) => (
                <BeneficialOwnerUnit
                  key={field.key}
                  id={field.name}
                  index={field.name + 1}
                  shareholderType={form.getFieldValue([
                    'shareholders',
                    field.name,
                    'shareholderType'
                  ])}
                  onDelete={() => remove(field.name)}
                  form={form}
                />
              ))}
            </div>
            {fields.length < 4 && (
              <button
                data-testid='add-benefical-owner-button'
                type='button'
                onClick={add}
                className='border !border-interaction w-full rounded-medium py-[14px] flex items-center justify-center gap-1 text-interaction'
              >
                <span className='ico-plus-round text-interaction text-l' />
                {t('capital:addLeader')}
              </button>
            )}
          </>
        )}
      </Form.List>

      <BasicButton
        testid='capital-next-button'
        label={t('common:continue')}
        loading={loading}
        bgColor='blue'
        className='mt-4 w-full justify-center body-1 font-semibold'
      />
    </Form>
  )
}

export default AddBeneficialOwner
