import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import { UserMailContext } from '../../../../contexts/UserMailContext'
import { UserMailMailType, UserMailQueryType } from '../Enum'
import { Link } from 'react-router-dom'
import Routing from '@/CustomerArea/Routing'
import star from 'src/images/star.svg'
import whiteDownloadIcon from 'src/images/download/white-download-icon.svg'
import eyeIcon from 'src/images/white-eye-icon.svg'
import UserMailQueryNew from '../user_mail_queries/New'
import { useTranslation } from 'react-i18next'

const UserMailAction = () => {
  const [displayModalQueryType, setDisplayModalQueryType] =
    useState<UserMailQueryType | null>(null)
  const { t } = useTranslation()
  const { selectedMail }: any = useContext(UserMailContext)
  const { domiciliation }: any = useContext(DomiciliationContext)

  const allowedOptionAvailable =
    selectedMail.allowedOptions.includes(UserMailQueryType.forwarding) ||
    selectedMail.allowedOptions.includes(UserMailQueryType.scanning)

  const downloadScan = () => {
    window.open(selectedMail.scanFileUrl, '_blank')?.focus()
  }

  const onCloseModal = () => {
    setDisplayModalQueryType(null)
  }

  return (
    <React.Fragment>
      {displayModalQueryType && (
        <UserMailQueryNew
          userMail={selectedMail}
          onCloseModal={onCloseModal}
          type={displayModalQueryType}
        />
      )}

      {(selectedMail.allowedOptions.includes(UserMailQueryType.forwarding) ||
        selectedMail.scanFileUrl) && (
        <React.Fragment>
          <ActionsWrapper>
            {!selectedMail.markAsScanned && selectedMail.scanFileUrl && (
              <ExpressActionButton onClick={downloadScan} whiteBg={false}>
                <ExpressDispatchIcon
                  src={eyeIcon}
                  alt='réexpedition express icone'
                />
                {t('userMails:actions.envelopePreview')}
              </ExpressActionButton>
            )}
            {selectedMail.markAsScanned && selectedMail.scanFileUrl && (
              <ExpressActionButton onClick={downloadScan} whiteBg={false}>
                <ExpressDispatchIcon
                  src={whiteDownloadIcon}
                  alt='réexpedition express icone'
                  className='inline'
                />
                {t('userMails:actions.showMailPreview')}
              </ExpressActionButton>
            )}
            {(domiciliation.canExpressForward ||
              selectedMail.mailType === UserMailMailType.parcel) && (
              <React.Fragment>
                {selectedMail.allowedOptions.includes(
                  UserMailQueryType.forwarding
                ) && (
                  <ExpressActionButton
                    onClick={() =>
                      setDisplayModalQueryType(UserMailQueryType.forwarding)
                    }
                    disabled={
                      domiciliation.forwardingCredits <= 0 &&
                      selectedMail.mailType !== UserMailMailType.parcel
                    }
                  >
                    <ExpressDispatchIcon
                      src={star}
                      alt='réexpedition express icone'
                    />
                    {t('userMails:actions.capitalizeExpressReexpedition')}
                  </ExpressActionButton>
                )}

                {selectedMail.allowedOptions.includes(
                  UserMailQueryType.scanning
                ) && (
                  <ExpressActionButton
                    onClick={() =>
                      setDisplayModalQueryType(UserMailQueryType.scanning)
                    }
                  >
                    <ExpressDispatchIcon
                      src={star}
                      alt='numérisation express icone'
                    />
                    {t('userMails:actions.expressNumerisation')}
                  </ExpressActionButton>
                )}
                {domiciliation.forwardingCredits === 0 && (
                  <EachAction marginBottom={allowedOptionAvailable}>
                    <ActionName>
                      {domiciliation.forwardingCredits}{' '}
                      {t('userMails:actions.expressReexpedition')}
                    </ActionName>
                    <Link to={Routing.SUBSCRIPTION_PACKS}>
                      <ActionButton>
                        {t('userMails:actions.buyPack')}
                      </ActionButton>
                    </Link>
                  </EachAction>
                )}
              </React.Fragment>
            )}
          </ActionsWrapper>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 0;
  margin-bottom: 24px;
  button:not(:last-child) {
    margin-bottom: 16px;
  }
`
export const EachAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) =>
    props.marginBottom &&
    `
  margin-bottom: 16px;
  `}
`
export const ActionName = styled.div`
  color: #6c767f;
  font-size: 14px;
`
export const ActionButton = styled.a`
  border: none;
  color: #1576e1 !important;
  font-size: 14px;
  font-weight: 600;
  background-color: transparent;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
    color: #0454ad !important;
  }
`
export const ExpressActionButton = styled.button`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  width: 100%;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  background-color: ${(props) => (props.whiteBg ? '#FFF' : '#1576e1')};
  color: ${(props) => (props.whiteBg ? '#1576e1' : '#FFF')};
  ${(props) =>
    props.whiteBg &&
    `
    border: 1px solid #1576e1;
  `};
  &:hover {
    cursor: pointer;
    background-color: #0454ad;
    background-color: ${(props) => (props.whiteBg ? '#E7F2FF' : '#0454ad;')};
  }

  &[disabled] {
    background-color: #9c9ea0;
    cursor: not-allowed;
  }
`
export const ExpressDispatchIcon = styled.img`
  margin-right: 10px;
`
export default UserMailAction
