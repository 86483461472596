import { Fonts } from 'src/components/UIKit'
import styled from 'styled-components'

export const Title = styled.h5`
  color: var(--Neutral-1, #171a1c);
  font-family: ${Fonts.openSans};
  font-size: 14px;
  font-style: normal;
  line-height: 150%;
  font-size: 14px;
  font-weight: 600;
  color: rgb(23, 26, 28);
`

export const Description = styled.h5`
  color: var(--Neutral-1, #171a1c);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`
