import { gql } from '@apollo/client'
import { USER_MAIL_FRAGMENT } from '../Fragments'

export const USER_MAIL_VIEW_MUTATION = gql`
  mutation UserMailView($userMailIds: [ID!]!) {
    userMailView(userMailIds: $userMailIds) {
      userMails {
        id
        viewed
      }
    }
  }
`

export const USER_MAIL_UNVIEW_MUTATION = gql`
  mutation UserMailUnview($userMailIds: [ID!]!) {
    userMailUnview(userMailIds: $userMailIds) {
      userMails {
        ...UserMailFragment
      }
    }
  }
  ${USER_MAIL_FRAGMENT}
`

export const USER_MAIL_QUERY_CREATE_MUTATION = gql`
  mutation UserMailQueryCreate(
    $userMailId: ID!
    $queryType: UserMailQueryCategory!
    $forwardingAddressId: ID
  ) {
    userMailQueryCreate(
      userMailId: $userMailId
      queryType: $queryType
      forwardingAddressId: $forwardingAddressId
    ) {
      error {
        id
        message
      }
      userMailQuery {
        userMail {
          ...UserMailFragment
        }
      }
    }
  }
  ${USER_MAIL_FRAGMENT}
`

export const USER_MAIL_TICKET_CREATE_MUTATION = gql`
  mutation UserMailTicketCreate(
    $userMailId: ID!
    $subject: UserMailTicketSubject!
    $description: String
  ) {
    userMailTicketCreate(
      userMailId: $userMailId
      subject: $subject
      description: $description
    ) {
      id
    }
  }
`

export const USER_MAIL_SHARE_MUTATION = gql`
  mutation UserMailShare($userMailIds: [ID!]!, $email: String!) {
    userMailShare(userMailIds: $userMailIds, email: $email) {
      errors {
        id
        message
      }
      userMails {
        ...UserMailFragment
      }
    }
  }
  ${USER_MAIL_FRAGMENT}
`

export const USER_MAILS_DOWNLOAD_ZIP = gql`
  mutation UserMailsDownloadZip(
    $startingDate: ISO8601DateTime!
    $endingDate: ISO8601DateTime!
  ) {
    userMailsDownloadZip(startingDate: $startingDate, endingDate: $endingDate) {
      success
    }
  }
`
