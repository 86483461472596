import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BasicButton, LegalflowSection } from '@sedomicilier/material-sd'
import SummarySections from '@/app/LegalFlow/Summary/summarySections'
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { LEGALFLOW_DONE_MUTATION } from '@/graphql/mutations/legalflowDone'

const Summary: React.FC = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [validate, setValidate] = useState(false)
  const [setLegalflowDone] = useMutation(LEGALFLOW_DONE_MUTATION)
  const navigate = useNavigate()

  const onClick = () => {
    if (!loading) {
      setLoading(true)
      setLegalflowDone().then(() => {
        navigate('/')
      })
    }
  }

  return (
    <LegalflowSection title={t('summary:title')}>
      <SummarySections />
      <Checkbox
        data-testid='checkbox'
        onChange={(e: CheckboxChangeEvent) => setValidate(e.target.checked)}
        checked={validate}
        className='body-2 text-neutral-600 my-3'
      >
        {t('summary:validation')}
      </Checkbox>

      <BasicButton
        testid='summary-next-button'
        bgColor='blue'
        label={t('common:send')}
        loading={loading}
        disabled={!validate}
        className='w-full justify-center body-1 font-semibold'
        onClick={onClick}
      />
    </LegalflowSection>
  )
}

export default Summary
