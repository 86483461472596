import styled from 'styled-components'
import { Input } from 'antd'
import { Colors } from 'src/components/UIKit'
import IdentitySelector from '../IdentityProofDrawer/identitySelector'

const BOPhysical = ({
  identityChoices,
  selectIdentity,
  setSelectIdentity,
  states,
  onSubmit,
  confirmIdentity,
  setConfirmIdentity,
  info,
  setInfo,
  setConfirmDenomination
}) => {
  return (
    <IdentitySelector
      showFirstStep={true}
      choices={identityChoices}
      selected={selectIdentity}
      setSelected={setSelectIdentity}
      states={states}
      onSendDocument={onSubmit}
      confirm={confirmIdentity}
      setConfirm={setConfirmIdentity}
      validate={!!info.lastname && !!info.firstname}
      setName={setInfo}
      onSelectionCancel={() => setConfirmDenomination(false)}
    >
      <TextInput>
        <Label>Nom</Label>
        <Input
          placeholder='Saisissez le nom'
          style={{ height: '48px' }}
          onChange={(e) =>
            setInfo((prevState) => ({
              ...prevState,
              lastname: e.target.value
            }))
          }
          defaultValue={info.lastname}
        />

        <Label>Prénom</Label>
        <Input
          placeholder='Saisissez le prénom'
          style={{ height: '48px' }}
          onChange={(e) =>
            setInfo((prevState) => ({
              ...prevState,
              firstname: e.target.value
            }))
          }
          defaultValue={info.firstname}
        />
      </TextInput>
    </IdentitySelector>
  )
}

export const Label = styled.div`
  color: ${Colors.black};
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
`
export const TextInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 0;
`

export default BOPhysical
