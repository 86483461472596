import { useTranslation } from 'react-i18next'

const PacksDetails: React.FC = () => {
  const { t } = useTranslation('domiciliationPage')

  return (
    <>
      {['starter', 'business', 'premium'].map(
        (element: string, idx: number) => (
          <div key={idx} className='mb-4'>
            <h4 className='title-3 text-2 mb-2'>
              {t(`drawers.mailsOfferDetails.offers.${element}.title`)}
            </h4>
            <p
              dangerouslySetInnerHTML={{
                __html: t(
                  `drawers.mailsOfferDetails.offers.${element}.description`
                )
              }}
            />
          </div>
        )
      )}
      <ul className='list-inside list-disc pl-1'>
        <p className='title-3 mb-1'>
          {t('drawers.mailsOfferDetails.noteTitle')}
        </p>
        {['li1', 'li2'].map((element: string, idx: number) => (
          <li key={idx}>{t(`drawers.mailsOfferDetails.note.${element}`)}</li>
        ))}
      </ul>
    </>
  )
}

export default PacksDetails
