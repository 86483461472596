import { CGV_ACCEPTANCE_FRAGMENT } from '@/graphql/Fragments'
import { gql } from '@apollo/client'

export const CURRENT_CGV_QUERY = gql`
  query currentCvgAcceptance($cgvableId: Int, $cgvableType: String) {
    currentCgvAcceptance(cgvableId: $cgvableId, cgvableType: $cgvableType) {
      ...CgvAcceptanceFragment
    }
  }
  ${CGV_ACCEPTANCE_FRAGMENT}
`
