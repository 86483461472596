import { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LEGAL_DOCUMENT_GET_ONBOARDING } from '@/graphql/mutations/legalDocument'
import Iframe from 'react-iframe'
import { Drawer } from 'antd'
import Routing from '@/CustomerArea/Routing'
import { CommonLoader } from '@/components/shared/Loader'
import NoticeInformation from '@/components/noticeInformation'
import { Colors } from '@/components/UIKit'

const Verification = () => {
  const { t } = useTranslation()

  const [legalDocumentGetOnboarding] = useMutation(
    LEGAL_DOCUMENT_GET_ONBOARDING
  )
  const [iframeLink, setIframeLink] = useState<string>('')
  const { targetId } = useParams<{ targetId: string }>()
  const { ruleCategory } = useParams<{ ruleCategory: string }>()
  const navigate = useNavigate()

  useEffect(() => {
    legalDocumentGetOnboarding({
      variables: {
        targetId: targetId,
        ruleCategory: ruleCategory
      }
    }).then(async (res) => {
      await res
      setIframeLink(res.data.legalDocumentGetOnboarding?.sdkLink)
    })
  }, [])

  return iframeLink ? (
    <Drawer
      title="Vérification d'identité"
      open={true}
      onClose={() =>
        navigate(`${Routing.PROFILE_LEGAL_FILES}?continuekyc=true`)
      }
      width='100vw'
    >
      <IframeWrapper>
        <NoticeWrapper>
          <NoticeInformation bgColor='blue' icon>
            <NoticeRegular>{t('procedure:sdkNotice.regular1')}</NoticeRegular>
            <NoticeStrong>
              {t(`procedure:sdkNotice.strong.${ruleCategory}`)}
            </NoticeStrong>
            <NoticeRegular>{t('procedure:sdkNotice.regular2')}</NoticeRegular>
          </NoticeInformation>
          <NoticeWarningLisibility
            dangerouslySetInnerHTML={{
              __html: t('procedure:sdkNotice.warningLisibility')
            }}
          />
        </NoticeWrapper>
        <Iframe
          url={iframeLink}
          width='400px'
          height='600px'
          display='block'
          overflow='hidden'
          frameBorder={0}
          scrolling='no'
          allowFullScreen={false}
          styles={{ left: 0, top: 0, zIndex: 9999 }}
          position='relative'
          allow='camera'
        />
      </IframeWrapper>
    </Drawer>
  ) : (
    <CommonLoader />
  )
}

export const NoticeWarningLisibility = styled.p`
  text-align: center;
`

export const IframeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const NoticeWrapper = styled.div`
  max-width: 500px;
  margin-bottom: 40px;
`
export const NoticeRegular = styled.span`
  color: ${Colors.black};
  font-size: 14px;
  font-weight: 400;
`
export const NoticeStrong = styled.span`
  color: ${Colors.black};
  font-size: 14px;
  font-weight: 700;
`

export default Verification
