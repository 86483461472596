export enum UserMailQueryType {
  scanning = 'scanning',
  forwarding = 'forwarding',
  provisioning = 'provisioning'
}

export enum UserMailMailType {
  letter = 'Courrier',
  letterAr = 'Lettre A/R',
  parcel = 'Colis',
  sensible = 'Courrier important',
  deliveryNotice = 'Avis de passage'
}

/* eslint-disable camelcase */
export enum UserMailStatus {
  received = 'received',
  forwarded = 'forwarded',
  disposed = 'disposed',
  provisioning_in_progress = 'provisioning_in_progress',
  provisioning_ready = 'provisioning_ready',
  scanning_in_progress = 'scanning_in_progress',
  archived = 'archived',
  destroyed = 'destroyed',
  npai = 'npai',
  npai_standby = 'npai_standby'
}
/* eslint-enable camelcase */

/* eslint-disable camelcase */
export enum UserMailOrderColumn {
  date_of_receipt = 'date_of_receipt'
}
/* eslint-enable camelcase */

export enum UserMailOrderDirection {
  asc = 'asc',
  desc = 'desc'
}

export enum UserMailTicketSubject {
  notReceived = 'not_received',
  badScan = 'bad_scan',
  missingPage = 'missing_page',
  wrongAddress = 'wrong_address'
}
