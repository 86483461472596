import React, { useContext } from 'react'
import TerminationSummary from './TerminationSummary'
import SubscriptionSummary from './SubscriptionSummary'
import { CancelationProcedureContext } from '@/contexts/CancelationProcedureContext'
import { TerminationSubscriptionBodyWrapper } from '../styles'

const TerminationSubscriptionBody = () => {
  const { dataCancelationProcedure }: any = useContext(
    CancelationProcedureContext
  )
  const cancelationProcedure =
    dataCancelationProcedure && dataCancelationProcedure.cancelationProcedure

  return (
    <TerminationSubscriptionBodyWrapper>
      {cancelationProcedure && <TerminationSummary />}
      <SubscriptionSummary />
    </TerminationSubscriptionBodyWrapper>
  )
}

export default TerminationSubscriptionBody
