import LanguageSelect from '@/components/languageSelect'

interface LegalFlowFooterProps {}

const LegalFlowFooter: React.FC<LegalFlowFooterProps> = () => {
  return (
    <footer className='min-h-[80px] bg-neutral-150 flex items-center justify-start tablet:pl-5'>
      <LanguageSelect />
    </footer>
  )
}

export default LegalFlowFooter
