import { Routes, Route } from 'react-router-dom'
import ForwardingAddress from './ForwardingAddress'
import LegalRepresentative from './LegalRepresentative'
import Navigation from './Navigation'
import Layout from './layout'
import CompanyActivity from '@/app/LegalFlow/CompanyActivity'
import Capital from '@/app/LegalFlow/Capital'
import Accounting from '@/app/LegalFlow/Accounting'
import Partner from '@/app/LegalFlow/Partner'
import Summary from '@/app/LegalFlow/Summary'
import Onboarding from '@/app/LegalFlow/Onboarding'
import { useWindowDimensions } from '@sedomicilier/material-sd'
import constants from 'src/constants'
import Header from '@/components/layouts/Header'
import LegalFlowFooter from '@/components/layouts/LegalFlowFooter'

const LegalFlow = () => {
  const { width } = useWindowDimensions()

  const mobile =
    width && width <= parseInt(constants.breakpoints['mobile-breakpoint-i'], 10)

  return (
    <main className='bg-neutral-50 p-0 h-screen overflow-y-auto flex flex-col justify-between'>
      <Layout>
        <Header />
        <div className='flex flex-1 items-start tablet:px-5 mobile:px-2 pb-5'>
          {!mobile && <Navigation />}
          <Routes>
            <Route path='onboarding' element={<Onboarding />} />
            <Route path='forwarding-address' element={<ForwardingAddress />} />
            <Route
              path='legal-representative'
              element={<LegalRepresentative />}
            />
            <Route path='company-activity' element={<CompanyActivity />} />
            <Route path='capital' element={<Capital />} />
            <Route path='accounting' element={<Accounting />} />
            <Route path='partner' element={<Partner />} />
            <Route path='summary' element={<Summary />} />
          </Routes>
        </div>
      </Layout>
      <LegalFlowFooter />
    </main>
  )
}

export default LegalFlow
