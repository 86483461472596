import React from 'react'
import CancelationProcedureProvider from 'src/contexts/CancelationProcedureContext'
import TerminationSubscriptionHeader from './TerminationSubscriptionHeader'
import TerminationSubscriptionBody from './TerminationSubscriptionBody'

const TerminationSubscriptionSummary = () => {
  return (
    <CancelationProcedureProvider>
      <TerminationSubscriptionHeader />
      <TerminationSubscriptionBody />
    </CancelationProcedureProvider>
  )
}

export default TerminationSubscriptionSummary
