import React, { createContext } from 'react'
import { useQuery } from '@apollo/client'

import { CURRENT_CGV_QUERY } from '@/graphql/queries/cgvAcceptance'
import { CommonLoader } from 'src/components/shared/Loader'

import ICgvAcceptance from '@/interfaces/cgvAcceptance'

interface IProps {
  children: React.ReactNode
}

export const CurrentCgvAcceptanceContext = createContext({} as ICgvAcceptance)

const CurrentCgvAcceptanceContextProvider = (props: IProps) => {
  const { data: cgvAcceptanceData, loading } = useQuery(CURRENT_CGV_QUERY)
  const currentCgv = cgvAcceptanceData?.currentCgvAcceptance

  if (loading) {
    return <CommonLoader size={48} />
  }

  return (
    <CurrentCgvAcceptanceContext.Provider value={currentCgv}>
      {props.children}
    </CurrentCgvAcceptanceContext.Provider>
  )
}

export default CurrentCgvAcceptanceContextProvider
