import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { DomiciliationContext } from '@/contexts/DomiciliationContext'
import { UsermailsFiltersProps } from '@/interfaces/filters'
import { Radio, Select } from 'antd'

interface MailsSortersProps {
  onFilterChange: (prev) => void
}

const MailsSorters: React.FC<MailsSortersProps> = ({ onFilterChange }) => {
  const { t } = useTranslation()
  const { domiciliation } = useContext(DomiciliationContext)
  const { userMailTags } = domiciliation

  const handleTagChange = (values: number[]) => {
    return onFilterChange((prev: UsermailsFiltersProps) => ({
      ...prev,
      tagIds: values
    }))
  }

  const handleTypeChange = (values: string[]) => {
    return onFilterChange((prev: UsermailsFiltersProps) => ({
      ...prev,
      mailTypes: values
    }))
  }

  const handleViewedChange = (values: string) => {
    const viewed = values === 'all' ? undefined : values === 'read'
    return onFilterChange((prev: UsermailsFiltersProps) => ({
      ...prev,
      viewed: viewed
    }))
  }

  return (
    <div className='flex items-center justify-between tablet:flex-wrap wide:flex-nowrap gap-1 mobile:flex-col'>
      <Select
        className='wide:basis-1/3 mobile:w-full tablet:w-[calc(50%-8px)]'
        placeholder={t('userMails:filters.tagsSorting')}
        allowClear
        showSearch={false}
        mode='multiple'
        onChange={handleTagChange}
        options={userMailTags.map((t) => ({ value: +t.id, label: t.name }))}
      />

      <Select
        className='wide:basis-1/3 mobile:w-full tablet:w-[calc(50%-8px)]'
        placeholder={t('userMails:filters.categorySorting')}
        allowClear
        showSearch={false}
        mode='multiple'
        onChange={handleTypeChange}
        options={[
          { value: 'Courrier', label: 'Courrier' },
          { value: 'Colis', label: 'Colis' },
          { value: 'Avis de passage', label: 'Avis de passage' },
          { value: 'Lettre A/R', label: 'Lettre A/R' }
        ]}
      />

      <Radio.Group
        className='flex mobile:w-full tablet:w-full wide:w-1/2'
        defaultValue='all'
        onChange={(e) => handleViewedChange(e.target.value)}
      >
        <Radio.Button className='basis-1/3 text-center' value='all'>
          {t('userMails:filters.readUnreadHandler.all')}
        </Radio.Button>
        <Radio.Button className='basis-1/3 text-center' value='read'>
          {t('userMails:filters.readUnreadHandler.read')}
        </Radio.Button>
        <Radio.Button className='basis-1/3 text-center' value='unread'>
          {t('userMails:filters.readUnreadHandler.unread')}
        </Radio.Button>
      </Radio.Group>
    </div>
  )
}

export default MailsSorters
