import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { UNLOGGED_CONTACT } from '@/graphql/queries/invoices'
import StripeWrapper from 'src/components/StripeWrapper'
import RegularizeFailedPaymentsUnlogged from './RegularizeFailedPaymentsUnlogged'
import { IFailedPayment } from '@/interfaces/failedPayment'
import NoInvoices from './NoInvoices'

export interface IUnloggedContactProps {
  unloggedContact: {
    id: string
    stripeConnectedAccount: string
    publicFailedPayments: IFailedPayment[]
    regularizationInformations: {
      total: number
      paymentIntent: {
        stripeClientSecret: string
        stripeId: string
      }
    }
  }
  error?: {
    message: string
  }
}

const ContactUnlogged: React.FC = () => {
  const params = useParams<{ token: string }>()
  const { data, loading, error } = useQuery<IUnloggedContactProps>(
    UNLOGGED_CONTACT,
    {
      variables: {
        securityToken: encodeURIComponent(params.token!)
      }
    }
  )
  const noInvoices = data?.error?.message === 'Pas de facture à régulariser'
  return loading ? null : noInvoices ? (
    <NoInvoices />
  ) : (
    <StripeWrapper
      stripeConnectedAccount={data?.unloggedContact.stripeConnectedAccount}
    >
      <RegularizeFailedPaymentsUnlogged
        regularizeInvoicesData={data?.unloggedContact}
        regularizeInvoicesLoading={loading}
        regularizeInvoicesError={error}
      />
    </StripeWrapper>
  )
}

export default ContactUnlogged
