import { gql } from '@apollo/client'
import { NOTIFICATION_UNSUBSCRIPTION_FRAGMENT } from '../Fragments'

export const NOTIFICATION_UNSUBSCRIPTION_UPDATE_MUTATION = gql`
  mutation NotificationUnsubscriptionUpdate(
    $support: Boolean!
    $marketing: Boolean!
    $newUserMail: Boolean!
  ) {
    notificationUnsubscriptionUpdate(
      support: $support
      marketing: $marketing
      newUserMail: $newUserMail
    ) {
      notificationUnsubscription {
        ...NotificationUnsubscriptionFragment
      }
    }
  }
  ${NOTIFICATION_UNSUBSCRIPTION_FRAGMENT}
`
