import React, { useContext } from 'react'
import { CommonLoader } from '@/components/shared/Loader'
import { Alert } from 'antd'
import { CancelationProcedureContext } from '@/contexts/CancelationProcedureContext'
import { TerminationSubscriptionHeaderWrapper } from '../styles'
import { useTranslation } from 'react-i18next'

const TerminationSubscriptionHeader = () => {
  const { t } = useTranslation()
  const { loadingCancelationProcedure, errorCancelationProcedure }: any =
    useContext(CancelationProcedureContext)

  return (
    <TerminationSubscriptionHeaderWrapper>
      {loadingCancelationProcedure && <CommonLoader />}
      {errorCancelationProcedure && (
        <>
          <Alert
            className='full-width'
            message='Error'
            description={t('cancelationProcedure:errors.default')}
            type='error'
            showIcon={true}
          />
        </>
      )}
    </TerminationSubscriptionHeaderWrapper>
  )
}

export default TerminationSubscriptionHeader
