import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { Colors } from 'src/components/UIKit'
import Button from 'src/components/UIKit/components/Button/buttonWithBorder'
import styled from 'styled-components'
import NoticeInformation from 'src/components/noticeInformation'
import IdentityUpload from './identityUpload'
import { Choice } from '../identityDrawers'
import { FileStates } from '.'

interface IdentitySelectorProps {
  showFirstStep: boolean
  choices: Choice[]
  selected: Choice
  setSelected: Dispatch<SetStateAction<Choice>>
  states: FileStates
  onSendDocument: () => void
  confirm: boolean
  setConfirm: Dispatch<SetStateAction<boolean>>
  setName?: Dispatch<SetStateAction<{ firstname: string; lastname: string }>>
  children?: React.ReactNode
  validate?: boolean | undefined
  onSelectionCancel?: () => void
}

const IdentitySelector = ({
  showFirstStep,
  choices,
  selected,
  setSelected,
  states,
  onSendDocument,
  confirm,
  setConfirm,
  children,
  setName,
  validate,
  onSelectionCancel
}: IdentitySelectorProps) => {
  const { t } = useTranslation()
  const {
    selectedFile,
    setSelectedFile,
    setFileType,
    fileVerso,
    setFileVerso
  } = states

  const [showButton, setShowButton] = useState(false)
  const [disable, setDisable] = useState<boolean>(false)

  const hasRectoVerso =
    selected.name === 'idCard' || selected.name === 'residencePermit'

  const onSubmit = () => {
    setDisable(true)
    onSendDocument()
  }

  const onConfirm = () => {
    if (selected) {
      setFileType(selected.fileType)
      return setConfirm(true)
    }
    return
  }

  const onCancel = () => {
    setDisable(false)
    setSelectedFile(null)
    setFileVerso(null)
    setConfirm(false)
    setName && setName({ lastname: '', firstname: '' })
  }

  useEffect(() => {
    if (validate || validate === undefined) {
      if (hasRectoVerso) {
        if (selectedFile && fileVerso) {
          return setShowButton(true)
        } else {
          return setShowButton(false)
        }
      }
      return selectedFile ? setShowButton(true) : setShowButton(false)
    } else {
      return setShowButton(false)
    }
  }, [selectedFile, fileVerso, validate])

  return (
    <div>
      {!confirm && showFirstStep ? (
        <>
          <DrawerTitle>
            {t('procedure:identity.drawer.selectIdentityProof')}
          </DrawerTitle>
          <IdentityProofDrawerChoice>
            {choices.map((item) => {
              return (
                <ChoiceWrapper
                  key={item.name}
                  selected={selected.name === item.name}
                  onClick={() => setSelected(item)}
                >
                  <ChoiceIcon src={item.icon} alt={`${item.icon} icone`} />
                  <ChoiceTitle>{item.title}</ChoiceTitle>
                </ChoiceWrapper>
              )
            })}
          </IdentityProofDrawerChoice>
          <ButtonsWrapper>
            <Button
              bgColor='blue'
              height='48px'
              justifyContent='center'
              onClick={onConfirm}
            >
              {t('common:continue')}
            </Button>
            {onSelectionCancel && (
              <Button
                bgColor='white'
                onClick={onSelectionCancel}
                justifyContent='center'
              >
                {t('common:cancel')}
              </Button>
            )}
          </ButtonsWrapper>
        </>
      ) : (
        <>
          <DrawerTitle>
            {t(`procedure:identity.drawer.upload.${selected.name}`)}
          </DrawerTitle>
          <NoticeInformation bgColor='blue'>
            <NoticeText>
              {t('procedure:identity.drawer.notice.blue.regular')}
            </NoticeText>
            <NoticeStrongText>
              {t('procedure:identity.drawer.notice.blue.strong')}
            </NoticeStrongText>
          </NoticeInformation>
          {!hasRectoVerso && (
            <NoticeInformation bgColor='orange'>
              <NoticeText>
                {t('procedure:identity.drawer.notice.orange.regular1')}
              </NoticeText>
              <NoticeStrongText>
                {t('procedure:identity.drawer.notice.orange.strong')}
              </NoticeStrongText>
              <NoticeText>
                {t('procedure:identity.drawer.notice.orange.regular2')}
              </NoticeText>
            </NoticeInformation>
          )}
          {children}

          <UploadWrapper>
            <IdentityUpload
              uploadedFile={selectedFile}
              setUploadedFile={setSelectedFile}
              buttonText={t(
                `procedure:identity.drawer.button.${hasRectoVerso ? 'recto' : 'passport'}`
              )}
              fileFormat={hasRectoVerso ? false : true}
            />
            {hasRectoVerso && (
              <IdentityUpload
                uploadedFile={fileVerso}
                setUploadedFile={setFileVerso}
                buttonText={t('procedure:identity.drawer.button.verso')}
                fileFormat={true}
              />
            )}
          </UploadWrapper>
          {showButton && (
            <Form.Item>
              <ButtonsWrapper>
                <Button
                  bgColor='blue'
                  justifyContent='center'
                  onClick={onSubmit}
                  disabled={disable}
                >
                  {t(`common:document.${hasRectoVerso ? 'sendP' : 'send'}`)}
                </Button>
                <Button
                  bgColor='white'
                  onClick={onCancel}
                  justifyContent='center'
                >
                  {t('common:cancel')}
                </Button>
              </ButtonsWrapper>
            </Form.Item>
          )}
        </>
      )}
    </div>
  )
}

export const DrawerTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${Colors.black};
  margin-bottom: 32px;
`
export const IdentityProofDrawerChoice = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
`
export const ChoiceWrapper = styled.div`
  height: 140px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border: 1px solid
    ${(props) => (props.selected ? `${Colors.blue}` : '#e3e6e8')};
  border-radius: 14px;
  &:hover {
    border: 1px solid ${Colors.blue};
    cursor: pointer;
  }
`
export const ChoiceIcon = styled.img``
export const ChoiceTitle = styled.div`
  color: ${Colors.black};
  font-size: 16px;
  font-weight: 600;
`
export const NoticeText = styled.span``
export const NoticeStrongText = styled.span`
  font-weight: bold;
`
export const UploadWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  & > div {
    margin: 0;
  }
`
export const ButtonsWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export default IdentitySelector
