import React, { createContext } from 'react'
import { useQuery } from '@apollo/client'
import { CANCELATION_PROCEDURE } from '@/graphql/queries/cancelationProcedure'

export const CancelationProcedureContext = createContext({})
export const CancelationProcedureContextProvider =
  CancelationProcedureContext.Provider
export const CancelationProcedureContextConsumer =
  CancelationProcedureContext.Consumer

interface IProps {
  children: React.ReactNode
}

const CancelationProcedureProvider = (props: IProps) => {
  const { loading, error, data, refetch } = useQuery(CANCELATION_PROCEDURE)
  return (
    <CancelationProcedureContextProvider
      value={{
        dataCancelationProcedure: data,
        loadingCancelationProcedure: loading,
        errorCancelationProcedure: error,
        refetch
      }}
    >
      {props.children}
    </CancelationProcedureContextProvider>
  )
}

export default CancelationProcedureProvider
