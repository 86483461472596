import React from 'react'

interface ITerminationTag {
  text: string
  color?: string
  classes?: string
}

const TerminationTag = ({ text, color, classes }: ITerminationTag) => {
  return (
    <div className={`termination-tag ${color || 'gray'} ${classes}`}>
      {text}
    </div>
  )
}

export default TerminationTag
