import * as React from 'react'
import * as Cookies from 'js-cookie'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUSNAG_ID!,
  autoTrackSessions: true,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_ENVIRONMENT,
  onError: (event) => {
    // Having cookies just for that is far from optimal
    const userId = Cookies.get('user_id')
    const domiciliationId = Cookies.get('domiciliation_id')
    event.setUser(userId, `domiciliation #${domiciliationId}`)
  },
  enabledReleaseStages: ['production', 'staging']
})

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)

export default ErrorBoundary
