import gql from 'graphql-tag'
import { CGV_ACCEPTANCE_FRAGMENT } from '@/graphql/Fragments/cgvAcceptance'

export const USER_CGV_ACCEPTANCE_FRAGMENT = gql`
  fragment UserCgvAcceptanceFragment on UserCgvAcceptance {
    id
    contactId
    userId
    createdAt
    cgvAcceptance {
      ...CgvAcceptanceFragment
    }
  }
  ${CGV_ACCEPTANCE_FRAGMENT}
`
