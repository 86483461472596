import React, { useState } from 'react'
import styled from 'styled-components'
import Search from './Search'
import List from './List'
import { ROOM_CITIES } from '@/graphql/queries/roomMeeting'
import { Query } from '@apollo/client/react/components'
import { ISearch } from './Interfaces'
import IRoom from '@/interfaces/room'
import { Room } from './Helpers'
import Routing from '@/CustomerArea/Routing'
import { FullScreenLayout } from '../shared/Helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@sedomicilier/pro-light-svg-icons'
import ConfirmBook from './ConfirmBook'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

const Book = (props) => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const [search, setSearch] = useState<ISearch>({})
  const [allFilled, setAllFilled] = useState(false)
  const [fullScreen, setFullScreen] = useState(true)
  const [selected, setSelected] = useState<IRoom | undefined>(undefined)

  const allFilledFunction = (searchArg: ISearch) => {
    return ['city', 'capacity', 'startingAt', 'endingAt'].reduce(
      (memo: boolean, key: string) =>
        memo && !!searchArg[key] && searchArg[key] !== '',
      true
    )
  }

  const setSearchFunction = (searchArg: ISearch) => {
    setSearch(searchArg)
    setAllFilled(allFilledFunction(searchArg))
  }

  const handleSearch = () => {
    setAllFilled(allFilledFunction(search))
    setFullScreen(fullScreen && !allFilledFunction(search))
  }

  const onClose = () => {
    if (!fullScreen) {
      setFullScreen(true)
    } else {
      if (location.state && (location.state as any).returnTo) {
        navigate((location.state as any).returnTo)
      } else {
        navigate(Routing.SERVICES_INDEX)
      }
    }
  }

  if (selected) {
    return (
      <FullScreenLayout onClose={() => setSelected(undefined)}>
        <div className='pt-4'>
          <h1 className='title center nowidth under-space size-mobile mb-4'>
            {t('services:bookings:room')}
          </h1>
          <p className='slogan center mb-4'>{t('services:bookings:slogan')}</p>

          <SelectedRoom className='m-auto'>
            <Room room={selected} />

            <div className='price-summary'>
              <div className='dates'>
                {t('services:bookings.price_for.date', {
                  slices: selected.availableSlices
                })}
              </div>
              {['day', 'halfDay', 'hour'].map((period) => {
                const count = selected.bestPriceDetail[period]
                if (count > 0) {
                  return (
                    <div className='detail' key={period}>
                      {t(`services:bookings.price_for.${period}`, {
                        count
                      })}
                      <span className='price'>
                        {' '}
                        {t('common:currency', {
                          value: count * selected.prices[period]
                        })}
                      </span>
                    </div>
                  )
                } else {
                  return null
                }
              })}
              <div className='detail total' key='total'>
                {t('services:bookings.price_for.total')}
                <span className='price'>
                  {t('common:currency', {
                    value: selected.bestPrice
                  })}
                </span>
              </div>
            </div>
            <ConfirmBook
              {...props}
              allFilled={allFilled}
              fullScreen={fullScreen}
              selected={selected}
            />

            <p className='terms'>{t('services:bookings.terms')}</p>
          </SelectedRoom>
        </div>
      </FullScreenLayout>
    )
  } else {
    return (
      <Query query={ROOM_CITIES}>
        {({ loading, error, data }: any) => {
          if (loading || error) {
            return null
          }

          return (
            <FullScreenLayout onClose={onClose}>
              <Search
                fullScreen={fullScreen}
                cities={data.roomCities}
                setSearch={setSearchFunction}
                search={search}
                handleSearch={handleSearch}
              />
              {!fullScreen && (
                <div className='full-padding pt-4'>
                  <h1 className='title center nowidth under-space mb-4'>
                    {t('services:bookings:room')}
                  </h1>
                  <p className='slogan center mb-4'>
                    {t('services:bookings:slogan')}
                  </p>
                  <label
                    htmlFor='cliquable-filtre'
                    className='section-button white-button display-filtre no-height'
                  >
                    <FontAwesomeIcon icon={faFilter} />
                    {t('common:filter')}
                  </label>

                  <List
                    search={search}
                    handleSelect={setSelected}
                    allFilled={allFilled}
                  />
                </div>
              )}
            </FullScreenLayout>
          )
        }}
      </Query>
    )
  }
}

export default Book

export const SelectedRoom = styled.div`
  max-width: 640px;
  padding: 16px;
`
