import styled from 'styled-components'
import colors from '@/components/UIKit/colors'

const Notification = ({ number }) => {
  if (!number) {
    return null
  } else {
    return <NotificationWrapper>{number}</NotificationWrapper>
  }
}

export const NotificationWrapper = styled.span`
  background-color: ${colors.orange};
  border-radius: 50%;

  width: 18px;
  height: 18px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 11px;
  font-weight: 600;
  font-family: 'Inter';
  color: ${colors.white};
  line-height: 150%;
`

export default Notification
