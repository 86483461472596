import { gql } from '@apollo/client'

export const CANCELATION_PROCEDURE = gql`
  {
    cancelationProcedure {
      id
      type
      reason
      reasonOther
      address
      state
      createdAt
      cancelationExpectedDate
    }
  }
`
