import React, { useContext } from 'react'
import { SubscriptionSummaryWrapper } from '../styles'
import { useTranslation } from 'react-i18next'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import { ContactContext } from 'src/contexts/contactContext'
import moment from 'moment'

const SubscriptionSummary = () => {
  const { t } = useTranslation()

  const { domiciliation }: any = useContext(DomiciliationContext)
  const { contact }: any = useContext(ContactContext)

  const firstname =
    contact?.personalInformation.leaderContactDetail.firstname || ''
  const lastname =
    contact?.personalInformation.leaderContactDetail.lastname || ''
  const societyName = contact?.society.name || ''

  return (
    <SubscriptionSummaryWrapper>
      <h4>{t('cancelationProcedure:summary.subscriptionBloc.title')}</h4>
      <ul id='subscription-summary-wrapper'>
        <li className='summary-row'>
          <p className='summary-row-label'>
            {t('cancelationProcedure:summary.subscriptionBloc.labels.fullName')}
          </p>
          <p className='summary-row-value'>
            {t(
              'cancelationProcedure:summary.subscriptionBloc.values.fullName',
              {
                lastname,
                firstname
              }
            )}
          </p>
        </li>
        <li className='summary-row'>
          <p className='summary-row-label'>
            {t(
              'cancelationProcedure:summary.subscriptionBloc.labels.societyName'
            )}
          </p>
          <p className='summary-row-value'>{societyName}</p>
        </li>
        <li className='summary-row'>
          <p className='summary-row-label'>
            {t(
              'cancelationProcedure:summary.subscriptionBloc.labels.subscriptionNumber'
            )}
          </p>
          <p className='summary-row-value'>{domiciliation.id}</p>
        </li>
        <li className='summary-row'>
          <p className='summary-row-label'>
            {t(
              'cancelationProcedure:summary.subscriptionBloc.labels.subscriptionDate'
            )}
          </p>
          <p className='summary-row-value'>
            {domiciliation.initialInvoicePaidAt &&
              moment(domiciliation.initialInvoicePaidAt).format('LL')}
          </p>
        </li>
      </ul>
    </SubscriptionSummaryWrapper>
  )
}

export default SubscriptionSummary
