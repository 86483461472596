import { CurrentUserContext } from '@/contexts/currentUserContext'
import { UPSERT_USER_LOCALE } from '@/graphql/mutations/upsertLocale'
import i18n from '@/i18n'
import session from '@/session'
import { useMutation } from '@apollo/client'
import { Select } from 'antd'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface LanguageSelectProps {}

const LanguageSelect: React.FC<LanguageSelectProps> = () => {
  const { t } = useTranslation()

  const { locale } = useContext(CurrentUserContext)

  const decodedToken = session.getDecodedToken()
  const adminConnexion = decodedToken.connected_as

  const [upsertUserLocale] = useMutation(UPSERT_USER_LOCALE)

  const onLanguageChange = (lng) => {
    if (!adminConnexion) {
      upsertUserLocale({ variables: { locale: lng.toUpperCase() } })
    }
    i18n.changeLanguage(lng)
  }

  useEffect(() => {
    i18n.changeLanguage(locale.toLowerCase())
  }, [locale])

  const options = [
    { label: t('common:languages.fr'), value: 'fr' },
    { label: t('common:languages.en'), value: 'en' }
  ]
  return (
    <Select
      data-testid='language-select'
      className='w-fit [&>span]:!text-neutral-700 [&>span]:!right-[0px] desktop:p-0 mobile:pl-3'
      variant='borderless'
      value={i18n.language}
      onChange={onLanguageChange}
      options={options}
    />
  )
}

export default LanguageSelect
